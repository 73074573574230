#h-bot-by {
    display: none !important;
}

html {
    height: 100%;
    overflow-x: hidden;
}

body {
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
}

@font-face {
    font-family: "Lato";
    src: url("../fonts/LatoCustom-Regular.woff2") format('woff2'),
         url("../fonts/LatoCustom-Regular.woff") format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Lato';
    src: url("../fonts/subset-Lato-Light.woff2") format('woff2'),
         url("../fonts/subset-Lato-Light.woff") format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: "Lato";
    src: url("../fonts/LatoCustom-Bold.woff2") format('woff2'),
         url("../fonts/LatoCustom-Bold.woff") format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
}

a:active, a:focus {
    outline: none;
    color: #333 !important;
}

input, textarea {
    outline: none;
}

input:active, textarea:active {
    outline: none;
}

:focus {
    outline: none;
}

textarea {
    resize: none;
}

textarea {
    resize: vertical;
}

textarea {
    resize: horizontal;
}

button:active, button:focus {
    outline: none !important;
}

button::-moz-focus-inner {
    border: 0 !important;
}

body {
    min-width: 970px;
    font-family: Lato, Аrial, sans-serif;
    -webkit-text-size-adjust: 100%;
    font-size: 16px;
}



.no-animation-only-this {
    -webkit-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.no-animation {
    -webkit-animation-delay: 0s !important;
    animation-delay: 0s !important;
    -webkit-animation-duration: 0s !important;
    animation-duration: 0s !important;
}

.no-animation *:before, .no-animation *:after {
    -webkit-animation-delay: 0s !important;
    animation-delay: 0s !important;
    -webkit-animation-duration: 0s !important;
    animation-duration: 0s !important;
}

.no-transition-only-this {
    -webkit-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.no-transition {
    -webkit-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.no-transition *, .no-transition *:before, .no-transition *:after {
    -webkit-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.wrapall {
    float: left;
    width: 100%;
    overflow: hidden;
}

.scrollable {
    overflow-y: auto;
    overflow-x: hidden;
}

.scrollable-x {
    overflow-y: hidden;
    overflow-x: auto;
}

.scrollable.nicescroll,
.scrollable-x.nicescroll {
    overflow: hidden;
    position: relative;
}

.oveflow-hidden {
    overflow: hidden;
}

.cena-name {
    font-size: 15px; /*12px;*/
    opacity: 0.6;
}

.nicescroll-rails {
    box-sizing: content-box;
    border-radius: 10px !important;
    visibility: hidden;
    display: none;
    /*z-index: 90 !important;*/
    background-color: #ebe5d7 !important;
}

.nicescroll-rails-hr {
    height: 8px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: -38px;
    margin-bottom: 38px;
    background-color: transparent !important;
}

.nicescroll-rails-hr:before {
    position: absolute;
    top: 0;
    right: 40px;
    bottom: 0;
    left: 40px;
    background-color: #ebe5d7;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 10px !important;
    content: "";
}

.nicescroll-rails-hr .nicescroll-cursors {
    height: 4px !important;
    margin-left: 40px;
}

.nicescroll-rails-vr {
    background-color: transparent !important;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 8px !important;
}

.nicescroll-rails-vr:before {
    position: absolute;
    top: 0px;
    right: 0;
    bottom: 0px;
    left: 0;
    background-color: #ebe5d7;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 10px !important;
    content: "";
}

.nicescroll-rails-vr .nicescroll-cursors {
    width: 8px !important;
}

.nicescroll-rails--inited {
    visibility: visible;
}

.nicescroll-invisible {
    visibility: hidden;
}

.nicescroll-cursors {
    box-sizing: content-box;
    background-color: #c2c2c2 !important;
    background-color: rgba(0, 0, 0, 0.2) !important;
    border-radius: 4px !important;
    z-index: 1;
}

.nicescroll-cursors:after {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    content: "";
}

a {
    text-decoration: none;
    color: #1378bf;
}

a:hover {
    text-decoration: none
}


/* a:visited {
	color:#551a8b; 
}*/


/* Прилепленный к низу футер начало */

.wrap {
    margin: 0 auto;
}

.container {
    padding: 0;
}

/*.container .container {
    width: auto;
}*/

#wrap > .container {
    padding: 0;
}

#footer > .container {
    padding: 0
}

code {
    font-size: 80%
}


/* Немного поправляем стили Boostrap 3*/

label {
    font-weight: normal
}

.topline {
    background: #f8f5ee;
}

.nopadding {
    padding: 0
}


.form-control {
    border-radius: 0;
}

.btn {
    border-radius: 0;
}

.nav-pills > li > a {
    border-radius: 0;
}


/* Стиль верхней серой линии конец*/

.btn-noround {
    border-radius: 0;
}

.btn-red {
    background-color: #d6341d;
    border-color: #d6341d;
    color: #fff;
}

.btn-red:focus,
.btn-red:active,
.btn-red:hover {
    background-color: #d6341d;
    border-color: #d6341d;
    color: #fff;
}


/* Стиль шапки начало */

header {
    margin: 14px 0;
}

.logo {
    background: url("../img/logo_desc.svg") no-repeat left center;
	/*background: url("../img/KD-NY2023.svg") no-repeat left center;*/
    background-size: contain;
    width: 286px;
    max-width: 100%;
    /*width: 305px;*/
    height: 81px;
    /*height: 86px;*/
    display: block;
    margin-top: 6px;
}

.logo:hover {
    text-decoration: none
}

.logo_tablet {
    background: url("../img/logo_desc.svg") no-repeat left center;
	/*background: url("../img/KD-NY2023.svg") no-repeat left center;*/
    background-size: contain;
    width: 236px;
    height: 67px;
    display: block;
    margin-top: 6px;
}

.logo_tablet:hover {
    text-decoration: none
}

.logo2 {
    background: url("../img/logo_desc.svg") no-repeat left center;
	/*background: url("../img/KD-NY2023.svg") no-repeat left center;*/
    width: 230px;
    height: 61px;
    /*margin-left: -20px;*/
    display: block;
}

.logo2:hover {
    text-decoration: none
}

#search {
}

.search-box {
    border-radius: 0;
    width: 345px;
}



.search-box .input-group .form-control {
    border-radius: 0;
}

.search-box .btn {
    border-radius: 0;
}

#search .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
}

.search-helper {
    margin: 1px 0 -5px 0;
    font-size: 12px;
    color: #666;
}

.search-helper span {
    border-bottom: 1px dashed #666;
    cursor: pointer;
}

.search-helper span:hover {
    border-bottom: none
}

.order-stat {
    display: block;
    float: right;
    width: 200px;
}

.order-stat-header {
    display: block;
}

.header-right {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: 100%;
    -ms-flex-direction: 100%;
    flex-direction: 100%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
}

.topline-links {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.topline-links > div {
    width: auto;
    flex: auto 1 1;
}

.toplinks div {
    padding: 0;
    color: #333;
    margin: 0;
}

.toplinks i {
    color: #888
}

.toplinks a {
    text-decoration: none;
}

li.login-register-inline {
    display: inline-block;
    list-style: none;
    margin-right: 0;
}

#search .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
}

.search-helper {
    margin: 1px 0 -5px 0;
    font-size: 12px;
    color: #666;
}

.search-helper span {
    border-bottom: 1px dashed #666;
    cursor: pointer;
}

.search-helper span:hover {
    border-bottom: none
}

.phone {
    line-height: 1;
    text-align: right;
    margin-top: 1px;
    font-size: 13px;
/*    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;*/
    min-width: 187px;
    margin-left: 24px;
}

.number {
    font-size: 22px;
}

.phone i {
    color: #FFF
}

.phone .sep {
    color: #666;
    display: inline;
}

.phone a {
    text-decoration: none;
}

.phone a:hover {
    border-bottom: none
}

#make-callback {
    color: #FFF !important;
    display: block;
    width: 187px;
    /*background: #d53535;*/
    background: #333;
    text-align: center;
    font-size: 12px;
    display: block;
    padding: 2px 0 4px;
    line-height: 1;
    text-decoration: none;
    margin-top: 3px;
    border-radius: 2px;
}

#make-callback:hover {
    background: #cb0202;

}

.work-time {
    display: block;
    opacity: 0.5;
    margin-bottom: 3px;
    margin-top: 0px !important;
}

.right-menu {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.compare-favorites {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    white-space: nowrap;
}

.top-lk {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}


/* Стиль шапки конец */


/* Верхнее меню начало */

.menu-blue {
    background: #c52411;
    height: 55px;
}

#topmenu {
    border: none;
    margin-bottom: 0;
    min-height: 40px;
    background-color: transparent;
}

#topmenu .horizontal-menu > li {
}

.navbar-right li {
    border-right: none
}

#topmenu li.menu-item a {
    color: #434343;
    /*padding: 10px 15px;*/
    padding: 10px;
    color: #333;
    font-size: 14px;
    text-decoration: none;
}

#topmenu li.menu-item a .menu-icon {
    position: relative;
    top: -2px;
    display: inline-block;
    vertical-align: middle;
    font-size: 15px;
    margin-right: 5px;
    color: #ccc;
}

#topmenu li.menu-item a:hover {
    background: #ebe5d7;
}

#topmenu li.menu-item--hl a {
    background: #ffea00;
    font-weight: 700;
}

#topmenu li.menu-item--hl a .menu-icon {
    color: #333;
}

#topmenu li.menu-item--hl a:hover {
    background: #fff583;
}


.right-menu a {
    color: #434343;
    padding: 11px 5px;
    color: #333;
    font-size: 12px;
    text-decoration: none;
    white-space: nowrap;
}

.right-menu a:hover {
    background: #ebe5d7;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
    background: #ebe5d7;
}

.navbar-nav.navbar-right:last-child {
    margin-right: 0px
}


/* Верхнее меню конец */


/* Дерево категорий начало */

#categories,
#popular-categories {
    /* описание шапки блока категорий */
    box-shadow: none;
    margin-bottom: 25px;
}

#categories ol,
#popular-categories ol {
    list-style: none;
    padding: 0;
    margin: 0;
}

#categories ol li,
#popular-categories ol li {
    /* если эта категория имеет подкатегории у которых нет подкатегорий то отступ не показываем (ведь плюсика нет) */
    padding-left: 0
}

#categories ol.havechilds,
#categories ol li ol li,
#popular-categories ol.havechilds,
#popular-categories ol li ol li {
    /* если эта категория имеет подкатегории которые имеют свои подкатегории (то есть показывается плюсик) то ставим отступ */
    padding-left: 20px
}

#categories ol li i,
#popular-categories ol li i {
    /* это иконка плюса, минуса и иконки загрузки */
    color: #888888;
    cursor: pointer !important;
    display: block;
    float: left;
    font-size: 14px;
    margin: 7px 0 0 -16px;
    position: absolute;
}

#categories ol li div,
#popular-categories ol li div {
    /* отступ чтобы показать количество товаров в категории, сейчас скрыто поскольку и количество товаров скрыто */
    /* padding-right: 23px; */
}

#categories ol li a,
#popular-categories ol li a {
    display: block;
    line-height: 1.15;
    padding: 5px 0;
    font-size: 14px;
}

#categories ol li a.active,
#popular-categories ol li a.active {
    font-weight: bold
}

#categories ol li span,
#popular-categories ol li span {
    /* показываем количество товаров в категории */
    display: none;
    color: #C3C3C3;
    font-size: 12px;
    position: absolute;
    right: 24px;
    padding: 2px 0 0 0;
    line-height: 20px;
}

#categories ul li ul li a,
#popular-categories ul li ul li a {
    /* стиль ссылок для подкатегорий */
}

.categories-heading {
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 6px;
    padding-bottom: 6px;
    margin-top: 2px;
}


/* Дерево категорий конец */


/* Дерево категорий начало */

.vertical-menu {
    /* описание шапки блока категорий */
    box-shadow: none;
    margin-bottom: 15px;
}

.vertical-menu ol {
    list-style: none;
    padding: 0;
    margin: 0;
}

.vertical-menu ol li {
    /* если эта категория имеет подкатегории у которых нет подкатегорий то отступ не показываем (ведь плюсика нет) */
    padding-left: 0
}

.vertical-menu ol.havechilds,
.vertical-menu ol li ol li {
    /* если эта категория имеет подкатегории которые имеют свои подкатегории (то есть показывается плюсик) то ставим отступ */
    padding-left: 20px
}

.vertical-menu ol li i {
    /* это иконка плюса, минуса и иконки загрузки */
    color: #888888;
    cursor: pointer !important;
    display: block;
    float: left;
    font-size: 14px;
    margin: 7px 0 0 -16px;
    position: absolute;
}

.vertical-menu ol li div {
    /* отступ чтобы показать количество товаров в категории, сейчас скрыто поскольку и количество товаров скрыто */
    /* padding-right: 23px; */
}

.vertical-menu ol li a {
    display: block;
    line-height: 1.15;
    padding: 5px 0;
    font-size: 15px;
}

.vertical-menu ol li a:hover {
    text-decoration: none;
}

.vertical-menu ol li a.active {
    font-weight: bold
}

.vertical-menu ol li span {
    /* показываем количество товаров в категории */
    display: none;
    color: #C3C3C3;
    font-size: 12px;
    position: absolute;
    right: 24px;
    padding: 2px 0 0 0;
    line-height: 20px;
}

.vertical-menu ul li ul li a {
    /* стиль ссылок для подкатегорий */
}

.vertical-menu-heading {
    font-size: 22px;
    line-height: 1.2;
    margin-bottom: 6px;
    padding-bottom: 6px;
    margin-top: 2px;
    border-bottom: 1px solid #e7e7e7;
}


/* Дерево категорий конец */


/* Бренды начало */

#brands {
    /* рамка блока категорий */
    font-size: 14px;
    border: none;
}

#brands .panel-body {
    padding: 0;
}

.brandlinks {
    display: inline-block;
}

.brandlinks a {
    /* стиль кнопки сброса фильтра */
    text-decoration: none;
    line-height: 1.25;
    padding: 4px 0px 5px;
    font-size: 15px;
    margin-right: 10px;
}

.brandlinks a:active {
    box-shadow: none
}

.brandlinks a span {
    border-bottom: 1px dotted #434343
}

.brandlinks a.current span {
    border-bottom: none;
    font-weight: bold;
}

.brandlinks a:hover span {
    border-bottom: none
}

#brands ul {
    list-style: none;
    padding: 0;
    margin: 0;
    -moz-column-count: 4;
    -moz-column-gap: 10px;
    -webkit-column-count: 4;
    -webkit-column-gap: 10px;
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    -webkit-column-gap: 10px;
    -moz-column-gap: 10px;
    column-gap: 10px;
}

#brands ul li {
    display: block;
    font-size: 15px;
    margin: 0 0 2px 0;
}

#brands ul li a {
    color: #0077af;
}

#brands ul li a.active {
    font-weight: bold
}

#brands ul li span {
    color: #999
}


/* Бренды конец */


/* Корзина начало */

.cart-dropdown .dropdown-menu {
    width: 455px;
    padding: 15px;
}

#cart {
    display: block;
    text-decoration: none;
    float: right;
    height: 55px;
    width: 155px;
    padding: 14px 21px;
    text-align: left;
    background-color: #f8f5ee;
    border: 0;
    vertical-align: middle;
    line-height: normal;
    white-space: nowrap;
    border-radius: 0;
    -webkit-transition: background-color .3s;
    transition: background-color .3s;
    margin-left: 24px;
    border-radius: 2px;
}

.cart-mini-header {
    font-size: 16px;
    font-weight: 500;
    display: block;
    padding-left: 35px;
    line-height: 1;
    color: #333;
    text-transform: uppercase;
}

.cart-mini-price {
    font-size: 10px;
    color: #333;
    opacity: 0.84;
    padding-left: 35px;
    top: 58px; /*38px;*/
    position: absolute;
    display: block;
}

.cart-mini-image {
    background: url("../img/cart.png") no-repeat;
    width: 32px;
    height: 30px;
    display: block;
    position: relative;
    top: -19px;
    left: -10px;
    z-index: 10;
}

.cart-counter {
    font-size: 10px;
    position: relative;
    color: #fff;
    border-radius: 50px;
    padding: 3px 7px 3px;
    background: #cd2a14;
    border: 2px solid #f7f7f7;
    top: -57px;
    left: 2px;
    display: inline-block;
    z-index: 20;
}

#cart:hover {
    background-color: #eeeae1;
}

.order-stat {
    display: block;
    text-decoration: none;
    float: right;
    height: 55px;
    width: 216px;
    padding: 14px 21px;
    color: #FFF;
    text-align: left;
    background-color: #c52411;
    border: 0;
    vertical-align: middle;
    line-height: normal;
    white-space: nowrap;
    border-radius: 0;
    -webkit-transition: all ease .3s;
    transition: all ease .3s;
    background: url("../img/order.png") no-repeat 21px 16px;
}

.order-stat-header {
    font-size: 16px;
    font-weight: 500;
    display: block;
    padding-left: 44px;
    padding-top: 3px;
    line-height: 1;
    color: #FFF;
    text-transform: uppercase;
}

.order-text {
    font-size: 10px;
    color: #fff;
    opacity: 0.84;
    padding-top: 0px;
    padding-left: 44px;
    margin-top: 1px;
    display: block;
}

.order-stat:hover {
    background-color: #1d9ad5;
}

.fiz-ur {
    display: block;
    margin-bottom: 15px;
}

.checkout {
    line-height: 1.45 !important;
    color: #FFF !important;
    padding: 6px 0 !important;
    text-decoration: none;
}

.checkout:hover,
.checkout:focus {
    color: #FFF !important;
    background: #47A447 !important;
    border-color: #398439 !important;
}

.cart-image {
    display: block;
    float: left;
    width: 60px;
    margin-right: 8px;
}

.cart-image a {
    box-sizing: border-box;
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
    display: block;
    height: 58px;
    width: 58px;
    line-height: 52px;
    padding: 2px;
    border-radius: 2px;
    margin: 0;
    text-align: center;
    vertical-align: middle;
}

.cart-image img {
    max-width: 52px;
    max-height: 52px;
    margin-top: -2px;
}

.cart-product-name {
    font-size: 13px;
    width: 252px;
    display: block;
    float: left;
    margin-right: 8px;
}

.cart-product-name a {
    display: block
}

.cart-product-price {
    font-size: 13px
}

.cart-product-price span {
    font-size: 13px
}

.cart-intotal {
    display: table;
    clear: both;
    width: 100%;
}

.cart-intotal-right {
    display: block;
    width: 60%;
    float: right;
    text-align: right;
}

.cart-buy1click {
    display: block;
    width: 40%;
    float: left;
}

.cart-intotal span {
    font-weight: bold
}

.cart-delete {
    width: 10px;
    display: table-cell;
    vertical-align: top;
    line-height: 16px;
    float: right;
}

.cart-priceblock {
    width: 80px;
    display: block;
    float: left;
    font-size: 12px;
    margin-right: 5px;
    text-align: right;
}

.cart-delete a {
    color: #333;
    opacity: 0.5;
}

.cart-delete a:hover {
    opacity: 1
}

.cart-product-name span {
    color: #666
}

.cart-amount {
    opacity: 0.9
}

.in-cart-item {
    display: table;
    clear: both;
    margin-bottom: 7px;
}

.carttable-info {
    background: #ffffdd
}

.carttable-image {
    display: block;
    position: absolute;
    width: 60px;
    margin-right: 8px;
}

.carttable-image a {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
    display: block;
    height: 58px;
    width: 58px;
    line-height: 52px;
    padding: 2px;
    margin: 0;
    text-align: center;
    vertical-align: middle;
}

.carttable-image img {
    max-width: 52px;
    max-height: 52px;
    margin-top: -2px;
}

.carttable-product-name {
    font-size: 14px;
    display: block;
    float: left;
    padding-left: 66px;
    min-height: 62px;
}

.carttable-product {
    width: 350px
}

.carttable-product-inside {
    display: table;
    clear: both;
}

.carttable-product {
    width: 350px
}

.carttable-priceone {
    width: 90px;
    white-space: nowrap;
    text-align: right;
}

.carttable .carttable-count {
    width: 115px;
    padding-top: 5px !important;
    padding-left: 30px !important;
}

.carttable-count .btn-sm {
    padding: 2px 6px 2px;
    color: #666;
    font-size: 14px;
    border: none;
    margin: 0 1px 0 1px !important;
    border-radius: 4px !important;
    border: none !important;
    box-shadow: none !important;
}

.carttable-count .btn-sm.disabled i {
    color: #666 !important;
    box-shadow: none;
}

.carttable-count .btn-sm.focus {
}

.carttable-count .btn-sm i {
    color: #999
}

.carttable-count .btn-sm:hover i {
    color: #333
}

.carttable-count input {
    text-align: right;
    padding: 3px 5px 2px;
    height: 25px;
    border: 1px solid #CCCCCC !important;
    border-radius: 4px !important;
}

.carttable-priceall {
    width: 110px;
    text-align: right;
}

.carttable-remove {
    width: 20px;
    text-align: right;
}

.carttable-remove a {
    color: #C00;
    font-size: 14px;
    line-height: 14px;
    width: 11px;
    background: #f3f3f3;
    padding: 2px 4px 3px 5px;
    border-radius: 4px;
}

.carttable-remove a:hover {
    background: #e6e6e6;
}

.carttable {
    margin-bottom: 0
}

.carttable tr th {
    font-size: 12px;
    color: #666;
}

.carttable tr th,
.carttable tr td {
    padding: 6px !important;
}

.carttable tr td {
    border-top: 1px solid #eee !important;
}

.carttable tr th {
    border-bottom: 2px solid #eee !important;
}

.subtotal {
    font-size: 16px;
    font-weight: 500;
    text-align: right;
    border-top: 1px solid #f5f5f5;
    display: block;
    padding: 8px;
}

.notopline {
    border-top: none !important;
    padding-top: 0 !important;
    margin-top: -8px !important;
}

.delivery-text {
    font-size: 12px
}

.delivery-text ul {
    padding-left: 10px;
}

#delivery-settings {
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid #f5f5f5;
}

.delivery-text p:last-child {
    margin-bottom: 10px;
}

.delivery-select-list {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    margin: 0 -30px -30px 0;
}

.delivery-select {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    font-size: 16px;
    padding: 0 30px 30px 0;
}

.delivery-select__inner {
    position: relative;
    display: flex;
    height: 100%;
    min-height: 62px;
    align-items: center;
    background: #f6f6f6;
}

.delivery-select__inner:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    box-shadow: inset 0 0 0 1px #ccc;
    content: '';
    pointer-events: none;
}

.delivery-select.active .delivery-select__inner:before {
    box-shadow: inset 0 0 0 2px #d6341d;
}

.delivery-select .radio {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    font-size: 16px;
    border-bottom: none;
    margin: 0;
}


.delivery-select .radio > label {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 9px;
}

.delivery-select .radio input {
    position: static;
    left: auto;
    top: auto;
    margin: 5px 0 0;
    align-self: flex-start;
}


.delivery-header {
    margin-left: 10px;
    flex: auto 1 1;
    width: 90%;
    width: calc(100% - 30px);
    font-size: 18px;
    line-height: 1.2;
    align-self: flex-start;
}

.delivery-external-module-block {
    flex: auto 1 1;
    width: 100%;
    margin-top: 15px;
    padding-left: 23px;
}

.delivery-more {
    order: 10;
    /*align-self: flex-end;*/
    margin-top: auto;
    flex: auto 1 1;
    padding-top: 9px;
    padding-bottom: 1px;
    margin: 0 -9px -9px;
}

.delivery-text {
    padding: 0 9px;
}

.delivery-select .btn-info {
    display: block;
    color: #333!important;
    background: #e5e5e5!important;
    border-color: #e5e5e5!important;
    box-shadow: none!important;
    border-radius: 0;
    padding: 2px 5px 3px;
    line-height: 1.2!important;
}

.delivery-select .btn-info .btn-inner {
    display: inline-block;
    vertical-align: top;
    line-height: 1.2;
    color: #333;
    border-bottom: 1px solid #999;
}


.payment-select {
    display: block;
    display: flex;
    flex-wrap: wrap;
    margin-right: -30px;
    margin-bottom: -20px;
    font-size: 0;
}

.payment-select-header {
    font-size: 18px
}

.payment-select .radio {
    font-size: 13px
}

.payment-method {
    display: inline-block;
    vertical-align: top;
    width: 25%;
    font-size: 16px;
    padding: 0 30px 20px 0;
    transition: background 0.2s ease;
}

.payment-method__inner {
    position: relative;
    display: flex;
    height: 100%;
    min-height: 62px;
    align-items: center;
    background: #fff;
}

.payment-method__inner:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    box-shadow: inset 0 0 0 1px #ccc;
    content: '';
    pointer-events: none;
}

.payment-method.active .payment-method__inner:before {
    box-shadow: inset 0 0 0 2px #d6341d;
}

.payment-method .radio {
    margin: 0;
    width: 100%;
}

.payment-method .radio label {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 9px;
}

.payment-method .radio input {
    position: static;
    left: auto;
    top: auto;
    margin: 0;
}

.payment-image {
    order: 2;
    flex: auto 0 0;
    margin-left: 10px;
    display: block;
    height: 40px;
    line-height: 40px;
    max-width: 60px;
    text-align: center;
}

.payment-image img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

.payment-header {
    margin-left: 10px;
    flex: auto 1 1;
    font-size: 16px;
}

.delivery-subtotal {
    font-size: 18px;
    text-align: right;
    display: table;
    width: 100%;
    padding: 4px 8px;
    background: #f9f9f9;
    border-radius: 4px;
    font-weight: 500;
}

.sub-price {
    /*width: 150px;*/
    display: inline-block;
    font-size: 22px;
}

.delivery-subtotal .sub-price {
    font-size: 26px
}

.discount-badge {
    text-decoration: none !important;
    display: inline-block;
    font-size: medium !important;
}

.userdata {
    display: block;
}

.userdata .form-group.userdata-nomargin {
    margin-bottom: 0px
}

.help-block-error {
    position: relative;
    font-size: 12px;
    line-height: 13px;
    color: #FFF;
    background: #d6341d;
    border-radius: 4px;
    display: inline-block;
    padding: 4px 5px 4px;
    margin-top: 5px;
}

.help-block-error:before {
    position: absolute;
    content: "";
    top: -5px;
    left: 5px;
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #d6341d transparent;
}

.carttable-remove span {
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    color: #C00;
}

.userdata .help-block {
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 0;
}

.userdata-link {
    font-size: 12px;
    color: #888;
    background: #f7f7f7;
    padding: 4px;
    border-radius: 4px;
    opacity: 0.6;
}

.enter-link {
    color: #428BCA;
    opacity: 1;
    background: none;
    padding: 0;
}

.userdata-link:hover {
    border-bottom: none
}

.userdata-link.on {
    color: #20A800;
    opacity: 1;
}

.userdata-link.off {
    color: #FF0000;
    opacity: 1;
}

.region {
    display: inline-block;
    padding-right: 5px;
}

.another-region {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    width: 250px;
}

.yandex-map {
    margin: 5px -8px 10px;
    border-top: 1px solid #DDD;
    border-bottom: 1px solid #DDD;
}

.enterphone {
    display: table;
    clear: both;
}

.phone-code {
    width: 85px;
    display: block;
    float: left;
}

.phone-code input {
    padding: 6px;
    border-radius: 4px !important;
}

.phone-number {
    width: 85px;
    display: block;
    float: left;
}

.phone-code .input-group-addon {
    background: #FFF;
    padding: 8px 3px;
    border: none;
}

.userdata-checkout {
    margin-bottom: 0px
}

.userdata .cart-ok {
    color: #47a447;
    font-size: 22px;
    position: absolute;
    right: -22px;
}

.userdata .cart-ok.phone-ok {
    left: 201px
}

.userdata .hint {
    padding-left: 30px
}

.userdata label span {
    color: #900
}

.cart-pod-zakaz {
    display: block;
    margin-top: 3px;
    display: none;
}

.cart-pod-zakaz span {
    display: inline-block;
    font-size: 12px;
    color: #666;
    background: #f5f5f5;
    padding: 2px 5px;
    border-radius: 2px;
}

.cart-offer {
    /* Баннер в корзине */
    display: table;
    clear: both;
    width: 100%;
    background: #dff0d8;
    padding: 10px;
    color: #333;
    font-size: 16px;
    border-radius: 4px;
    margin-bottom: 15px;
}

.cart-offer p {
    margin-bottom: 0;
}

.cart-header {
    font-size: 25px;
    line-height: 1.05;
}

.cart-header2 {
    font-size: 22px;
    line-height: 1.05;
}

.cart-block {
    margin-bottom: 25px;
}

.cart-block-last {
    border: none !important;
    margin-bottom: 0;
    padding-bottom: 0;
}

.cart-block-first {
}

.cart-block .col-xs-3 {
    width: 32%;
}

.cart-block .col-xs-9 {
    width: 68%;
}

.hint {
    font-size: 12px;
}

.warning-block {
    padding: 15px;
    background-color: #ccc;
    margin: 0 0 10px;
    font-size: 18px;
    text-align: center;
}

.warning-block--blue {
    background-color: #d9edf7;
}

.cart-modifier {
    display: table;
    clear: both;
    width: 100%;
    margin-top: 2px;
}

.cart-modifier ul {
    margin: 0;
    padding: 0;
}

.cart-modifier ul li {
    display: block;
    list-style: none;
}

.cart-modifier li div {
    display: inline-block;
    font-size: 12px;
    color: #333;
    background: #fcf8e3;
    padding: 2px 5px;
    border-radius: 2px;
    margin-bottom: 2px;
}

.delivery-external-module-block .col-md-6 {
    margin: 0;
    padding: 0;
}

.delivery-external-module-block label {
    margin: 0!important;
    padding: 0!important;
    display: inline-block;
    vertical-align: top;
    font-size: 12px;
    min-height: 0;
    line-height: 1.2;
    color: #333;
    border-bottom: 1px solid #999;
}

.delivery-external-module-block .form-group {
    width: 200px !important;
    display: table;
    clear: both;
    width: 100%;
    margin-top: 15px;
}

.delivery-external-module-block .form-group:first-child {
    margin-top: 0;
}

#extModuleMkadKilometers {
    margin: 0;
}

.delivery-external-module-block .input-group {
    width: 130px;
}

.delivery-external-module-block .input-group input {
    height: 31px;
}

.delivery-external-module-block .input-group input {
    text-align: right;
    border: 1px solid #CCCCCC !important;
    border-radius: 4px !important;
}

.delivery-external-module-block .input-group .btn-sm {
    color: #666;
    font-size: 14px;
    border: none;
    margin: 0 1px 0 1px !important;
    border-radius: 4px !important;
    border: none !important;
    box-shadow: none !important;
}

.delivery-external-module-block .input-group .btn-sm.disabled i {
    color: #666 !important;
    box-shadow: none;
}

.delivery-external-module-block .input-group .btn-sm {
    background-color: #e6e6e6;
}

.delivery-external-module-block .input-group .btn-sm.focus {
}

.delivery-external-module-block .input-group .btn-sm i {
    color: #999
}

.delivery-external-module-block .input-group .btn-sm:hover i {
    color: #333
}


/* Корзина конец */


/* Страница заказа начало */

.ordertable, .complecttable {
    margin: 0 0 20px;
}

.ordertable tr th, .complecttable tr th {
    font-size: 12px;
    color: #666;
}

.ordertable tr th,
.ordertable tr td,
.complecttable tr th,
.complecttable tr td {
    padding: 6px !important
}

.complecttable .variant-hidden {
    display: none !important;
}

.ordertable-priceall {
    width: 110px;
    text-align: right;
}

.ordertable-image {
    display: block;
    position: absolute;
    width: 60px;
    margin-right: 8px;
}

.ordertable-image a {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
    display: block;
    height: 58px;
    width: 58px;
    line-height: 52px;
    padding: 2px;
    border-radius: 2px;
    margin: 0;
    text-align: center;
    vertical-align: middle;
}

.ordertable-image img {
    max-width: 54px;
    max-height: 54px;
    margin-top: -2px;
}

.ordertable-product-name {
    font-size: 14px;
    display: block;
    float: left;
    padding-left: 66px;
    min-height: 62px;
}

.ordertable-product {
    width: 350px
}

.ordertable-priceone {
    width: 110px;
    text-align: right;
}

.ordertable-count {
    width: 115px;
    text-align: right;
    padding-top: 5px !important;
    padding-left: 30px !important;
}

.order-subtotal {
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    border-top: 1px solid #DDDDDD;
    display: block;
    padding: 8px;
}

.order-subtotal .order-sub-price {
    width: 140px;
    font-weight: normal !important;
    display: inline-block;
    font-size: 22px;
}

.order-total {
    background: #F9F9F9;
    border-radius: 4px;
    display: table;
    font-size: 18px;
    margin-bottom: 10px;
    padding: 4px 8px;
    text-align: right;
    width: 100%;
    font-weight: 500;
}

.order-total i {
    color: #656565;
    font-size: 14px;
}

.order-total .order-sub-price {
    width: 140px;
    font-weight: normal !important;
    display: inline-block;
    font-size: 22px;
}

.firsttd {
    width: 200px
}

.selected-payment {
    padding: 15px;
    display: table;
    clear: both;
    width: 100%;
    border-radius: 4px;
    background: #F9F9F9;
    margin-bottom: 10px;
}

.selected-payment:after {
    display: table;
    clear: both;
    content: "";
}

.change-payment {
    float: right;
}

.selected-payment-legend {
    display: table;
    clear: both;
    margin-bottom: 7px;
}

.selected-payment-legend span {
    display: block;
    font-size: 21px;
    float: left;
    width: 490px;
}

.selected-payment-image {
    display: block;
    float: left;
    width: 148px;
    line-height: 48px;
    text-align: center;
    margin-right: 10px;
    border: 1px solid #f1f1f1;
    border-radius: 4px;
    margin: 0 14px 8px 0;
    padding: 4px;
    background: #fff;
}

.selected-payment-header {
    font-weight: bold;
}

.selected-payment-text {
    margin-left: 170px;
}

.selected-payment-description {
    display: table;
    clear: both;
}

.selected-payment-description:after {
    display: table;
    clear: both;
    content: "";
}

.selected-payment-action {
    padding: 15px;
    background-color: #fcf8e3;
    border-radius: 4px;
}

.selected-payment-action .help-block {
    font-size: 12px;
    margin-top: 1px;
    margin-bottom: 5px;
    color: #a5a5a5;
}

.change-payment {
    display: none;
}


/* Страница заказа конец */


/* Баннер вверху НАЧАЛО */

#top-banner {
    min-height: 70px;
    margin: -5px 0px 10px 0px;
    opacity: 1;
    transition: 0.2s;
    display: flex;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px #eee;
    padding: 0 0 !important;
}


#top-banner .top-banner-wrap {
    width: 100%;
    /*padding: 0 15px;*/
    padding: 0 1px;
}

#top-banner:hover {
    opacity: 0.85;
    transition: 0.2s;
}

.top-banner-first {
    background: url("../img/top-banner/top-banner-first.png") no-repeat 95% center;
    min-height: 72px;
}

.top-banner-second {
    background: url("../img/top-banner/top-banner-second.png") no-repeat 5% center;
    min-height: 72px;
}

#top-banner-2 {
    background: url(/venmi/views/admin/default/img/banner-2.png) 100% 85% no-repeat #b82b1a;
    color: #fff;
    padding: 20px 15px 23px 15px;
    border-radius: 4px;
    margin-bottom: 10px;
    font-size: 20px;
    background-size: 625px;
    letter-spacing: 0.3px;
    box-shadow: inset 0px 0px 15px 2px RGBA(0, 0, 0, 0.075);
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
}

.top-banner-2-first {
    font-size: 24px;
    margin-bottom: 5px;
}

.top-banner-2-second {
}

.top-banner-2-second span {
    display: inline-block;
    background: rgba(182, 41, 26, 0.85);
    padding-right: 10px;
    border-radius: 4px;
}

.top-banner-2-second em {
    font-style: normal;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
    padding-bottom: 1px;
    color: #ffde92;
}

.top-banner-2-third p {
    margin-bottom: 0px;
    font-size: 14.26px;
    display: inline-block;
    background: rgba(182, 41, 26, 0.85);
    padding-right: 10px;
    border-radius: 4px;
}


/* Баннер вверху КОНЕЦ */


/* Новости начало */

.news-main {
    padding: 35px 0;
    display: block;
}

.articles {
    margin-top: 30px;
}

.articles:first-child {
    margin-top: 0;
}

.articles .head a {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 9px;
    display: block;
    text-decoration: none;
    color: #838e92;
}

.articles a {
    color: #464646;
}

.articles .head a:hover {
    text-decoration: underline
}

.articles ul {
    margin: 0 -15px -10px -15px;
    padding: 0 5px;
    font-size: 0;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.articles ul li {
    display: inline-block;
    vertical-align: top;
    font-size: 0;
    width: 25%;
    min-width: 220px;
    padding-left: 10px;
    padding-right: 10px;
    list-style: none;
    padding-bottom: 10px;
    font-size: 13px;
    white-space: normal;
}

.articles ul li img {
    max-width: 100%;
}

.articles ul li:last-child {
    margin-right: 0;
}

.allnews {
    display: none;
}

.articles .date {
    font-size: 12px;
    color: #999;
}

.news-header,
.readmore {
    display: block;
    margin-bottom: 10px;
    font-weight: 500;
}

.previewimage {
    float: right;
    margin: 0 0 0 5px;
}


/* Новости конец */


/* Просмотренные товары начало */

#viewedproducts {
    /*margin-left: -15px;*/
    border-top: 1px solid #eeeeee;
    padding: 35px 0;
}

#viewedproducts .container {
    padding: 0;
}

.viewedproducts-header {
    font-size: 21px;
    display: block;
    font-weight: 700;
    margin-bottom: 15px;
    text-transform: uppercase;
    /*margin-left: 7px;*/
}

.viewedproducts {
    padding: 0;
    /*margin: 0 -15px;*/
    display: table;
    clear: both;
}

.viewedproducts-owl {
    display: block;
}

.viewedproducts-owl .owl-tiles {
    display: flex;
    overflow: hidden;
}

.viewedproducts-owl .owl-tiles.inited {
    overflow: visible;
}

.viewedproducts-owl .owl-stage-outer {
    width: 100%;
    flex: auto 1 0;
}

.viewedproducts-link {
    list-style: none;
    display: block;
    float: left;
    margin: 5px 15px 10px 15px;
    width: 170px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .07);
    text-align: center;
    text-decoration: none;
    border-radius: 0px;
    padding: 4px;
}

.viewedproducts-link:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .14);
}

.viewedproducts-image {
    line-height: 162px;
    display: block;
    text-align: center;
    margin-bottom: 4px;
}

.viewedproducts-image img {
}

.viewedproducts-text {
}

.viewedproducts-name {
    line-height: 1.4em;
    font-size: 13px;
    display: block;
    overflow: hidden;
    max-height: 2.8em;
    color: #333;
}

.viewedproducts-price {
    font-size: 15px;
    margin: 7px 0 0;
    white-space: nowrap;
    color: #2b2b2b;
    font-weight: 700;
    text-decoration: none;
}

.viewedproducts--horz {
    /*margin-left: 2px;
    margin-right: 25px;*/
    margin-left: -6px;
    margin-right: -6px;
}

.viewedproducts-link--horz {
    margin-left: 6px;
    margin-right: 6px;
    width: 236px;
}

.viewedproducts-link--horz .viewedproducts-image {
    float: left;
    width: 45%;
    padding: 10px;
    height: 100px;
    line-height: 80px;
}

.viewedproducts-link--horz .viewedproducts-image img {
    display: inline-block;
    height: auto;
    width: auto;
    max-height: 80px;
    max-width: 100%;
}

.viewedproducts-link--horz .viewedproducts-text {
    float: left;
    width: 55%;
    padding: 5px 10px 5px 0;
    text-align: left;
    margin: 0;
    line-height: 90px;
}

.viewedproducts-link--horz .viewedproducts-text-inner {
    display: inline-block;
    line-height: 1.25;
    vertical-align: middle;
    width: 100%;
}


/* Просмотренные товары конец */


/* Контент начало */

h1 {
    position: relative;
    font-size: 25px;
    margin-top: 0;
    font-weight: 700;
    color: inherit;
    line-height: 1.1;
    margin: 0 0 20px;
    padding-bottom: 18px;
}

h1:before {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    /*border-bottom: 3px solid #eee;*/
    content: "";
}

#content h2 {
    font-size: 1.5em;
    margin-top: 20px;
    margin-bottom: 10px;
    color: inherit;
    font-weight: 500;
    line-height: 1.1;
}


/* Контент конец */


/* Блок товаров на главной начало */

.products-block {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px dashed #E3E3E3;
}

.products-block-header {
    font-size: 21px;
    margin-bottom: 15px;
}

.all-products-block {
    display: block;
    float: right;
    font-size: 14px;
    line-height: 31px;
}


/* Блок товаров на главной конец */


/* Список товаров - стиль плитка начало */

.plitka {
    margin: 0 -6px;
    display: block;
}

.hit-orange .plitka-item {
    background-color: #fff;
}

.plitka-item {
    /*width: 33.3%;*/
    width: 25%;
    margin: 0;
    display: block;
    float: left;
    list-style: none;
    position: relative;
    padding: 7px;
    /*padding: 12px 18px 6px 18px;*/
    /*padding: 1px 1px 1px 1px;*/
    -webkit-transition: border-color 0.3s;
    transition: border-color 0.3s;
    /*border: solid 1px #eee;*/
}

.plitka-item:nth-child(4n+1) {
    clear: both;
}

.plitka-item:before {
    position: absolute;
    /*top: 0;
    bottom: 0;*/
    left: 6px;
    right: 6px;
    /*border: 1px solid #eeeeee;*/
    -webkit-transition: border-color 0.3s;
    transition: border-color 0.3s;
    content: "";
    z-index: -1;
}

.plitka:after {
    clear: both;
    display: table;
    content: "";
}

.plitka-item:hover:before {
    border-color: #CCCCCC
}

/*
.product-inner {}

.product-inner:hover {
}
*/
.product-inner {
    position: relative;
    z-index: 5;
    /*border: 1px solid #eeeeee;*/
    background-color: #fff;

    border: solid 2px #eee;
    padding: 5px;
}

.product-inner:hover {
    z-index: 6;
    background: #FFF;
}

.product-inner:hover .plitka-name {
    color: #e76a41;
}

.product-inner:hover .plitka-description {
    display: block;
}

.product-inner-two {
    /*border: 2px solid #FFF;*/
    /*padding: 12px 12px 6px 12px;*/
    -webkit-transition: border-color 0.3s;
    -o-transition: border-color 0.3s;
    transition: border-color 0.3s;
}

.product-inner-two:hover {
    border-color: #eeeeee;
}

.touch .product-inner-two:hover {
    border-color: #FFF;
}

.plitka-image-more {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    /*z-index: 1;*/
}

.plitka-image-more-items {
    display: table;
    width: 100%;
    height: 100%;
    table-layout: fixed;
}

.plitka-image-more-item {
    display: table-cell;
    vertical-align: top;
    height: 100%;
    cursor: pointer;
}

.plitka-image {
    display: block;
    line-height: 220px;
    height: 220px;
    text-align: center;
    margin-bottom: 4px;
    overflow: hidden;
}

.plitka-image img {
    max-height: 220px;
    line-height: 1;
}

.plitka-name-block {
    display: block;
    padding: 5px 0;
}

.plitka-name-block .rating-micro {
}

.plitka-name {
    font-size: 14px;
    line-height: 1.20;
    display: inline-block;
    color: #333;
    overflow: hidden;
    height: 48px;
}

.plitka-name:hover {
    color: #333;
}

.plitka-description {
    font-size: 13px;
    display: block;
    overflow: hidden;
    max-height: 55px;
    opacity: 0.85;
}

.property-block {
    font-size: 12px;
    line-height: 18px;
}

.property-name {
    color: #878787;
}

.property-strong .property-value {
    font-weight: 700;
}

.plitka-readmore {
    font-size: 13px;
    display: block;
    float: left;
    width: 80px;
}

.country-tag {
    font-size: 13px;
    display: block;
    float: left;
    width: 110px;
    color: #797979;
    margin-bottom: 2px;
    display: none;
}

.plitka-status {
    display: block;
    font-size: 13px;
    color: #666;
    width: 150px;
    margin: 4px 0 8px;
    opacity: 0.85;
}

.plitka-status .to-order,
.plitka-status .in-stock {
}

.plitka-hidden {
    display: block;
}

.plitka-statusbar {
    display: table;
    clear: both;
    width: 100%;
    margin: 4px 0;
}

.plitka-buy-block {
    display: table;
    clear: both;
    width: 100%;
    margin-top: 2px;
}

.plitka-price {
    font-size: 20px;
    line-height: 20px;
    display: block;
    margin: 6px 0 0;
    font-weight: 700;
    color: #1c1c1c;
    display: inline-block;
}

.have-oldprice-tile {
    color: #ff001e;
    margin-right: 8px;
}

.plitka-tradein-price {
	display: inline-block;
    font-size: 20px;
    font-weight: 700;
	color: #ff001e;
	margin-right: 8px;
	position: relative;
	z-index: 1000;
}

.have-tradein-price-tile {
	color: #1c1c1c;
	font-size: 16px;
}

.plitka-price span {
    font-size: 14px;
}

.plitka-gift {
    position: relative;
    z-index: 2;
    float: right;
    font-size: 32px;
    line-height: 1;
    margin: 0 0 -5px 10px;
}

.plitka-gift .icon {
    vertical-align: top;
}

.plitka-buy {
    text-decoration: none;
    font-size: 16px;
    text-transform: uppercase;
    float: right;
    background: #f7f7f7;
    color: #505050;
    padding: 6px 12px;
    border-radius: 2px;
    /* opacity: 0.6; */
}

.plitka-buy:hover {
    color: #3b833b;
    cursor: pointer;
    background: #f1f1f1;
    /*opacity: 1;*/
}

.badge-overlay {
    display: block;
    width: 206px;
    position: absolute;
    z-index: 1;
}

.plitka-list-badges {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
}

.plitka-list-badges li {
    display: inline-block;
}

.plitka-list-badges:after {
    clear: both
}

.plitka-variants {
    margin: 0 0 10px 0
}

.plitka-variants label {
    font-size: 12px;
    color: #666;
}

.plitka-variants .input-sm {
    padding: 5px 6px
}

.plitka-variants .to-order {
    font-style: italic
}

.plitka-variants .out-of-stock {
    color: #999
}

.plitka-old-price {
    /* Старая цена */
    text-decoration: line-through;
    color: #1c1c1c;
    display: inline-block;
    font-weight: 500;
    font-size: 16px; /*13px;*/
    margin-right: 8px;
}

.plitka-old-price span {
    /* Символ валюты у старой цены */
}

.plitka-separator {
    /* Разделитель, ставится при отображении товаров плиткой после каждого третьего товара */
    /*padding-bottom: 12px;*/
    display: block;
    clear: both;
}

.plitka .plitka-separator:last-child {
    border: none
}

.plitka-properties {
    margin-top: 8px;
}

.plitka-properties table {
    margin-bottom: 4px;
}

.plitka-properties table tr td:first-child {
    padding-left: 0;
}

.plitka-properties table tr td:last-child {
    padding-right: 0;
}

.plitka-properties table tr:first-child td {
    border-top: none;
}

.plitka-properties table tr td {
    color: #4e4e4e;
    font-size: 13px;
    padding: 4px;
}

.plitka-variants-count {
    display: block;
    width: 206px;
    position: absolute;
    margin-top: 200px;
    z-index: 1;
    text-align: right;
    font-style: italic;
    font-size: 12px;
}

.plitka-variants-count span {
    background-color: rgba(255, 255, 255, 1);
    padding: 3px 6px;
    border-radius: 2px;
    display: inline-block;
    line-height: 1.1;
    color: #333;
    opacity: 1;
}


/* Список товаров - стиль плитка конец */


/* Список товаров - стиль список начало */

.list {
    padding: 0;
    display: table;
    clear: both;
    margin-bottom: 20px;
}

.list .listitem {
    list-style: none;
    display: block;
    float: left;
    width: 100%;
    padding: 0;
    border: 1px solid #eee;
    border-bottom: none;
}

.list .listitem:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 1px solid #eee;
}

.listitem-inner {
    border: 2px solid #FFF;
    padding: 12px;
    -webkit-transition: border-color 0.3s;
    transition: border-color 0.3s;
    display: table;
    clear: both;
    width: 100%;
}

.list-left {
    width: 175px;
    display: block;
    float: left;
    margin-right: 20px;
}

.list-center {
    width: 310px;
    display: block;
    float: left;
}

.list-image {
    line-height: 175px;
    display: block;
    text-align: center;
}

.list-right {
    display: block;
    float: right;
    width: 170px;
}

.list-right .to-order {
    display: none;
}

.list-image img {
}

.rate-overlay {
    display: block;
    width: 206px;
    position: absolute;
    margin-top: 150px;
    z-index: 1;
}

.list-name-block {
    margin: 0 0 10px 0;
    display: block;
}

.list-name-block a {
    color: #333;
    font-size: 16px;
    font-weight: normal;
}

.list-name {
    font-size: 16px;
    font-weight: 500;
    display: inline;
    line-height: 1.15;
}

.list-badges {
    display: table;
    margin: 0 0 5px 0;
}

.list-badges:after {
    clear: both
}

.list-description {
    margin-bottom: 5px;;
    display: table;
    clear: both;
    width: 100%;
    font-size: 12px;
    color: #4d4d4d;
}

.list-properties {
    margin: 0px 0 9px 0;
}

.list-properties .table {
    margin-bottom: 0;
    font-size: 13px;
}

.list-description p:last-child {
    margin-bottom: 0;
}

.list-readmore {
}

.list-old-price span {
    /* Символ валюты у старой цены */
}

.list-block-price {
    display: table;
    clear: both;
    width: 100%;
}

.list-price {
    /* Цена товара в списке товаров */
    font-size: 24px;
    line-height: 24px;
    display: inline-block;
    font-weight: bold;
}

.have-old-price-list {
    color: #ff001e;
    margin-right: 8px;
}

.list-old-price {
    text-decoration: line-through;
    color: #1c1c1c;
    display: inline-block;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
}

.list-price span {
    /* Символ валюты у цены */
    font-size: 14px;
}

.list-buy {
    text-decoration: none;
    margin-top: 6px;
    font-size: 16px;
    text-transform: uppercase;
}

.product-status {
    /* стиль статусов заказа */
    display: block;
    font-size: 13px;
    margin-bottom: 3px;

}

.in-stock {
    font-weight: bold;
    color: #5cb85c;
}

.in-stock i {
    /* галочка в наличии */
    /* color: #093; */
}

.out-of-stock {
    color: #333;
    font-weight: bold;
}

.out-of-stock i {
    /* галочка нет в наличии */
    /* color: #900; */
}

.to-order {
    color: #333;
    font-weight: bold;
}

.buy-one-click {
    /* Заказ в 1 клик */
    display: block;
    margin-top: 8px;
    font-size: 13px;
}

.buy-one-click {
    /* Заказ в 1 клик */
    display: block;
    margin-top: 8px;
    font-size: 13px;
}

.compare {
    /* Добавить к сравнению */
    display: block;
    margin-top: 5px;
    font-size: 13px;
}

.list-variants {
    margin: 5px 0;
}

.list-variants label {
    font-size: 12px;
    color: #666;
}

.list-variants .input-sm {
    padding: 5px 6px
}

.list-variants .to-order {
}

.list-variants .out-of-stock {
    color: #999
}

.list-reviews {
    text-decoration: none;
    color: #4d4d4d !important;
    font-size: 13px;
    display: inline-block;
    margin-right: 12px;
}

.list-reviews-cover a i {
    color: #B7B7B7;
}

.list-compare {
    display: inline-block;
}

.list-compare a.dotted {
    text-decoration: none;
    border-bottom: 1px dotted;
}

.list-compare a.dotted:hover {
    border-bottom: none
}


/* Список товаров - стиль список конец */


/* Список товаров списка Избранного - начало */

.favotite-list {
    padding: 0;
    display: table;
    clear: both;
    margin-bottom: 20px;
}

.favorite-item {
    list-style: none;
    display: block;
    float: left;
    margin: 0 0 10px 0;
    width: 720px;
    padding: 0 0 10px 0;
    border-bottom: 1px solid #eeeeee;
}

.favorite-list-badges {
    display: table;
    clear: both;
    margin-bottom: 8px;
    padding: 0;
}

ol.favorite-list-badges li {
    list-style: none;
    display: inline-block;
    margin-right: 4px;
}

.favorite-image {
    line-height: 100px;
    display: block;
    text-align: center;
    margin-bottom: 4px;
}

.favorite-left {
    width: 100px;
    display: block;
    float: left;
    margin-right: 10px;
}

.favorite-center {
    width: 465px;
    display: block;
    float: left;
}

.favorite-right {
    width: 130px;
    display: block;
    float: right;
    padding: 9px;
    background: #ebe5d7;
    border-radius: 4px;
}

.favorite-description {
    font-size: 12px;
}

.favorite-name-block {
    margin: 2px 0 5px 0;
    display: block;
}

.favorite-name {
    font-size: 16px;
    display: inline;
    line-height: 1.15;
}

.favorite-status {
    display: block;
    font-size: 12px;
    margin-bottom: 3px;
    color: #666;
}

.favorite-price {
    font-size: 20px;
    line-height: 20px;
    width: 132px;
    display: block;
    float: left;
    margin-top: 3px;
}

.favorite-buy {
    margin-top: 9px;
}

.delete-from-favorite {
    text-decoration: none;
    color: #666;
    font-size: 14px;
    line-height: 14px;
    background: #f3f3f3;
    padding: 4px 7px 4px 6px;
    border-radius: 4px;
    display: block;
    float: right;
}

.delete-from-favorite:hover {
    background: #e6e6e6;
}

.delete-from-favorite:hover span {
    text-decoration: underline;
}

.favorite-old-price {
    /* Старая цена */
    text-decoration: line-through;
    color: #666;
}

a#favorite {
    position: relative;
    text-decoration: none;
}

a#favorite:hover:after {
    content: attr(data-title);
    position: absolute;
    left: -150px;
    top: -70px;
    z-index: 2;
    background: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    font-weight: bold;
    padding: 5px;
    border: 1px dotted #d6341d;
    color: #d6341d;
    box-shadow: 0 0 3px #ccc;
    min-width: 165px;
    min-height: 50px;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    align-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
}

a.u_favorite:hover:after {
    content: attr(data-title);
    position: absolute;
    left: -150px;
    top: -70px;
    z-index: 2;
    background: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    font-weight: bold;
    padding: 5px;
    border: 1px dotted #d6341d;
    color: #d6341d;
    box-shadow: 0 0 3px #ccc;
    min-width: 165px;
    min-height: 50px;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    align-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
}

a#favorite:hover i {
    color: #d6341d;
}

#favorite i.grey-fa-icon {
    color: #333;
    font-size: 20px;
}

#favorite i.red-fa-icon {
    color: #d6341d;
    font-size: 20px;
}

.account-content .breadcrumb {
    background: none;
    padding: 0;
    font-size: 12px;
}


/* Список товаров списка Избранного - конец */

/* Список товаров списка Отслеживания - начало */

.subscription-list {
    padding: 0;
    display: table;
    clear: both;
    margin-bottom: 20px;
}

.subscription-item {
    list-style: none;
    display: block;
    float: left;
    margin: 0 0 10px 0;
    width: 100%;
    padding: 0 0 10px 0;
    border-bottom: 1px solid #eeeeee;
}

.subscription-list-badges {
    display: table;
    clear: both;
    margin-bottom: 8px;
    padding: 0;
}

ol.subscription-list-badges li {
    list-style: none;
    display: inline-block;
    margin-right: 4px;
}

.subscription-image {
    line-height: 100px;
    display: block;
    text-align: center;
    margin-bottom: 4px;
}

.subscription-left {
    width: 10%;
    display: block;
    float: left;
    margin-right: 10px;
}

.subscription-center {
    width: 65%;
    display: block;
    float: left;
}

.subscription-right {
    width: 20%;
    display: block;
    float: right;
    padding: 9px;
    background: #ebe5d7;
    border-radius: 4px;
}

.subscription-description {
    font-size: 12px;
}

.subscription-name-block {
    margin: 2px 0 5px 0;
    display: block;
}

.subscription-name {
    font-size: 16px;
    display: inline;
    line-height: 1.15;
}

.subscription-status {
    display: block;
    font-size: 12px;
    margin-bottom: 3px;
    color: #666;
}

.subscription-price {
    font-size: 20px;
    line-height: 20px;
    width: 132px;
    display: block;
    float: left;
    margin-top: 3px;
}

.subscription-buy {
    margin-top: 9px;
}

.delete-from-subscription {
    text-decoration: none;
    color: #666;
    font-size: 14px;
    line-height: 14px;
    background: #f3f3f3;
    padding: 4px 7px 4px 6px;
    border-radius: 4px;
    display: block;
    float: right;
}

.delete-from-subscription:hover {
    background: #e6e6e6;
}

.delete-from-subscription:hover span {
    text-decoration: underline;
}

.subscription-old-price {
    /* Старая цена */
    text-decoration: line-through;
    color: #666;
}

a#subscription {
    position: relative;
    text-decoration: none;
}

a#subscription:hover:after {
    content: attr(data-title);
    position: absolute;
    left: -150px;
    top: -70px;
    z-index: 2;
    background: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    font-weight: bold;
    padding: 5px;
    border: 1px dotted #d6341d;
    color: #d6341d;
    box-shadow: 0 0 3px #ccc;
    min-width: 165px;
    min-height: 50px;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    align-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
}

a.u_subscription:hover:after {
    content: attr(data-title);
    position: absolute;
    left: -150px;
    top: -70px;
    z-index: 2;
    background: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    font-weight: bold;
    padding: 5px;
    border: 1px dotted #d6341d;
    color: #d6341d;
    box-shadow: 0 0 3px #ccc;
    min-width: 165px;
    min-height: 50px;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    align-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
}

a#subscription:hover i {
    color: #d6341d;
}

#subscription i.grey-fa-icon {
    color: #333;
    font-size: 20px;
}

#subscription i.red-fa-icon {
    color: #d6341d;
    font-size: 20px;
}

.account-content .breadcrumb {
    background: none;
    padding: 0;
    font-size: 12px;
}


/* Список товаров списка Отслеживания - конец */

.breadcrumb {
    margin-bottom: 10px;
    margin-top: 0px;
}

.breadcrumb i {
    /* Иконка home */
    color: #BBB;
}

.breadcrumb i:hover {
    color: #777
}

.pagination-all {
    /* Кнопка показать все */
    float: right;
}


/* Блок загрузки при нажатии на кнопку Показать еще начало  */

.loading {
    display: block;
    height: 118px;
}

.loader {
    margin: 0 auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.5em solid rgba(0, 0, 0, 0.2);
    border-right: 0.5em solid rgba(0, 0, 0, 0.2);
    border-bottom: 0.5em solid rgba(0, 0, 0, 0.2);
    border-left: 0.5em solid #767676;
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 4em;
    height: 4em;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


/* Блок загрузки при нажатии на кнопку Показать еще конец */

.show-more {
    /* Кнопка показать еще */
    display: block;
    width: 100%;
    margin-bottom: 15px;
    padding: 8px 12px;
    line-height: 2.2;
    color: #333;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
}

.show-more:hover {
    color: #333;
    background-color: #f5f1e8;
    border-color: #ddd;
}

#sortview {
    display: table;
    clear: both;
    width: 100%;
    margin-bottom: 15px;
}


/* Сортировка - начало */

.pagination {
    margin-top: 0px
}

#sort {
    font-size: 13px;
    display: block !important;
    float: right;
    width: 700px;
}

#sort li.disabled {
    display: block;
    padding: 8px 15px;
    position: relative;
    color: #999;
}

#sort li a {
    font-size: 13px;
    color: #333;
}

#sort li a {
    padding: 7px 15px;
    text-decoration: none;
    border: 1px solid #d2d2d2;
    margin-left: -1px;
/*    border-top-left-radius: 0;
    border-bottom-left-radius: 0;*/
}
/*
#sort li:not(:last-child) a {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

#sort li.disabled + li a {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}*/

#sort li a.current {
    background: #ebe5d7;
}

#sort li a span {
    text-decoration: none;
}

#sort li a:hover {
    text-decoration: none
}

#sort li a:hover span {
    text-decoration: none
}

.nav-pills > li + li {
    margin: 0;
}


/* Сортировка - конец */


/* Отображение - списком или плиткой - начало */

#view {
    display: block;
    width: 200px;
    font-size: 13px;
    text-align: right;
    float: right;
    color: #999999;
}

.view-style {
    display: block;
    float: right;
    opacity: 0.8;
}

.view-style a {
    font-size: 18px;
    line-height: 1.24;
    padding: 5px 15px;
}

.view-style a:hover {
    background: #eeeeee;
}

.view-style a.active {
    box-shadow: none;
    background: #ebe5d7;
}

.view-style:hover {
    opacity: 1
}

.view-how {
    display: block;
    float: right;
    margin-right: 7px;
    line-height: 34px;
    display: block;
    float: right;
}

.btn-orange {
    background: url(/venmi/views/admin/default/img/buttons_banner.png) no-repeat;
    background-position-x: 0%;
    background-position-y: 0%;
    width: 123px;
    height: 36px;
    display: block;
    margin: 0 auto;
}

/* Отображение - списком или плиткой - конец */



button.btn-filter {
    /*display: flex;*/
    display: none;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    height: 30px;
    padding: 0 10px;
    margin: 0;
    border-radius: 0;
    border: 1px solid #ccc;
    color: #7a7875;
    background-color: #f8f5ee;
}

.btn-filter .icon {
    font-size: 18px;
    margin: 0 5px;
}

.btn-filter .icon:first-child {
    margin-left: 0;
}

.btn-filter .icon:last-child {
    margin-right: 0;
}


/* Страница товара (Карточка товара) - начало */

.product-cols {
    margin-top: 20px;
    margin-bottom: 30px;
}

.product-col-left {
    width: 37.5%;
}

.product-col-right {
    width: 25%;
    float: right;
}

.product-col-center {
    width: 37.5%;
}

.product-col-main {
    margin-top: 50px;
}

.price-with-amount-and-little-buttons {
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    justify-content: space-between;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    margin-bottom: 25px;
    margin-top: 25px;
	clear: left;
}

.price-with-amount-and-little-buttons:first-child {
    margin-top: 0;
}

.price-with-amount-and-little-buttons:last-child {
    margin-bottom: 0;
}

.product-header {
    display: table;
    width: 100%;
    clear: both;
}

.product-header h1 {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1;
    padding-bottom: 10px;
}

.product-list-badges {
    display: inline-block;
    vertical-align: top;
    padding-top: 1px;
    position: absolute;
    top: 0;
    left: 15px;
    z-index: 2;
}

.main-image {
    /* главная картинка товара */
    background-color: #FFFFFF;
    border-radius: 4px;
    line-height: 1.42857;
    margin-bottom: 8px;
    height: 355px;
    max-width: 318px;
    text-align: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.main-image > img {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}

a.main-image:hover,
a.main-image:focus,
a.main-image.active {
    border-color: #CCC
}

#more-images {
    padding: 0;
    margin-right: -10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

#more-images li {
    list-style: none;
    padding: 0;
}

#more-images li a {
    /* стиль ссылки доп. изображений */
    background-color: #FFFFFF;
    border: 1px solid #E6E6E6;
    margin: 0 4px 4px 0;
    padding: 2px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 70px;
    min-width: 70px;
}

#more-images li a > img {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
}

#more-images li a:hover,
#more-images li a:focus,
#more-images li a.active {
    border-color: #CCC
}

#buy {
    /* блок покупки */
    display: table;
    clear: both;
    width: 100%;
}

.buy-inner {
    display: block;
}

.buy-inner2 {
    margin-top: 25px;
}

@media (min-width:991px) and (max-width:1399px) {
    .buy-inner2 {
		margin-top: 55px;
	}
}​

.buy-inner2:first-child {
    margin-top: 0;
}

.product-id {
    display: table;
    clear: both;
    width: 100%;
    background: #f8f5ee;
    font-size: 13px;
    float: left;
    padding: 8px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 1px dotted #e4e4e4;
    margin-top: 0px;
}

.product-id span {
    font-weight: 700;
}

#sku span {
    font-weight: bold
}

.product-price-block {
    margin-bottom: 25px;
}

.buy-status {
    clear: both;
}

.buy-oldprice {
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
}

.buy-oldprice span {
}

.buy-oldprice-strike {
    text-decoration: line-through;
}

.buy-price {
    /* цена товара */
    font-size: 32px;
    display: inline-block;
    font-weight: 700;
    /*text-align: right;*/
    display: block;
}

.have-old-price {
    color: #ff001e;
    margin-right: 5px;
}

.buy-tradein-price {
	color: #ff001e;
    font-size: 32px;
    font-weight: 700;
}

.have-tradein-price {
	font-size: 28px;
	color: #333333;
}

.product-description-short {
    /* краткое описание товара в товаре */
    display: block;
    clear: both;
    width: 100%;
}

.product-properties {
    /* краткое описание товара в товаре */
    display: block;
    clear: both;
    width: 100%;
    font-size: 14px;
}

.product-properties .table > thead > tr > th,
.product-properties .table > tbody > tr > th,
.product-properties .table > tfoot > tr > th,
.product-properties .table > thead > tr > td,
.product-properties .table > tbody > tr > td,
.product-properties .table > tfoot > tr > td {
    border-top: 1px solid #e7e7e7;
    font-size: 12px;
    padding: 5px 6px;
}

.product-properties table tr:last-child {
    border-bottom: 1px solid #e7e7e7;
}

.product-properties2 {
    display: block;
    float: right;
    width: 225px;
    flex-shrink: 0;
}

.product-properties2 .table {
    width: 100%;
    margin-bottom: 0 !important;
}

.product-properties2 .table > thead > tr > th,
.product-properties2 .table > tbody > tr > th,
.product-properties2 .table > tfoot > tr > th,
.product-properties2 .table > thead > tr > td,
.product-properties2 .table > tbody > tr > td,
.product-properties2 .table > tfoot > tr > td {
    border-top: 1px solid #e7e7e7;
    font-size: 12px;
    padding: 5px 6px;
}

.product-properties2 table tr:last-child {
    border-bottom: 1px solid #e7e7e7;
}

.list-badges {
    display: table;
    clear: both;
    margin-bottom: 8px;
    padding: 0;
}

ol.list-badges li {
    list-style: none;
    display: inline-block;
    margin-right: 4px;
}

.buy-compare {
    font-size: 13px;
}

.buy-compare a {
    position: relative;
}

a.add_to_compare{
	width:20px;
	height:20px;
	color:#4d4d4d;
}

a.add_to_compare:hover{
	color:#d6341d;
}

a.remove_from_compare {
	width:20px;
	height:20px;
	color:#d6341d!important;
}

a.add_to_compare:hover::after {
    content: attr(data-title);
    position: absolute;
    right: -25px;
    top: -70px;
    z-index: 2;
    background: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    font-weight: bold;
    padding: 5px;
    border: 1px dotted #d6341d;
    color: #d6341d;
    box-shadow: 0 0 3px #ccc;
    min-width: 150px;
    min-height: 50px;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    align-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
}

a.remove_from_compare:hover::after {
    content: attr(data-title);
    position: absolute;
    left: -130px;
    top: -70px;
    z-index: 2;
    background: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    font-weight: bold;
    padding: 5px;
    border: 1px dotted #d6341d;
    color: #d6341d;
    box-shadow: 0 0 3px #ccc;
    min-width: 180px;
    min-height: 50px;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    align-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
}

.area-to-compare {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px dotted #d6341d;
    box-shadow: 0 0 3px #ccc;
    z-index: 2;
    min-width: 180px;
    min-height: 50px;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    align-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
}

.buy-compare i {
    font-size: 20px;
    color: #333;
}

.buy-compare i:hover {
    color: #d6341d;
}

.remove_from_compare i {
    font-size: 20px;
    color: #d6341d
}

.with-area-to-compare {
    position: relative;
}

.area-to-compare {
    position: absolute;
    z-index: 2;
    width: 150px;
    height: 100px;
}

.buy-compare a,
.list-reviews a {
    text-decoration: none;
    color: #4d4d4d;
}

.buy-list-variants {
    margin-top: 25px;
}

.buy-list-variants:first-child {
    margin-top: 0;
}

.buy-list-variants label {
}

.buy-list-variants .radio {
    display: table;
    clear: both;
    width: 100%;
    margin-bottom: 15px;
}

.buy-list-variants .to-order {
    font-style: italic
}

.buy-list-variants .out-of-stock {
    color: #999
}

.share {
    /* стиль кнопок соц.сетей */
    display: block;
    float: left;
    margin-top: 10px;
}

.tab-pane {
    padding: 25px 0 35px 0;
}

.products-tabs {
    display: table;
    clear: both;
    width: 100%;
    margin-top: 10px;
}

#no_stock {
    padding: 4px 8px;
    margin-top: 10px;
    margin-bottom: 0;
}

.product-modifier-group .checkbox,
.product-modifier-group .radio {
    padding-left: 15px;
}

.modifier-select {
    margin: 10px 0 !important;
    display: block;
}

.modifier-checkbox {
    margin-top: -9px;
    display: block;
}

.modifier-group-header {
    font-weight: bold;
    display: block;
    margin-bottom: -5px;
}

.product-modifier {
    /* Модификаторы цены */
    display: table;
    clear: both;
    width: 100%;
}

.product-modifier-img {
    display: inline-block;
}

.product-modifier .input-group {
    width: 75px;
}

.product-modifier .input-group input {
    text-align: right;
    padding: 3px 5px 2px;
    height: 25px;
    width: 28px;
    border: 1px solid #CCCCCC !important;
    border-radius: 4px !important;
}

.product-modifier .input-group .btn-sm {
    padding: 2px 6px 2px;
    color: #666;
    font-size: 14px;
    border: none;
    margin: 0 1px 0 1px !important;
    border-radius: 4px !important;
    border: none !important;
    box-shadow: none !important;
}

.product-modifier .input-group .btn-sm.disabled i {
    color: #666 !important;
    box-shadow: none;
}

.product-modifier .input-group .btn-sm.focus {
}

.product-modifier .input-group .btn-sm i {
    color: #999
}

.product-modifier .input-group .btn-sm:hover i {
    color: #333
}

.modifier-counter {
    display: inline-block;
}

.modifier-counter .input-group {
    margin-bottom: -8px;
}

.product-links {
    /* Линия в карточке товара где звезды, сравнение, отзывы и прочее */
    display: table;
    clear: both;
    width: 100%;
    margin-bottom: 25px;
    font-size: 13px;
    height: 26px !important;
}

.product-links a.dotted2 {
    text-decoration: none;
    margin-top: 2px;
    display: block;
    /*float: right;*/
}

.product-links a.dotted2 span {
    border-bottom: 1px dotted;
}

.product-links a.dotted2:hover span {
    border-bottom: none;
}

.product-links a {
    color: #666;
}

.product-links a i {
    font-size: 14px;
}

.product-links .buy-compare {
    display: block;
    /*float: right;*/
}

.product-stars {
    display: block;
    float: left;
}

.write-review-top {
    /*margin-left: 20px;*/
    display: inline-block;
    position: relative;
}

a.write-review-top:hover::after {
    content: attr(data-title);
    position: absolute;
    left: -165px;
    top: -70px;
    z-index: 2;
    background: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    font-weight: bold;
    padding: 5px;
    border: 1px dotted #d6341d;
    color: #d6341d;
    box-shadow: 0 0 3px #ccc;
    min-width: 150px;
    min-height: 50px;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    align-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
}

.write-review-top {
    font-size: 20px;
    color: #333;
    display: inline-block;
    vertical-align: middle;
    top: -2px;
}

.write-review-top:hover {
    color: #d6341d;
}

.price-with-amount {
    /* Блок покупки нескольких штук товар с подсчетом суммы */
    /* margin-top: 25px; */
    display: block;
    /* float: left; */
}

.kolvo {
    width: 50px;
    display: block;
    /* margin-bottom: 4px; */
}

.price-with-amount .input-group {
    /* width: 85px; */
}

.price-with-amount .input-group input {
    text-align: right;
    width: 40px;
    height: 20px;
    border: 1px solid #CCCCCC !important;
    margin: 0 8px;
    box-shadow: none;
    /* font-size: 12px; */
    /* border-radius: 4px !important; */
}

.price-with-amount .input-group .btn-sm {
    color: #666;
    font-size: 14px;
    border: none;
    margin: 0 1px 0 1px !important;
    /* border-radius: 4px !important; */
    border: none !important;
    box-shadow: none !important;
}

.price-with-amount .input-group .btn-sm.disabled i {
    color: #666 !important;
    box-shadow: none;
}

.price-with-amount .input-group .btn-sm {
    background: none;
    padding: 0px;

}

.price-with-amount .input-group .btn-sm.focus {
}

.price-with-amount .input-group .btn-sm i {
    color: #333;
}

.price-with-amount .input-group .btn-sm:hover i {
    color: #000;
}

.buy-price-with-amount {
    display: none;
}

.product-blocks {
    /* Баннеры справа от блока купить */
    font-size: 13px;
    padding: 8px 10px;
    /*border-top: 1px solid #e3e4e4;*/
    background: #fafafa;
}

.product-blocks-item-heading {
    font-size: 13px;
    font-weight: 500;
}

.product-blocks-item {
    padding: 3px 0;
}

.product-blocks-name {
    display: inline-block;
}

.product-blocks-value {
    display: block;
    float: right;
}

.product-blocks p {
    font-size: 13px;
    margin-bottom: 0;
}

.product-description-top {
    display: block;
}

.product-description-header {
    display: block;
    font-size: 24px;
    margin: 10px 0;
}

.little-icons {
    margin-left: 6px;
}

.little-icons:first-child {
    margin-left: 0;
}

.little-icons a {
    display: inline-block;
}

.favorite-like-comment-compare {
    align-self: flex-end;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: flex-end;
    align-items: flex-end;
    -webkit-box-align: flex-end;
    -webkit-align-items: flex-end;
    -ms-flex-align: flex-end;
    display: -webkit-flex;
    display: -webkit-box;
    display: -moz-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
}

.favorite-like-comment-compare .little-icons a {
    /*line-height: 30px;
	width: 30px;*/
	line-height:16px;
}

.favorite-like-comment-compare .little-icons i {
    display: inline-block;
}

.favorite-like-comment-compare .little-icons .write-review-top i {
    margin-top: -2px;
}

.share-product {
    font-size: 20px;
    color: #333;
}

#share-product {
    padding-top: 50px;
    display: block;
}

.share-product:hover i {
    color: #d6341d;
}

a.share-product {
    position: relative;
}

a.share-product:hover::after {
    content: attr(data-title);
    position: absolute;
    left: -150px;
    top: -70px;
    z-index: 2;
    background: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    font-weight: bold;
    padding: 5px;
    border: 1px dotted #d6341d;
    color: #d6341d;
    box-shadow: 0 0 3px #ccc;
    min-width: 180px;
    min-height: 50px;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    align-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
}

.discount-button {
    height: 50px;
    border: 1px dotted #333;
    position: relative;
    margin-bottom: 20px;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    align-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
}

.discount-button:hover {
    cursor: pointer;
    border: 1px dotted #d6341d;
}

.discount-button a {
    text-decoration: none;
    font-size: 16px;
    color: #d6341d;
}

.discount-button a:hover {
    text-decoration: none;
    color: #d6341d;
}

.discount-button:hover a {
    border-bottom: 2px solid #d6341d;
    line-height: 14px;
}

.discount-message {
    display: none;
    background: #fff;
    border: 1px solid #ccc;
}

.discount-button:hover .discount-message {
    position: absolute;
    top: 48px;
    padding: 10px;
    display: block;
    z-index: 2;
    width: calc(100% + 2px);
    /* text-align: center; */
    /* display: flex;
    display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
    justify-content: center;
    -webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
    align-items: center;
    -webkit-box-align: center;
	-webkit-align-items: center;
    -ms-flex-align: center;
    flex-direction: column;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column; */
}

.discount-button .discount-message:hover {
    cursor: default;
    display: block;
}

.discount-button .discount-title {
    color: #d6341d;
    font-weight: bold;
    text-align: center;
}

.discount-description {
    text-align: center;
    margin-top: 10px;
}

.discrount-contacts {
    text-align: center;
    margin-top: 10px;
    font-weight: bold;
}

.discount-call-to {
    margin: 10px auto;
    /* margin-top: 10px;
    margin-bottom: 10px; */
    padding: 5px;
    height: 30px;
    width: 150px;
    background: linear-gradient(to bottom, #ffe06c, #ffd00e);
    text-align: center;
    border-radius: 3px;
}

.discount-call-to a {
    text-decoration: none;
    border-bottom: none !important;
    color: initial !important;
}

.buttons-on-product-card {
    margin-top: 10px;
}

.buttons-on-product-card:first-child {
    margin-top: 10px;
}

.buttons-on-product-card .btn {
    border-radius: 0;
}

.the-big-fast-open {
    height: 45px;
    padding: 12px !important;
    margin-bottom: 0px !important;
    font-size: 16px !important;
    text-transform: uppercase;
    background-color: #333 !important;
    color: #fff !important;
    border: none !important;
}

.the-big-fast-open:hover {
    background-color: #000 !important;
}

.buy-price {
}

.buy-price span {
    font-size: 20px;
}

.buy-buy {
    /* кнопка покупки */
    text-decoration: none;
    /* margin-bottom: 10px; */
    width: 100%;
    height: 45px;
    font-size: 16px !important;
    color: #fff;
    text-transform: uppercase;
    padding: 12px !important;
    background-color: #d6341d !important;
    color: #fff !important;
    border: none !important;
}

.buy-buy:hover {
    background-color: #ea7352 !important;
}

.buy-credit {
    /* кнопка покупки */
    text-decoration: none;
    width: 100%;
    height: 45px;
    font-size: 16px !important;
    color: #fff;
    text-transform: uppercase;
    padding: 12px !important;
    background-color: #fff !important;
    border: 1px solid #333 !important;
    color: #333 !important;
}

.buy-credit:hover {
    background-color: #ccc !important;
    border: 1px solid #333 !important;
}



.buy1click-credit-user-info .form-control {
    border-radius: 0;
}

.buy1click-credit-user-info .btn {
    border-radius: 0;
}


.buy1 {
    /* Заказ в 1 клик */
    text-decoration: none;
    width: 100%;
    background: #e3e4e4;
    font-size: 18px;
    color: #1c1c1c;
    text-transform: uppercase;
    padding: 10px;
    border: 3px solid #e3e4e4;
}

.buy1:hover,
.buy1:focus {
    color: #333;
    border: 3px solid #d7d7d7;
    background: #ffffff;
}


/* Страница товара - конец */


/* Стили товаров спарсенных с Яндекс-маркета - начало */


/* Стиль краткого описания маркета */

.product-spec__body {
    margin-bottom: 30px;
}

.product-spec-item {
    position: relative;
    margin: 0;
    background: #fff;
}

.product-spec__body .title {
    margin-top: -4px !important;
    margin-bottom: 12px !important;
}

.title_size_22 {
    font-size: 22px !important;
}

.title {
    line-height: 1.4em !important;
    font-weight: 500;
}

.product-spec__body .product-spec-item__name {
    font-size: 15px;
    line-height: 17px;
    width: 49%;
    font-weight: 500;
}

.product-spec-item__name {
    display: inline-block;
    width: 48%;
    margin: 0 0 7px;
    vertical-align: top;
    background: inherit;
}

.product-spec-item__name-inner {
    position: relative;
    padding: 0 10px 0 0;
    background: inherit;
    z-index: 2;
}

.product-spec-item__name:after {
    position: absolute;
    right: 5px;
    left: 0;
    height: 1px;
    margin: .85em 0 0;
    content: '';
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAABCAAAAAA+i0toAAAAAnRSTlMA/1uRIrUAAAAMSURBVHheY7j1/z8ABY8C2UtBe8oAAAAASUVORK5CYII=') 0 0 repeat-x;
}

.product-spec__body .product-spec-item__value {
    font-size: 15px;
    font-weight: normal;
    line-height: 18px;
    width: 51%;
}

.product-spec-item__value {
    display: inline-block;
    width: 52%;
    margin: 0 0 7px;
    vertical-align: bottom;
    background: inherit;
}

.product-spec-item__value-inner {
    position: relative;
    display: block;
    padding: 0 0 0 10px;
    background: inherit;
    z-index: 2;
}


/* Описание стиля в списке товаров плиткой */

.snippet-cell__desc-list {
    position: relative;
    margin: 0 0 0 0 !important;
    word-wrap: break-word;
    word-break: break-word;
    padding: 0;
    line-height: 1.6em;
}

.snippet-cell__desc-item:first-child {
    margin-left: 0 !important;
}

.snippet-cell__desc-item:first-child:first-letter {
    text-transform: uppercase
}

.snippet-cell__desc-item:first-child:before {
    display: none;
}

.snippet-cell__desc-item {
    position: relative;
    margin: 0 0 0 12px !important;
    word-wrap: break-word;
    word-break: break-word;
    list-style: none;
    font-size: 12.8px;
    line-height: 20.48px;
}

.snippet-cell__desc-item:before {
    content: '•';
    position: absolute;
    left: -12px !important;
    top: 2px;
    color: #b2b2b2;
    font-family: Arial;
    font-size: 12.8px;
    line-height: 20.48px;
}

.snippet-card__desc-list {
    position: relative;
    margin: 0 0 0 0 !important;
    word-wrap: break-word;
    word-break: break-word;
    padding: 0;
    line-height: 1.6em;
}

.snippet-card__desc-item:first-child {
    margin-left: 0 !important;
}

.snippet-card__desc-item:first-child:first-letter {
    text-transform: uppercase
}

.snippet-card__desc-item:first-child:before {
    display: none;
}

.snippet-card__desc-item {
    position: relative;
    margin: 0 0 0 12px !important;
    word-wrap: break-word;
    word-break: break-word;
    list-style: none;
    font-size: 12.8px;
    line-height: 20.48px;
}

.snippet-card__desc-item:before {
    content: '•';
    position: absolute;
    left: -12px !important;
    top: 2px;
    color: #b2b2b2;
    font-family: Arial;
    font-size: 12.8px;
    line-height: 20.48px;
}


/* описание верстки внутри карточки товара второго краткого описания */

.product-card__spec-item {
    position: relative;
    margin: 0 0 5px
}

.product-card__spec-item:before {
    content: '';
    position: absolute;
    left: -12px;
    top: 7px;
    width: 4px;
    height: 4px;
    background: #b2b2b2;
    border-radius: 50%;
    font-family: Arial, Helvetica, sans-serif;
}

.product-card__spec-list {
    line-height: 1.4em;
    margin: 0 0 15px;
    list-style: none;
    padding: 0 0 0 15px;
}


/* Стили товаров спарсенных с Яндекс-маркета - конец */


/* Все всплывающие окна - начало */

#contact-center .modal-footer .btn {
    /* Описание блока  Заказ в 1 клик */
    width: 100%;
}

#contact-center .modal-body {
    padding-bottom: 0
}

.modal-body .img_part_big {
    min-height: 1px !important;
}

#contact-center .help-block {
    font-size: 12px
}

.help-block.red {
    font-size: 12px;
    color: #DD0003;
}

#buy1click .modal-footer .btn {
    /* Описание блока  Заказ в 1 клик */
    width: 100%;
}

#buy1click .modal-body {
    padding: 0
}

#buy1click .help-block {
    font-size: 12px
}

.modal-complain {
    width: 350px
}

#complain-form .modal-body {
    padding: 0 20px
}

.modal-complain .alert {
    margin-top: 20px
}

#buy1click .modal-dialog {
    width: 700px;
}

#buy1click .cart-body {
    padding: 5px 15px 0 15px;
}

.buy1click-user-info {
    background: #f8f5ee;
    padding: 15px 15px 0 15px;
    border-top: 1px solid #e5e5e5;
    display: table;
    clear: both;
    width: 100%;
}

.buy1click-user-info label {
    font-weight: 500;
}

.buy1click-user-info .col-xs-6 {
    padding-left: 20px;
}

.buy1click-phone {
    font-size: 18px;
    width: 100%;
    max-width: 235px;
}

#buy1click textarea {
    width: 100%;
    max-width: 235px;
}

#buy1click .help-block {
    margin-top: 0;
}

.buy1click-phone-block {
    text-align: right;
    line-height: 34px;
}


/* Окно "Товар добавлен в корзину" начало */

#after-buy-form .modal-dialog {
    width: 600px;
}

#after-buy-form .modal-header {
    font-weight: 500;
    font-size: 18px;
}

#after-buy-form .btn {
    font-size: 15px;
    padding: 10px 12px !important;
}

.after-buy-prod-name {
    font-size: 16px;
    font-weight: bold;
}

.after-buy-image {
    text-align: center;
}

.after-buy-check {
    font-size: 14px;
    text-align: center;
    border: 1px solid #dff0d8;
    margin: 15px 0;
    padding: 7px 0;
    border-radius: 4px;
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

.after-buy-check i {
    color: #4cae4c;
    font-size: 16px;
    margin-right: 4px;
}


/* Окно "Товар добавлен в корзину" конец */


/* Все всплывающие окна - конец */


/* Слайдшоу начало */

.carousel {
    margin-bottom: 20px
}

.carousel-control i {
    display: inline-block;
    position: absolute;
    top: 45%;
    z-index: 5;
}

.carousel-indicators {
    bottom: 10px;
    margin-bottom: 0px;
    opacity: 0.7;
}

.carousel-indicators li {
    border: 1px solid #CCC;
    width: 17px;
    height: 17px;
}

.carousel-indicators .active {
    background: #008ccd;
    border: none;
    width: 18px;
    height: 18px;
}

.carousel-control.left {
    width: 75px;
    text-align: left;
}

.carousel-control.right {
    width: 75px;
    text-align: left;
}

.carousel-control.left i {
    margin-left: 30px
}

.carousel-control.right i {
    margin-left: 30px
}

.carousel-control.left {
    background: none
}

.carousel-control.right {
    background: none
}


/* Слайдшоу конец */


/* Сравнение товаров начало */

.compare-head {
    margin-bottom: 10px
}

.compare-params a {
    border-radius: 4px !important;
    text-decoration: none;
}

.compare-params a span {
    border-bottom: 1px dotted
}

.compare-params a:hover {
    background: #EFEFEF;
    text-decoration: none;
}

.compare-params a.active {
    box-shadow: none;
    background: #EFEFEF;
}

.compare-params a.active span {
    border: none
}

.compare-params h3 {
    display: block;
    vertical-align: top;
}

.compare-head tr th {
    vertical-align: bottom
}

.compare-product {
    font-weight: normal;
    vertical-align: top !important;
}

.compare-product-image {
    display: block;
    height: 120px;
    width: 120px;
    margin-bottom: 5px;
    clear: both;
}

.compare-product a {
    display: block
}

.compare-delete {
    /* ссылка удалить */
    text-decoration: none;
    margin-top: 5px;
    line-height: 1.25;
    display: block !important;
    position: relative;
    bottom: 0px;
    left: 0px;
    background: #FFF;
    vertical-align: bottom;
}

.compare-delete span {
    border-bottom: 1px dotted
}

.compare-delete:hover span {
    border-bottom: none
}

.compare-delete .fa {
    color: #999
}

.compare-body tr td {
    border-top: 0 !important;
    border-bottom: 1px solid #DDDDDD;
}

.compare-fistblock,
.compare-property-name {
    /* ширина первой колонки в сравнении - там где перечисление свойств */
    width: 300px;
}

.compare-property-value,
.compare-product {
    width: 220px
}

.compare-property-name .fa {
    color: #666
}


/* Отображение статуса заказа в сравнении товаров */

.compare-property-value .product-status {
    font-size: 14px;
    display: inline-block;
}

.compare-property-value hr {
    margin: 3px 0
}


/* Сравнение товаров конец */


/* Отображение материалов начало */

.material-date {
    font-size: 12px;
    color: #666;
}

.material-separator {
    /* Разделитель, ставится после каждого материала */
    border-bottom: 1px dashed #E3E3E3;
    padding-bottom: 9px;
    margin: 0 0 10px;
    display: block;
    clear: both;
}

.category-materials {
    color: #333
}

.material-readmore {
    text-decoration: none
}

.material-full table {
    width: 100%!important;
}


/* Отображение материлов конец */


/* Форма регистрации начало */

.input-ok {
    color: #47a447;
    font-size: 22px;
    position: absolute;
    right: -15px;
    margin-top: 0px;
}

.input-ok.phone-ok {
    left: 193px
}


/* Форма регистрации конец */


/* Поиск начало */

#addRelatedModal .modal-dialog {
    width: 930px
}

#addRelatedModal .modal-body {
    padding: 10px 20px
}

.addcontainer {
    /* Блок вывода результатов поиска */
    background: #FFF;
    border: 1px solid #CCCCCC;
    width: 620px;
    overflow: auto;
    margin: 0;
    position: absolute;
    z-index: 100;
    border-radius: 4px;
    margin-left: -88px;
}

.search-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    border-bottom: 1px solid #d4d4d5;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.search-block:last-child {
    border-bottom: none;
}

.search-block-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    text-align: right;
    font-size: 12px;
    color: #8c8c8c;
    border-right: 1px solid #e5e5e5;
    -webkit-flex-basis: 100px;
    -ms-flex-preferred-size: 100px;
    flex-basis: 100px;
    background: #ebe5d7;
    padding: 8px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.search-block-results {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
}

.search-block-results .search-block-item:last-child {
    border-bottom: none;
}

.search-block-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 7px;
    border-bottom: 1px solid #efefef;
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}

.search-block-item:hover,
.search-block-item.active {
    background: #f6f7f7;
    -webkit-transition: background 0.1s ease;
    transition: background 0.1s ease;
    cursor: pointer;
}

.addlist {
    padding: 0;
    margin: 0;
    list-style: none;
}

ol.addlist li {
    /* найденный элемент поиска */
    background: #FFF;
    padding: 3px;
    cursor: pointer;
    border-bottom: 1px solid #ededed;
    min-height: 36px;
}

ol.addlist li:last-child {
    border-bottom: none
}

ol.addlist li.selected {
    background: #f5f5f5
}

.apimage {
    -webkit-flex-basis: 30px;
    -ms-flex-preferred-size: 30px;
    flex-basis: 30px;
    height: 30px;
    margin: 0 10px 0 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    float: left;
    text-align: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
}

.apimage img {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
}

.apname {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
}

.app-productname {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    line-height: 1.2;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}

.app-productname span {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.search-block-centered {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
}

.search-block-brands {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    text-align: left;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
}

.all-results {
    padding: 0 4px;
    color: #333;
}

.sky-id {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 4px;
}

.apsky {
    font-size: 11px;
    opacity: 0.7;
    margin-right: 10px;
    max-width: 50%;
    box-sizing: border-box;
    display: inline-block;
}

.apid {
    font-size: 11px;
    max-width: 50%;
    opacity: 0.7;
    box-sizing: border-box;
    display: inline-block;
}

.apprice {
    -webkit-flex-basis: 90px;
    -ms-flex-preferred-size: 90px;
    flex-basis: 90px;
    margin: 0 3px 0 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    text-align: right;
    font-size: 12px;
    opacity: 0.5;
    float: right;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.apprice span {
    display: inline;
}

.app-productname-left {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.searchclear {
    position: absolute;
    display: block;
    left: 254px;
    background: none;
    border: none;
    color: #999;
    padding: 0;
    top: 35px;
    z-index: 1000;
}

.searchclear:hover {
    color: #444
}

#search-ajax-loader {
    position: absolute;
    display: block;
    right: 98px;
    background: none;
    border: none;
    color: #999;
    padding: 0;
    top: 12px;
    z-index: 1000;
}

.noresults i {
    /* показываем когда ничего не найдено - там спиннер крутится загрузки */
    margin: 7px 7px 7px 100px;
}

.no-results {
    padding: 7px;
}

#searchcategories {
    /* рамка блока категорий поиска  */
    padding: 4px 9px 9px 9px;
    border: 1px solid #E6E6E6;
    /*border-radius: 4px;*/
    margin-bottom: 20px;
	/*background: #f8f5ee;*/
}

#searchcategories ol {
    list-style: none;
    padding: 0;
    margin: 0;
}

#searchcategories ol li {
    padding-left: 0;
    padding-right: 15px;
}

#searchcategories ol li:hover {
	background: #ebe5d7;
}

#searchcategories ol li a {
    /* стиль ссылки категории поиска */
    line-height: 1.45;
	font-size: 14px;
	color: rgb(51, 51, 51);
}

.brand-group {
    display: block;
    margin-bottom: 5px;
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 5px;
}

#searchcategories ol li span {
    /* показываем количество товаров в категории */
    display: block;
	float: right;
    color: #C3C3C3;
    font-size: 12px;
    /*position: absolute;
    right: 24px;*/
    padding: 2px 0 0 0;
    line-height: 20px;
}

/*.searchcats {
    font-weight: bold
}*/

.object-header {
    height: 18px !important;
    min-height: 15px !important;
    line-height: 14px;
    padding: 3px 3px 6px 3px;
    display: block;
    width: 100%;
    font-size: 11px;
    border-bottom: none !important;
}

.object-header span {
    padding: 0
}

.category-line {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    /*margin: 0 -6px;*/
}

.found-categories {
    padding: 0;
    display: table;
    width: 100%;
    clear: both;
    margin: 0;
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 1px dashed #ededed;
}

.found-categories__list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    /*margin: 0 -6px;*/
}

.found-categories li {
    list-style: none;
}

.found-categories-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    flex: auto 0 0;
    padding: 2px 8px 14px;
    width: 25%;
    text-align: center;
    text-decoration: none;
}

a.found-categories-item:hover {
    border-color: #CCCCCC
}

.found-categories-name {
    /*font-size: 13px;*/
	font-size: 14px;
    margin: 10px 0 3px;
    font-weight: 500;
    text-decoration: none;
    color: #333;
    line-height: 1.2;
}

.found-categories-image {
    height: 100px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 6px;
}

.found-categories-image img {
    max-height: 100%;
    max-width: 100%;
}


/* Поиск конец */


/* Популярные категории на главной странице начало hidden-xs версия */

.popular-categories {
    padding: 0;
    display: table;
    width: 100%;
    clear: both;
    margin: 0;
}

.popular-categories a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 16.6%; /*153px;*/
    max-width: 16.6%; /*153px;*/
    /*margin: 0 6px 12px;*/
    padding: 2px;
    clear: both;
    /*border: 1px solid #ededed;*/
    text-align: center;
    text-decoration: none;
}

.popular-categories a:hover {
    border-color: #CCCCCC
}


.popular-categories-name {
    /*font-size: 14px;*/
	font-size: 15px;
    margin: 20px 0 4px;
    font-weight: 500;
    text-decoration: none;
    color: #333;
    line-height: 1.2;
}

.popular-categories-image {
    height: 115px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 10px;
}

.popular-categories-image img {
}

/* Популярные категории на главной странице конец */


/* Админская линия сверху начало */


/* Самое верхнее меню начало */

.mininavbar {
    min-height: 30px;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 0;
    border-radius: 0;
    background: #fff;
    border: none;
}

.minicontainer {
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 -15px;
}

.mininavbar ul.navbar-nav li:first-child a {
    padding-left: 0
}

.mininavbar ul.pull-right li:last-child a {
    padding-right: 0
}

a.logout span {
    text-decoration: underline
}

a.logout:hover span {
    text-decoration: none
}

.mininavbar ul.nav li a {
    white-space: nowrap;
    padding: 5px 20px;
    color: #333333;
    text-decoration: none;
}

a.to-frontend span {
    text-decoration: underline
}

.mininavbar .badge {
    opacity: 0.4
}

.otkliki {
    margin: 7px 0;
}

.otkliki li {
}

.otkliki li a span {
    text-decoration: underline
}

.otkliki li a {
    padding: 7px 8px 9px !important;
    margin-right: 7px;
    border-radius: 4px;
}

.otkliki li:last-child a {
    margin-right: 0;
}

.otkliki li a span.badge {
    text-decoration: none;
    font-weight: normal;
    background: #428bca;
    padding: 2px 5px;
    opacity: 0.7;
    line-height: 11px;
    font-size: 11px;
}

.orderspage span.badge {
    background: #d9534f !important;
    opacity: 1 !important;
}

.otkliki li a.orderspage {
    background-image: url(/venmi/views/admin/default/img/icons/topmenu/orders.png);
    background-position: 8px 9px;
    background-repeat: no-repeat;
    padding-left: 30px !important;
}

.otkliki li a.top-message {
    background-image: url(/venmi/views/admin/default/img/icons/topmenu/message.png);
    background-position: 8px 13px;
    background-repeat: no-repeat;
    padding-left: 30px !important;
}

.otkliki li a.faq {
    background-image: url(/venmi/views/admin/default/img/icons/topmenu/comment.png);
    background-position: 8px 13px;
    background-repeat: no-repeat;
    padding-left: 30px !important;
}

.otkliki li a:hover,
.otkliki li.active a {
    background-color: #f8f5ee !important
}

.otkliki li a:hover span {
}

.adminmenu {
    margin: 7px 0;
}

.adminmenu li a {
    padding: 7px 8px 9px 30px !important;
    margin-right: 7px;
    border-radius: 4px;
}

.adminmenu li:last-child a {
    margin-right: 0;
}

.adminmenu li a:hover,
.otkliki li.active a {
    background-color: #f8f5ee !important
}

.adminmenu li a.usermenu {
    background-image: url(/venmi/views/admin/default/img/icons/topmenu/boss.png);
    background-position: 8px 9px;
    background-repeat: no-repeat;
}

.to-backend {
    background-color: #fafafa;
    background-image: url(/venmi/views/admin/default/img/favicon.ico);
    background-position: 8px 9px;
    background-repeat: no-repeat;
}

.to-backend span {
    text-decoration: underline;
}

.adminpanel {
    border-bottom: 1px solid #e0e0e0;
    background: #FFF;
}

.adminpanel .navbar {
    margin-bottom: 0;
}

.adminpanel .container {
    padding: 0;
}


/* Самое верхнее меню конец */


/* Админская линия сверху конец */


/* Личный кабинет начало */

.accountmenu a {
    text-decoration: none;
    padding-left: 8px;
}

.accountmenu a i {
    opacity: 0.8;
    display: inline-block;
    width: 20px;
    text-align: center;
}

.accountmenu a span {
    text-decoration: none
}

.accountmenu a:hover span {
    text-decoration: none
}

.order-details {
    display: block;
    margin-top: 10px;
}

.order-details-list p:last-child {
    margin-bottom: 0
}

.orderlist {
    padding: 0
}

.orderlist li {
    list-style: none;
}

.orderlist li.panel {
    margin-bottom: 30px;
}

.orderlist .list-group-item {
    border: 1px solid #f5f5f5;
}

.orderlist .ordertable td,
.orderlist .ordertable th {
    border-color: #f5f5f5;
}

.order-number {
    font-size: 18px;
}

.orders-status span {
    font-size: 14px;
    font-weight: normal !important;
}

.orderlist li:first-child {
    margin-top: -5px;
}

.order-head {
    border-left: 3px solid #9ecc92;
    padding: 0 0 0 6px;
}

.notify-block {
    display: table;
    width: 100%;
    clear: both;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px dashed #efefef;
}

.notify-block .mini-onoff {
    float: right
}

.orders-repeat {
    text-align: right;
    display: block;
    margin: -2px -8px -3px 0;
}

.orders-repeat .btn i {
    font-size: 14px;
}

.orders-repeat .btn {
}

.orders-repeat btn {
}

.orders-number {
    font-size: 18px
}

.notorders {
    color: #D3D3D3 !important
}

.orderlist-date {
    text-align: right;
    display: block;
    float: right;
}

.current-orders .orders-status {
    padding: 0;
}

.current-discount {
    /* Текущая скидка пользователя */
    display: block;
    border-radius: 4px;
    background: #f5f5f5;
    padding: 15px;
    font-size: 15px;
}

.current-discount span {
    font-weight: bold;
}

.review-controls {
    /* Удалине и статус отзыва в личном кабинете */
    display: table;
    width: 100%;
    clear: both;
    padding-top: 5px;
}

.delete-review {
    display: block;
    float: left;
    width: 180px;
    color: #828282;
    text-decoration: none;
}

.delete-review:hover {
    color: #9C0002;
}

.delete-review span {
    text-decoration: underline;
}

.review-status {
    display: block;
    float: right;
    width: 525px;
}

.review-moderate {
    color: #828282;
}

.review-published {
    color: #1f8321
}

.account-content h1 {
    font-size: 28px !important;
    margin-bottom: 18px !important;
    line-height: 40px !important;
}


/* Личный кабинет конец */


/* Фильтры начало */

#filter {
    display: table;
    width: 100%;
    box-shadow: none;
    border: none !important;
    /*border: 1px solid #eee;*/
}

#filter .panel-heading,
#filter .panel-footer {
    padding: 7px 9px
}

#filter .panel-body {
    padding: 0;
}

#clear-filter {
    /* стиль кнопки сброса фильтра */
    background: #FFF;
    color: #333;
    border: 3px solid #e3e4e4;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
}

#clear-filter:hover {
    background: #e3e4e4;
}

#clear-filter span {
}

#clear-filter:hover span {
    border-bottom: none
}

#filter label.labelcollapse {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 0;
    width: 100%;
    padding: 10px 12px;
    cursor: pointer;
}

#filter .logicbox label {

}

#filter .logicbox {
    border-bottom: 1px solid #eeeeee;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 0;
    width: 100%;
    padding: 16px 12px 10px 12px;
    cursor: pointer;
}

#filter .logicbox .tag-name {
    float: left;
    max-width: 155px;
    margin-left: 7px;
}

#filter .select-filter {
}

#filter .select-filter label {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 0;
    width: 100%;
    padding: 10px 12px;
    cursor: pointer;
}

.filterbox__inner .checkbox label,
.filterbox__inner .radio label {
    font-weight: normal;
    font-size: 13px;
}

.filterbox__inner .checkbox label input,
.filterbox__inner .radio label input {
    margin-top: 2px;
}

.filterbox__inner .checkbox,
.filterbox__inner .radio {
    margin: 0px 0 4px;
    min-height: 10px;
}

#filter .form-group {
    /*border-bottom: 1px solid #eeeeee;*/
    margin-bottom: 0;
    -webkit-transition: background 0.16s;
    transition: background 0.16s;
}

#filter .form-group:hover {
    background: #f7f7f7;
}

.navbar-offcanvas #filter .form-group:hover {
    background: transparent;
}

#filter .form-group:first-child {
    margin-top: 0;
}

#filter .form-group:last-child {
    border-bottom: none;
    margin-bottom: 0;
}

.filterbox__inner {
    padding-right: 10px;
    /*padding: 2px 15px 15px;*/
}

.filter-disabled .filterbox__inner {
    padding: 0;
}

.filterbox__inner .items-collapse {
    max-height: 340px;
    overflow-y: auto;
}

.filter-disabled {
    opacity: 0.7;
}

#filter .property-help {
    text-decoration: none;
    color: #ABABAB;
    font-size: 13px;
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
    position: relative;
    display: block;
    float: right;
    margin-top: 0px;
	z-index: 20;
}

.collection-group {
    border: none !important;
    margin-top: 8px;
}

.collection-group-name {
    font-weight: 500;
    margin-left: 0;
    text-indent: 0 !important;
}

.logicbox {
    margin-bottom: 8px !important
}

.property-help {
    text-decoration: none;
    color: #ABABAB;
    font-size: 13px;
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
}

.property-help:hover {
    color: #c52411;
    cursor: pointer;
}

.tag-name {
    display: block;
    margin-left: 22px;
}

.unavailable {
    /* в select помечаем неактивный элемент */
    color: #AAA !important;
}

.popover-content {
    /* стиль всплывающих подсказок у свойств */
    font-size: 13px;
    padding: 9px 14px;
}

.popover-content p {
    line-height: 1.45
}

.popover-content p:last-child {
    margin-bottom: 0
}

#filter i.fa-caret-right,
#filter i.fa-caret-down {
    color: #888888;
    cursor: pointer !important;
    display: block;
    width: 11px;
    float: left;
    font-size: 13px;
    margin: 12px 0 0 12px;
    position: absolute;
}

.labelcollapse span {
    cursor: pointer !important;
    width: 100%;
    margin-left: 14px;
}

#clear-filter:active {
    box-shadow: none
}

.items-collapse {
    padding-left: 11px
}

.selected-tags {
    /* Блок выбранных тегов */
    display: table;
    clear: both;
    width: 100%;
    margin-bottom: 7px;
}

.cat-counter {
    padding: 7px 15px 7px 0;
    display: block;
    float: left;
}

.tag {
    /* Выбранный тег */
    background: #efefef;
    border-radius: 4px;
    padding: 0px 6px 2px;
    line-height: 1.45;
    margin: 0 4px 6px 0;
    font-size: 13px;
    color: #444;
    display: inline-block;
}

.tag a {
    /* Крестик удаления тега */
    font-size: 14px;
    color: #999;
    margin-top: 2px;
    display: inline-block;
}

.tag a:hover {
    color: #555
}

.expand-more-tags2 {
    /* Кнопка "Еще..." */
    display: inline-block;
    margin: 4px 0 0 11px;
    font-size: 12px;
    padding: 3px 8px;
    color: #333;
    background: #ebebeb;
    text-decoration: none;
    opacity: 0.8;
}

.expand-more-tags2:hover {
    cursor: pointer;
    color: #333;
    background: #dcdcdc;
    opacity: 1;
}

.filter-counter {
    position: relative;
    margin-bottom: -30px;
    top: 62px;
    right: -214px;
    background-color: #FFF;
    border: 1px solid #dddddd;
    height: 30px;
    border-radius: 4px;
    display: table;
    padding: 3px 6px 4px 6px;
    z-index: 100;
}

.filter-arrow {
    border: none;
    display: block;
    position: relative;
    background: url("../img/filter/arrow.png") no-repeat;
    width: 8px;
    height: 13px;
    top: 2px;
    left: -13px;
    margin-top: -17px;
}

.irs {
    position: relative;
    display: block;
    top: 7px;
    margin-bottom: 7px;
    height: 37px;
    width: 150px;
    margin-left: 10px;
}

.irs-line {
    position: relative;
    display: block;
    overflow: hidden;
    height: 6px;
    top: 10px;
    border: 1px solid #737373;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    /*Firefox 1-3*/
    -webkit-box-sizing: border-box;
    /* Safari */
    background-image: none;
    background: #737373 !important;
}

.irs-line-left,
.irs-line-mid,
.irs-line-right {
    position: absolute;
    display: block;
    top: 0;
}

.irs-line-left {
    left: 0;
    width: 10%;
    height: 12px;
    background-position: 0 -30px;
}

.irs-line-mid {
    left: 10%;
    width: 80%;
    height: 12px;
    background-position: 0 0;
}

.irs-line-right {
    right: 0;
    width: 10%;
    height: 12px;
    background-position: 100% -30px;
}

.irs-diapason {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 6px;
    top: 10px;
    background-position: 0 -60px;
    background-image: none;
    /* background:#6db56d !important; */
    background: #a3e0a4 !important;
}

.irs-slider {
    position: absolute;
    display: block;
    cursor: default;
    z-index: 1;
    width: 10px;
    height: 14px;
    top: 9px;
    background-position: 0 -90px;
}

#irs-active-slider,
.irs-slider:hover {
    cursor: pointer;
}

.irs-slider.single {
    left: 10px
}

.irs-slider.single:before {
    position: absolute;
    display: block;
    content: "";
    top: -30%;
    left: -30%;
    width: 160%;
    height: 160%;
    background: rgba(0, 0, 0, 0.0);
}

.irs-slider.from {
    left: 100px;
    background: url("../img/filter/mini.png") no-repeat top left;
    opacity: 1;
    margin-top: 7px;
    margin-left: -10px;
}

.irs-slider.from:hover {
    background: url("../img/filter/mini.png") no-repeat bottom left
}

.irs-slider.from:before {
    position: absolute;
    display: block;
    content: "";
    top: -30%;
    left: -30%;
    width: 130%;
    height: 160%;
    background: rgba(0, 0, 0, 0.0);
}

.irs-slider.to {
    left: 300px;
    background: url("../img/filter/mini.png") no-repeat top right;
    opacity: 1;
    margin-top: 7px;
}

.irs-slider.to:hover {
    background: url("../img/filter/mini.png") no-repeat bottom right
}

.irs-slider.to:before {
    position: absolute;
    display: block;
    content: "";
    top: -30%;
    left: 0;
    width: 130%;
    height: 160%;
    background: rgba(0, 0, 0, 0.0);
}

.irs-slider.last {
    z-index: 2
}

.irs-min {
    position: absolute;
    display: block;
    left: 0;
    cursor: default;
}

.irs-max {
    position: absolute;
    display: block;
    right: 0;
    cursor: default;
}

.irs-min,
.irs-max {
    color: #999;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    top: 0;
    padding: 1px 3px;
    background: #e1e4e9;
    border-radius: 4px;
}

.irs-from,
.irs-to,
.irs-single {
    position: absolute;
    display: none !important;
    top: 0;
    left: 0;
    cursor: default;
    white-space: nowrap;
    color: #fff;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    padding: 1px 5px;
    background: #ed5565;
    border-radius: 4px;
}

.irs-grid {
    position: absolute;
    display: none;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
}

.irs-with-grid .irs-grid {
    display: block
}

.irs-grid-pol {
    position: absolute;
    top: 0px;
    margin-top: -7px;
    left: 0;
    width: 1px;
    height: 6px;
    background: #d8dbdf;
}

.irs-grid-pol.small {
    height: 3px;
    margin-top: -3px;
}

.irs-grid-text {
    position: absolute;
    top: -16px;
    left: 0;
    width: 100px;
    white-space: nowrap;
    text-align: center;
    font-size: 9px;
    line-height: 9px;
    color: #8f8e8e;
}

.irs-disable-mask {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: default;
    background: rgba(0, 0, 0, 0.0);
    z-index: 2;
}

.irs-disabled {
    opacity: 0.4
}

.irs-from:after,
.irs-to:after,
.irs-single:after {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #ed5565;
}

.irs-diapason-green {
    height: 6px;
    top: 10px;
    background: #4cae4c !important;
    position: absolute;
    display: block;
}

.irs-diapason-silver-left,
.irs-diapason-silver-right {
    height: 6px;
    top: 10px;
    background-position: 0 -60px;
    background-image: none;
    background: #afaeae !important;
    position: absolute;
    display: block;
}



/* обновленный вид шкалы диапазонов */



#filter .irs {
    width: auto;
    margin-left: 7px;
    margin-right: 7px;
}

#filter .irs .irs {
    width: 100%;
    margin-left: 0;
}

#filter .irs-with-grid .irs-grid {
    display: none;
}

#filter .irs-line,
#filter .irs-diapason,
#filter .irs-diapason-green,
#filter .irs-diapason-silver-left,
#filter .irs-diapason-silver-right {
    border: none;
    height: 2px;
    background: #cecece!important;
}

#filter .irs-diapason-green {
    width: 100%;
    display: none!important;
    background: #d63c26!important;
}

#filter .irs-diapason {
    background: #d63c26!important;/*#cecece*/
}

#filter .irs-slider {
    width: 14px;
    height: 14px;
    background: #333;
    border-radius: 50%;
    margin: 0 0 0 -7px;
    top: 4px;
}

#filter .irs-slider:before {
    top: -10px;
    left: -10px;
    bottom: -10px;
    right: -10px;
    width: auto;
    height: auto;
}

#filter .irs-slider.from {

}

#filter .irs-slider.to {

}

#filter .irs-line * {
    visibility: hidden;
}


/* Фильтры конец */

/* Категория товаров начало */

.decription-category-top {
    /* верхнее описание категории */
    display: table;
    clear: both;
	width: 100%;
}

.decription-category-top table td {
    font-size: 13px;
}

.decription-category-bottom {
    margin-top: 35px;
    margin-bottom: 35px;
}

.main-cetegory-image {
    background-color: #FFFFFF;
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    display: block;
    clear: both;
    height: auto;
    line-height: 1.42857;
    margin-bottom: 8px;
    width: 345px;
    padding: 4px;
    text-align: center;
}

.main-cetegory-image:hover {
    border-color: #CCC;
}

.category-images-additional {
    padding: 0;
    width: 345px;
    margin-right: -10px;
}

.category-images-additional li {
    list-style: none;
    display: inline-block;
    padding: 0;
}

.category-images-additional li a {
    background-color: #FFFFFF;
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    display: block;
    box-sizing: border-box;
    height: 79px;
    width: 79px;
    line-height: 71px;
    margin: 0 4px 8px 0;
    padding: 2px;
    text-align: center;
    vertical-align: middle;
}

.category-images-additional li a:hover {
    border-color: #CCC;
}


/* Категория товаров конец */


/* Общее начало */

.w100 {
    width: 100%
}

a.btn {
    text-decoration: none
}

.mini-onoff {
    display: block
}

.mini-onoff .btn-default.on.active {
    background-color: #5CB85C;
    border-color: #4CAE4C;
    color: #FFFFFF;
}

.userdata .help-block {
    font-size: 12px;
    margin: 0px 0 2px 0;
}

.userdata .form-group:last-child {
    margin-bottom: 0;
}

.alert {
    color: #333 !important
}

.container .navbar-header,
.container .navbar-collapse {
    margin-right: 0;
    margin-left: 0;
}

.topnews {
    display: block;
    margin-bottom: 15px;
    border-radius: 4px;
    background: #f5f1e8;
}

.topnews:hover {
    box-shadow: none;
}

.topnews p:last-child {
    margin-bottom: 0;
}

#content {
    margin-bottom: 30px
}


/* Общее конец */


/* Фильтр по цене */

.range {
    display: table;
    width: 100%;
    clear: both;
    font-size: 12px;
}

.rleft {
    display: block;
    float: left;
    width: 58%;
}

.rright {
    display: block;
    float: left;
    width: 42%;
}

.range .input-group-addon {
    padding: 0px 4px 0 4px;
    border: none;
    background: none;
    font-size: 13px;
}

.range .form-control {
/*    border-radius: 4px !important;*/
    padding: 5px;
    width: 63px;
}

.bs-callout-danger {
    background-color: #fdf7f7;
    border-color: #d9534f;
}

.bs-callout {
    margin: 20px 0;
    padding: 20px;
    border-left: 3px solid #eee;
}


/* Отзывы к товарам начало */

.rate-header {
    display: table;
    clear: both;
    width: 100%;
    margin-bottom: 20px;
}

div.vote-wrap {
    overflow: hidden
}

div.vote-block {
    position: relative
}

div.vote-hover {
    float: left;
    padding: 0 !important;
    cursor: pointer;
}

div.vote-stars {
    position: absolute;
    left: 0;
    top: 0;
}

div.vote-active {
    position: absolute;
    left: 0;
    top: 0;
}

div.vote-result {
    color: #a7a7a7;
    width: 100%;
    box-sizing: border-box;
    padding-left: 110px;
    font-size: 11px;
    margin-top: 2px;
}

div.vote-success {
    clear: both;
    color: #ccc;
    font-size: 14px;
    margin: 10px 0 0 10px;
}

.vote-revote {
    display: inline-block;
    margin-left: 7px;
}

.vote-revote a {
    cursor: pointer;
    font-size: 12px;
    text-decoration: none;
    border-bottom: 1px dotted;
}

.vote-revote a:hover {
    border-bottom: none
}

.raiting {
    display: inline-block;
    width: 79px;
    height: 15px;
    background: url("../img/stars/stars-mini.png") no-repeat;
}

.rate10 {
    background-position: 0px 0px
}

.rate15 {
    background-position: 0px -15px
}

.rate20 {
    background-position: 0px -30px
}

.rate25 {
    background-position: 0px -45px
}

.rate30 {
    background-position: 0px -60px
}

.rate35 {
    background-position: 0px -75px
}

.rate40 {
    background-position: 0px -90px
}

.rate45 {
    background-position: 0px -105px
}

.rate50 {
    background-position: 0px -120px
}

.rating-micro {
    display: inline-block;
    width: 57px;
    height: 11px;
    background: url("../img/stars/stars-micro.png") no-repeat;
}

.plitka-rate {
    display: block;
    width: 206px;
    position: absolute;
    margin-top: 200px;
    z-index: 1;
}

.micro-rate10 {
    background-position: 0px 0px
}

.micro-rate15 {
    background-position: 0px -12px
}

.micro-rate20 {
    background-position: 0px -24px
}

.micro-rate25 {
    background-position: 0px -36px
}

.micro-rate30 {
    background-position: 0px -48px
}

.micro-rate35 {
    background-position: 0px -60px
}

.micro-rate40 {
    background-position: 0px -72px
}

.micro-rate45 {
    background-position: 0px -84px
}

.micro-rate50 {
    background-position: 0px -96px
}

.agregate-raiting {
    position: relative;
    padding: 8px 8px;
    border-radius: 4px;
    font-size: 12px;
    background: #f6f5f3 !important;
    display: table;
    width: 100%;
}

.agregate-left {
    width: 30%;
    margin-left: 5px;
    box-sizing: border-box;
    float: left;
    display: block;
}

.agregate-right {
    width: 60%;
    float: right;
    display: block;
}

.agregate-left .raiting {
    margin: 5px 0 0 -5px;
    display: inline-block;
}

.raiting-count {
    display: inline-block;
    line-height: 1.5;
    vertical-align: top;
}

.write-review {
    float: right
}

.agregate-right #raiting {
    margin-top: 0
}

.review-header {
    margin-bottom: 10px;
    display: table;
    clear: both;
    width: 100%;
    line-height: 34px;
    font-size: 22px;
    margin-top: 10px;
    border-bottom: 1px solid #dddddd;
}

.review-header-right {
    display: block;
    float: right;
    width: 50%;
    height: 38px;
    vertical-align: bottom;
}

.review-header-right .rating {
    margin-top: 7px
}

.rate-sort {
    width: 50%;
    display: block;
    float: left;
    line-height: 34px;
}

.please-rate {
    display: block;
    float: left;
    font-size: 14px;
    padding: 0 10px 4px 0;
}

ol.reviews-list-products {
    list-style: none;
    padding: 0;
    margin-bottom: 15px;
}

ol.reviews-list-products li:nth-child(2n+1) {
    background: #fcfcfc;
    /* фон для каждого второго элемента */
}

ol.reviews-list-products li {
    display: table;
    clear: both;
    width: 100%;
    height: auto;
    line-height: 18px;
    margin: 0;
    padding: 8px;
    color: #333;
    text-decoration: none;
    border-bottom: 1px solid #e1e1e1;
    background: #f5f5f5;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

ol.reviews-list-products li .checkb {
    display: block;
    float: left;
    margin-right: 8px;
    width: 13px;
    height: 13px;
}

.reviews-list-image {
    display: block;
    float: left;
    margin-right: 8px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    height: 50px;
    margin-right: 8px;
}

.reviews-list-controls {
    display: block;
    float: right;
    width: 40px;
}

.reviews-list-body {
    display: block;
    float: left;
}

.review-id {
    display: block;
    margin: 7px 0 0px 0;
    font-size: 13px;
    color: #999;
}

.review-title {
    min-height: 32px;
    background: #fafafa;
    border-radius: 4px;
    padding: 4px 8px;
    width: 180px;
    box-sizing: border-box;
    margin-right: 15px;
    display: block;
    float: left;
    font-size: 13px;
}

.review-autor {
    font-size: 13px;
    display: block;
}

.review-autor a {
    text-decoration: underline;
    color: #333;
}

.review-autor a:hover {
    text-decoration: none
}

.review-user-count {
    display: inline-block;
    line-height: 30px;
    vertical-align: middle;
}

.review-date {
    line-height: 15px;
    opacity: 0.7;
    display: block;
    font-size: 13px;
    margin-top: 5px;
}

.review-rate {
    display: block;
    float: right;
    margin: 8px 8px 0 0;
}

.review-stars-text {
    color: #999;
    font-size: 12px;
    display: inline-block;
    margin-left: 10px;
}

.review-verdict {
    display: table;
    width: 100%;
    clear: both;
    margin-bottom: 10px;
    font-size: 13px;
}

.review-verdict-type {
    margin-bottom: 8px;
    display: block;
}

.review-verdict-comment {
}

.review-verdict-header {
    display: block;
    float: left;
    width: 20px;
    vertical-align: top;
    line-height: 19px;
    font-size: 14px;
}

.review-verdict-header .fa-plus-circle {
    color: #1f8321
}

.review-verdict-header .fa-minus-circle {
    color: #e94932
}

.review-verdict-header.review-comment {
    margin-top: -2px;
}

.review-verdict-header .fa-comment-o {
    color: #9c9c9c;
}

.review-verdict-text {
    display: block;
    float: right;
    width: 505px;
}

.review-body {
    display: block;
    clear: both;
    width: 100%;
    padding-bottom: 14px;
    border-bottom: 1px dashed #E3E3E3;
    margin-bottom: 15px;
}

.review-body:after {
    display: table;
    clear: both;
    content: "";
}

.review-body-text {
    display: block;
    float: right;
    width: 525px;
}

.usergrade {
    font-size: 13px;
    float: right;
    width: 410px;
    display: block;
    text-align: right;
    color: #999;
}

.usergrade-yes {
    color: #239600;
    text-decoration: underline;
    cursor: pointer;
}

.usergrade-no {
    color: #ff2f2f;
    cursor: pointer;
    text-decoration: underline;
}

.usergrade-yes:hover,
.usergrade-no:hover {
    text-decoration: none
}

.review-complain {
    display: inline-block;
    text-decoration: none;
    margin: 5px 0;
    color: #999;
    border-bottom: 1px dotted;
    line-height: 1;
}

.review-complain a {
    font-size: 12px;
    color: #999;
    opacity: 0.3;
}

.review-complain a:hover {
    opacity: 1;
    border-bottom: none;
}

.my-review {
    display: table;
    width: 100%;
    clear: both;
    padding: 12px 18px;
    border-radius: 4px;
    background: #f8f7f6;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1), 0 3px 4px -3px rgba(0, 0, 0, .3);
    margin-bottom: 20px;
}

.my-review-header {
    font-size: 22px;
    margin-bottom: 10px;
}

.my-review-send {
    width: 78%;
    display: block;
    float: right;
}

.my-review-send .btn {
    width: 50%;
    margin-bottom: 6px;
}

.myrate label {
    line-height: 30px;
    display: block;
    float: left;
    width: 21%;
}

.my-review label {
    display: block;
    float: left;
    width: 21%;
    line-height: 34px;
}

.myrate .rating {
    display: block;
    width: 78%;
    float: right;
}

.my-review .form-control {
    display: block;
    float: right;
    width: 78%;
}

.my-review .form-group {
    display: table;
    clear: both;
    width: 100%;
}

.myrate {
    margin-bottom: 15px;
    height: 30px;
    display: block;
}

.myrecommend {
    display: table;
    clear: both;
    margin: -4px 0 15px 22%;
    padding: 4px 8px 4px 8px;
    background: #ECECEC;
    border-radius: 4px;
}

.myrecommend .checkbox {
    margin: 0
}

.myrecommend label {
    width: 100%;
    line-height: 20px;
}

.irecommend {
    color: #1f8321;
    font-size: 12px;
}

.irecommend i {
    font-size: 14px;
    width: 20px;
    display: inline-block;
}

.review-topline {
    font-size: 13px;
    line-height: 15px;
    vertical-align: top;
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.review-verdict-text .review-images {
    margin: 8px 0 0 0;
    padding: 0;
}

.review-images li {
    list-style: none;
    display: inline-block;
}

.review-images li a {
    box-sizing: border-box;
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
    display: block;
    height: 68px;
    width: 68px;
    line-height: 62px;
    padding: 2px;
    border-radius: 2px;
    margin: 0;
    text-align: center;
    vertical-align: middle;
}

.review-images li a:hover {
    border: 1px solid #CCC
}

.review-images li a img {
    max-width: 62px;
    max-height: 62px;
    margin-top: -2px;
}

.review-add-images {
    /** форма заливки изображений для отзыва **/
    display: block;
    float: right;
    width: 78%;
    margin-top: 8px;
}

.review-add-images .btn {
}


/** Стилизуем input[type=file] **/

.btn-file {
    position: relative;
    overflow: hidden;
    display: block;
    float: left;
}

.btn-file i {
    font-size: 14px
}

.file-list {
    display: block
}

.btn-file input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: translate(-300px, 0) scale(4);
    transform: translate(-300px, 0) scale(4);
    font-size: 23px;
    direction: ltr;
    cursor: pointer;
}


/* Fix for IE 7: */

* + html .btn-file {
    padding: 2px 15px;
    margin: 1px 0 0 0;
}

.review-images.add-image {
    margin: 0;
    padding: 0;
    width: 360px;
    display: block;
    float: right;
}

.remove-review-image {
    border: none;
    border: none;
    background-color: #13131b;
    color: #fff;
    position: absolute;
    line-height: 1;
    display: block;
    margin-top: 1px;
    font-size: 12px;
    margin: 0px 0 0 47px;
    opacity: 0.5;
    padding: 1px 3px 2px;
    z-index: 300;
    cursor: pointer;
}

.remove-review-image:hover {
    opacity: 0.7
}

#comment-block-error-1,
#comment-block-error-2 {
    margin-left: 144px
}

.rate-sort {
    display: block;
    float: left;
    width: 500px;
}

.rate-sort-item {
    font-size: 13px;
    line-height: 13px;
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 9px 10px 10px;
    text-align: center;
    text-decoration: none;
    border: 1px solid #e7e7e7;
    border-left: none;
}

.rate-sort-item.active {
    background: #F0F0F0
}

.rate-sort-item.active a {
    text-decoration: none
}

.rate-sort-item i {
    font-size: 13px;
    line-height: 13px;
    color: #BBB;
}

.rate-sort-item:first-child {
    padding-left: 10px;
    border-radius: 4px 0 0 4px;
    border-left: 1px solid #e7e7e7;
}

.rate-sort-item:last-child {
    padding-right: 10px;
    border-radius: 0 4px 4px 0;
}

.non-reviews {
    display: block;
    margin: 5px 0 20px;
}

.review-product-image {
    width: 56px;
    padding: 2px;
    height: 56px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 2px;
    text-align: center;
    line-height: 52px;
    display: block;
}

.review-product-image img {
    margin-top: -2px
}

.review-autor-header {
    display: block;
    height: 25px;
    font-size: 13px;
}

.review-product-link {
    font-size: 13px;
    line-height: 16px;
    display: block;
    margin-top: 4px;
}

div.vote-wrap {
    overflow: hidden;
}

div.vote-block {
    position: relative;
}

div.vote-hover {
    float: left;
    cursor: pointer;
}

div.vote-stars {
    position: absolute;
    left: 0;
    top: 0;
}

div.vote-active {
    position: absolute;
    left: 0;
    top: 0;
}

div.vote-success {
    clear: both;
    color: #ccc;
    font-size: 14px;
    margin: 10px 0 0 10px;
}


/* Отзывы к товарам конец */


/* Вопрос-ответ к товару начало */

.question-header {
    margin-bottom: 10px;
    display: table;
    clear: both;
    width: 100%;
    line-height: 34px;
}

.write-question {
    float: right
}

.q-separator {
    padding-top: 4px;
    border-top: 1px dashed #E3E3E3;
    margin-top: 15px;
}

.question {
    display: block;
    font-size: 13px;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #eee;
    border-left-width: 5px;
    border-radius: 3px;
    border-left-color: #5bc0de;
}

.answer {
    display: block;
    font-size: 13px;
    padding: 10px;
    margin: 10px 0 10px 30px;
    border: 1px solid #eee;
    border-left-width: 5px;
    border-radius: 3px;
    border-left-color: #eee;
}

.answer-solved {
    border-color: #d6e9c6;
    border-left-color: #47a447;
    background: #eaf6e6;
}

.answer-solved .fa-check-circle {
    color: #47a447;
    font-size: 14px;
}

.answer-solved .question-username {
    font-weight: bold;
    color: #333;
}

.answer-solved .question-username span {
    text-decoration: underline
}

.question-header {
    line-height: 1.2
}

.question-username {
    display: inline-block;
    color: #2a6496;
}

.question-date {
    display: inline-block;
    color: #aaa;
    margin-left: 15px;
}

.question-complain {
    display: block;
    text-decoration: none;
    color: #999;
    border-bottom: 1px dotted;
    line-height: 1;
    float: right;
    opacity: 0.4;
}

.question-complain:hover {
    border-bottom: none;
    opacity: 1;
}

.question-text {
    display: table;
    clear: both;
    width: 100%;
    margin-bottom: 10px;
}

.your-answer {
    display: inline-block
}

.help-counter {
    display: block;
    float: right;
    color: #aaa;
}

.answer-help-me {
    display: block;
    float: right;
    margin-left: 15px;
}

.answer-admin {
    color: #a92b2b;
    font-weight: bold;
    display: inline-block;
    margin-left: 15px;
}

.my-question {
    /* форма задания вопроса */
    display: table;
    width: 100%;
    clear: both;
    padding: 12px 18px;
    border-radius: 4px;
    background: #f8f7f6;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1), 0 3px 4px -3px rgba(0, 0, 0, .3);
    margin: 10px 0 20px 0;
}

.my-question-header {
    font-size: 22px;
    margin-bottom: 10px;
}

.my-question-send {
    width: 78%;
    display: block;
    float: right;
}

.my-question-send .btn {
    width: 50%;
    margin-bottom: 6px;
}

.my-question label {
    display: block;
    float: left;
    width: 21%;
    line-height: 34px;
}

.my-question .form-control {
    display: block;
    float: right;
    width: 78%;
}

.my-question .form-group {
    display: table;
    clear: both;
    width: 100%;
}

.my-question-add-images {
    /* форма заливки изображений для отзыва */
    display: block;
    float: right;
    width: 78%;
    margin-top: 8px;
}


/* Вопрос-ответ к товару конец */


/* Кнопка вверх начало */

.b-top {
    width: 50px;
    height: 50px;
    background-color: #ebe5d7;
    text-align: center;
    padding: 10px 0;
    position: fixed;
    bottom: 170px;
    right: 2%;
    cursor: pointer;
    display: none;
    color: #333;
    font-size: 20px;
    z-index: 999;
}

.b-top:hover {
    opacity: 1;
    cursor: pointer;
}


/* Кнопка вверх конец */


/* Группы категорий на главной начало */

ul.category-list {
    padding: 0;
    display: table;
    clear: both;
    margin: 0 -6px;
}

ul.category-list li {
    list-style: none;
    padding-left: 0;
    float: left;
    display: block;
    list-style: none outside none;
    width: 174px;
    margin: 0 6px 20px;
}

ul.category-list li a {
    padding: 4px;
    display: table;
    width: 100%;
    clear: both;
    border: 1px solid #eeeeee;
    text-align: center;
}

ul.category-list li a:hover {
    border-color: #CCCCCC
}

.category-list-name {
    font-size: 15px;
    color: #333;
    font-weight: 500;
    width: 100%;
    clear: both;
    margin: 7px 0 0;
    font-weight: 700;
    line-height: 1.2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.category-list-name span {
    color: #333;
    text-transform: uppercase;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    text-align: center;
}

ul.category-list li a {
}

ul.category-list li a:hover .category-list-name span {
    text-decoration: none;
}

.category-list-image {
    line-height: 165px;
    text-align: center;
    vertical-align: central;
    display: block;
    margin-top: -1px;
}

/* Группы категорий на главной конец */


.icon {
    position: relative;
    display: inline-block;
    fill: currentColor;
    width: 1em;
    height: 1em;
    letter-spacing: 0;
    line-height: 1;
}

svg.icon {
    pointer-events: none;
}


/* Горизнотальное Мегаменю товаров начало */

.horizontal-navbar {
    /*background: url("../img/menu.png") repeat-x bottom left;*/
    border: none !important;
    margin-bottom: 0px !important;
}

.horizontal-navbar li.dropdown.dropdown-large {
    /*margin-bottom: 3px;*/
    /*background: #d53535;*/
}

.horizontal-navbar li.dropdown.dropdown-large a.menu-level0:hover {
    background-color: #fff !important;
}

.horizontal-navbar li.dropdown.dropdown-large a:hover {
    color: #000 !important;
}

.horizontal-navbar .menu-level0 {
    text-decoration: none;
    display: block;
    height: 51px;
    border-right: 1px solid #e7e4dd;
    color: #fff !important;
    padding: 15px 10px 17px 10px;
    -webkit-transition: background 0.1s ease;
    transition: background 0.1s ease;
    /*font-size: 15px;
    font-weight: 500;*/
	font-size: 16px;
	font-weight: 550;
    white-space: nowrap;
}

.horizontal-navbar .menu-icon {
    position: relative;
    top: -2px;
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
    margin-right: 5px;
}

/*
#categories-menu-columns li.active:first-child a,
#categories-menu-columns li:first-child a:hover {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}*/

.horizontal-navbar .menu-level0 span {
}

.horizontal-navbar .menu-level0:hover span {
    text-decoration: none;
}

.js-navbar-collapse li:last-child a {
    border-right: none;
}

.navbar-default.horizontal-navbar .navbar-nav > .open > a,
.navbar-default.horizontal-navbar .navbar-nav > .open > a:hover,
.navbar-default.horizontal-navbar .navbar-nav > .open > a:focus {
    background-color: #FFF !important;
}

#main-navbar .horizontal-navbar .navbar-collapse {
    background-color: #d6341d;
    border-right: none;
}

/*#main-navbar .navbar-default {
	background-color: none;
}*/

#categories-menu-columns {
    display: table;
    width: 100%;
    vertical-align: middle;
}

#categories-menu-columns li.dropdown-large {
    float: none;
    display: table-cell;
    text-align: center;
}

.dropdown:hover a.menu-level0,
.navbar-default .navbar-nav > .active > a.menu-level0,
.dropdown:hover a.menu-level0 {
    /*background-color: #ebe5d7 !important;*/
    background-color: #d6341d !important;
}

.navbar-default .navbar-nav > .active > a.menu-level0:hover,
.navbar-default .navbar-nav > .active > a.menu-level0:focus {
    background-color: #fff;
}

.horizontal-navbar .menu-level0 .caret {
    opacity: 0.6;
    display: none;
}

.js-navbar-collapse {
    padding: 0;
}

.dropdown-menu {
    border: 1px solid #e7e7e7;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.09);
    z-index: 5;
}

.dropdown-menu-large ul {
    margin: 0;
    padding: 0;
}

.dropdown-large {
    position: static !important;
}

.dropdown-menu-large {
    padding: 10px 0px;
    margin-left: -1px !important;
    /*margin-top: -3px !important;*/
}

li.menu-column-width ul {
    padding: 0;
    margin: 0;
}

li.menu-column-width ul li {
    list-style: none;
}

li.menu-column-width ul li a, li.menu-column-width ul li span {
    display: block;
    padding: 8px 8px;
    clear: both;
    font-weight: normal;
    line-height: 1.428571429;
    white-space: normal;
    /*font-size: 15px;*/
	font-size: 16px;
    color: #333;
}


li.menu-column-width ul li.have-childs a {

}

li.menu-column-width ul li a:hover, li.menu-column-width ul li span:hover {
    background: #f5f5f5;
}

li.menu-column-width ul li > ul > li {
    margin-left: 10px;
    list-style: none;
}

li.menu-column-width ul li > ul > li > a, li.menu-column-width ul li > ul > li > span {
    display: block;
    padding: 3px 7px;
    clear: both;
    font-weight: normal;
    color: #333333;
    /*font-size: 13px;*/
	font-size: 14px;
    white-space: normal;
}

.dropdown-menu-large > li ul > li > a:hover,
.dropdown-menu-large > li ul > li > a:focus {
    text-decoration: none;
    color: #262626;
    background-color: #f5f5f5;
}

.dropdown-menu-large .disabled > a,
.dropdown-menu-large .disabled > a:hover,
.dropdown-menu-large .disabled > a:focus {
    color: #999999;
}

.dropdown-menu-large .disabled > a:hover,
.dropdown-menu-large .disabled > a:focus {
    text-decoration: none;
    background-color: transparent;
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled false);
    cursor: not-allowed;
}

.dropdown-menu-large .dropdown-header {
}


/* Настройки простого меню */

.simple-menu {
    /* Если меню простое то ориентируем его по левому краю */
    float: right !important;
    left: auto !important;
    margin-left: -1px;
    /*margin-top: -3px !important;*/
}

.simple-menu li a {
    /* отступы и минимальная ширина пункта меню в простом меню */
    padding: 5px 15px;
    min-width: 210px;
    font-size: 15px;
    -webkit-transition: background 0.1s ease;
    transition: background 0.1s ease;
}


/* Настройки Мегаменю */

.menu-width-1 {
    /* Если на ширину колонок то ширину не указываем и также ориентируем по левому краю пункта меню*/
    left: auto !important;
}

.menu-width-2 {
    /* Если на пункт на всю ширину то ориентируем по левому краю меню  */
    width: 970px !important;
    left: 0 !important;
}

.menu-column-width {
    /* Если 2 колонки  */
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    display: list-item;
    float: left;
}

.columns-width-standart {
    /* Ширина колонки по умолчанию */
    width: 240px;
}

.menu-width-2 .columns-width-2 {
    /* Если меню на всю ширину и 2 колонки */
    width: 50%;
}

.menu-width-2 .columns-width-3 {
    /* Если меню на всю ширину и 3 колонки */
    width: 33.3333%;
}

.menu-width-2 .columns-width-4 {
    /* Если меню на всю ширину и 3 колонки */
    width: 25%;
}

.menu-width-2 .columns-width-5 {
    /* Если меню на всю ширину и 5 колонки */
    width: 20%;
}

.submenu-columns-2 {
    /* Если у меню второго уровня выбрано что подпункты разбиваем на 2 колонки */
    -moz-column-count: 2;
    -moz-column-gap: 5px;
    -webkit-column-count: 2;
    -webkit-column-gap: 0px;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 0px;
    -moz-column-gap: 0px;
    column-gap: 0px;
}

.submenu-columns-3 {
    /* Если у меню второго уровня выбрано что подпункты разбиваем на 2 колонки */
    -moz-column-count: 3;
    -moz-column-gap: 5px;
    -webkit-column-count: 3;
    -webkit-column-gap: 0px;
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 0px;
    -moz-column-gap: 0px;
    column-gap: 0px;
}

.wide-menu-block {
    /* Широкий баннер который показывается снизу у пунктов меню */
    padding: 20px 0 0 10px;
    display: table;
    clear: both;
    width: 100%;
}

.wide-menu-block p {
    margin-bottom: 0;
}

.menu-align-left {
    /* Если меню ориентировано влево */
    right: 0;
}

.brandsmenu {
    float: right !important;
    display: block;
}

.menubrands {
    /* пункт меню бренды */
    float: right !important;
}

.menubrands .dropdown-menu {
    padding: 15px 15px 15px 15px;
}

.menubrands-link {
    border-right: none;
    border-left: 1px solid #e7e7e7;
}

.menubrands ul {
    -moz-column-count: 4;
    -moz-column-gap: 10px;
    -webkit-column-count: 4;
    -webkit-column-gap: 10px;
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    -webkit-column-gap: 10px;
    -moz-column-gap: 10px;
    column-gap: 10px;
}

.menubrands ul li {
    list-style: none;
}

.menubrands ul li span {
    color: #B3B3B3;
}

.menubrands ul li {
    padding: 2px 0;
    display: block;
}


/* Горизнотальное Мегаменю товаров конец */


/* Баннеры слева от слайдшоу начало */

.top-action {
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    padding: 8px;
    display: block;
    line-height: 1.3;
    height: 200px;
    display: table;
    width: 100%;
    clear: both;
    margin-bottom: 20px;
}

.top-action p {
    margin-bottom: 5px;
}


/* Баннеры слева от слайдшоу конец */


/* Блок "Нужна помощь?" начало */

.needhelp {
    font-size: 19px;
    background: url("../img/icons/help.png") no-repeat 6px 6px #f5f5f5;
    padding: 18px 18px 18px 66px;
    margin-bottom: 20px;
    min-height: 60px;
    border-radius: 4px;
}

.needhelp p {
    margin-bottom: 0;
}

.needhelp-header {
    display: block;
    float: left;
    margin-right: 38px;
}

.needhelp-text {
    display: block;
    float: left;
}

.needhelp-add {
    display: block;
    float: right;
}


/* Блок "Нужна помощь?" конец */


/* Блок "О компании" внизу прямо перед футером - начало */

.company {
    background: #f8f5ee;
    display: block;
}

.company a {
    color: #333;
}

.company-inner {
    width: 970px;
    margin: 0 auto;
    padding: 25px 0;
}

.company-menu a {
    text-decoration: underline;
}

.company-menu-header {
    font-size: 14px;
    color: #464646;
    margin-bottom: 5px;
    font-weight: bold;
    text-transform: uppercase;
}

.vertical-menu {
    margin: 0;
    padding: 0;
}

.vertical-menu li {
    list-style: none;
    margin: 0;
}


/* Блок "О компании" внизу прямо перед футером - конец */


/* Футер (Подвал) сайта начало */

#footer {
    background-color: #eeeae1;
}

#footer p {
    margin-bottom: 0;
    color: #5d5d5d;
    font-size: 13px;
    line-height: 1.15;
}

#footer a {
    color: #575757;
}

.footer-logo {
    background: url("../img/logo_desc.svg") no-repeat;
	/*background: url("../img/KD-NY2023.svg") no-repeat;*/
    width: 206px;
    height: 45px;
    display: block;
    margin: 0px 0 18px;
}

.footer-copyright,
.footer-cms {
    margin: 15px 0;
}

.footer-cms {
    text-align: right;
}

.footer-phone {
    font-size: 22px;
    font-weight: bold;
    margin: 6px 0;
}

.footer-time {
    margin: 6px 0;
}

.footer-mail a {
}

.footer-mail {
    margin: 6px 0;
}

.footer-make-call a {
    text-decoration: none;
    width: 100%;
    display: block;
    text-align: center;
    padding: 6px;
    background: #d7e8f1;
    margin-top: 12px;
}

.footer-make-call a:hover {
    background: #a6c7d8
}


/* Футер (Подвал) сайта конец */


/* Галлерея материалов начало */

.gallery-list {
    /* Отображение списком */
    padding: 0;
    list-style: none;
}

.gallery-list li {
    margin: 10px;
}

.gallery-tile {
    /* Отображение плиткой */
    padding: 0;
    list-style: none;
    margin: 0 -5px;
}

.gallery-tile li {
    margin: 0 5px 14px 5px;
    display: inline-block;
}


/* Галлерея материалов конец */


/* Файлы товара начало */

ul.files {
    margin: 0;
    padding: 0;
}

ul.files li {
    list-style: none;
    display: block;
    padding: 5px 0;
}

.fileimage {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 2px;
}

ul.files li a {
    display: inline-block;
}

.files-header {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    line-height: 30px;
    text-transform: uppercase;
}


/* Файлы товара конец */


/* Сопутствующие товары начало */

.inproduct-header {
    display: inline-block;
    font-size: 18px;
    line-height: 30px;
    text-transform: uppercase;
    font-weight: 500;
}


/* Сопутствующие товары конец */


/* Управление под админом начало */

.edit {
    opacity: 0.4;
}

.edit:hover {
    opacity: 1;
}


/* Управление под админом конец */


/* Новое слайдшоу Swiper начало */

.topblocks {
    margin-bottom: 25px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.swiper-container {
    width: 100%;
}

.main-slideshow-wrapper {
    position: relative;
    margin: 0 auto;
    padding-left: 60px;
    padding-right: 60px;
}

.main-slideshow, .main-slideshow-mobile {
    /*max-width: 862px;*/
    /*max-width: 90%;*/
    border: none;
    border-radius: 0;
    margin: 0 auto 25px;
}

.main-slideshow {
    border: none;
}

.swiper-button-prev, .swiper-button-next {
    z-index: 1;
}

.main-slideshow-wrapper .swiper-button-prev {
    left: 0;
}

.main-slideshow-wrapper .swiper-button-next {
    right: 0;
}

.main-slideshow-wrapper img {
    max-width: 100%;
}

.main-slideshow-wrapper .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    min-height: 280px;
    padding: 2px;
}

.main-slideshow-wrapper .swiper-button-next,
.main-slideshow-wrapper .swiper-button-prev {
    opacity: 0.075;
}

.main-slideshow-wrapper .swiper-button-next:hover,
.main-slideshow-wrapper .swiper-button-prev:hover {
    opacity: 0.4;
}


/* Новое слайдшоу Swiper конец */


/* Баннеры слева от слайдшоу начало */

.flex-vertical {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 280px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
}

.flex-vertical a {
    color: #333;
    text-decoration: none;
}

.left-main {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    border: 1px solid #e7e7e7;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 12px;
    -webkit-flex-basis: 220px;
    -ms-flex-preferred-size: 220px;
    min-width: 220px;
    max-width: 220px;
    min-height: 84px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.left-main:hover {
    border-color: #CCC;
}

.flex-vertical .left-main:last-child {
    margin-bottom: 0;
}

.left-main-header {
    font-family: Lato, sans-serif;
    font-weight: 700;
    font-size: 18px;
}

.left-main-body {
    font-family: Lato, sans-serif;
    font-size: 13px;
    line-height: 19px;
    padding: 0 12px;
}

.left-main-body p {
    margin-bottom: 0;
}

.block-new {
    /* Описание блока Новинки */
    background: url("../img/blocks/new.png") no-repeat right center;
}

.block-new .left-main-header {
    margin-top: 4px;
    margin-bottom: 4px;
}

.block-new .left-main-header span {
    color: #FFF;
    background: #2fb457;
    display: inline-block;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    padding: 3px 8px 3px 11px;
}

.block-sale {
    /* Описание блока Скидки */
    background: url("../img/blocks/sale.png") no-repeat 110% center;
}

.block-sale .left-main-header {
    margin: 7px 11px 3px 11px;
}

.block-sale .left-main-header span {
    display: inline-block;
}

.block-delivery {
    /* Описание блока Доставки */
    background: url("../img/blocks/delivery.png") no-repeat 110% 0px;
}

.block-delivery .left-main-header {
    margin: 7px 11px 6px 11px;
    width: 120px;
    line-height: 22px;
}

.block-delivery .left-main-header span {
    display: inline-block;
}


/* Баннеры слева от слайдшоу конец */


/* Меню материалов - начало */


/* Показывается в левой колонке на всех материалах и категориях материалов */


/* Общее начало */

.materials-menu {
    margin-bottom: 25px;
}

.materials-menu-header {
    font-size: 22px;
    line-height: 1.2;
    margin-bottom: 6px;
    padding-bottom: 6px;
    margin-top: 2px;
    border-bottom: 1px solid #e7e7e7;
}

.materials-menu ol li a {
    display: block;
    font-size: 15px;
}


.menu-switcher.materials-menu-switcher {
    margin-top: -2px;
    display: none;
    height: 30px;
    width: 36px;
    margin-right: 0;
    margin-left: 25px;
    -webkit-transform: scale(0.64);
    -moz-transform: scale(0.64);
    -ms-transform: scale(0.64);
    -o-transform: scale(0.64);
    transform: scale(0.64);
}

.menu-switcher.materials-menu-switcher.position-2 {
    margin-top: 7px;
}

.menu-switcher.materials-menu-switcher.position-2 ~ #filter {
    top: 40px;
}

.menu-switcher.materials-menu-switcher:before {
    position: absolute;
    top: -1px;
    right: 0;
    left: 0;
    height: 5px;
    width: 36px;
    background-color: #d53535;
}

.menu-switcher.materials-menu-switcher:after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 5px;
    width: 36px;
    background-color: #d53535;
}

.menu-switcher.materials-menu-switcher > span:first-child {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    height: 5px;
    width: 36px;
    margin-top: -3px;
    background-color: #d53535;
}

.menu-switcher.materials-menu-switcher.active:before {
    background-color: #72706d;
}

.menu-switcher.materials-menu-switcher.active:after {
    background-color: #72706d;
}

.menu-switcher.materials-menu-switcher.active > span:first-child {
    background-color: #72706d;
}

.menu-switcher.materials-menu-switcher > span:first-child + span {
    top: -13px;
    right: -10px;
    bottom: -13px;
    left: -10px;
    z-index: -1;
}

.csstransforms .menu-switcher.materials-menu-switcher > span:first-child + span {
    background-color: #eee;
}

.menu-switcher.filter-switcher {
    background-color: #e8e8e8;
    margin-left: 115px;
    z-index: 100;
}

.menu-switcher.filter-switcher:before,
.menu-switcher.filter-switcher:after {
    display: none;
}

.menu-switcher.filter-switcher.fixed {
    position: fixed;
    top: 55px !important;
    right: 9px !important;
}

.menu-switcher-bg {
    position: absolute;
    background-color: #f8f5ee;
    top: -13px;
    right: -10px;
    bottom: -13px;
    left: -10px;
    z-index: -1;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.menu-switcher-title {
    position: absolute;
    top: 2px;
    text-align: right;
    right: 100%;
    line-height: 1.2;
    padding-right: 13px;
    font-size: 11px;
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale(1.5625);
    -moz-transform: scale(1.5625);
    -ms-transform: scale(1.5625);
    -o-transform: scale(1.5625);
    transform: scale(1.5625);
}

.menu-switcher .fa {
    color: #d53535;
    font-size: 33px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.menu-switcher.active .menu-switcher-bg {
    background-color: #f8f5ee;
}

.menu-switcher.active .fa {
    color: #72706d;
}

.materials-menu {
    margin-bottom: 25px;
}

#h1-sort-header-block .materials-menu {
    display: none;
}

.materials-menu-header {
    font-size: 22px;
    line-height: 1.2;
    margin-bottom: 6px;
    padding-bottom: 6px;
    margin-top: 2px;
    border-bottom: 1px solid #e7e7e7;
}

.materials-menu ol li a {
    display: block;
    font-size: 15px;
}


/* Меню материалов - конец */


/* Отзывы о магазине начало */

.review-shop-all {
    font-size: 15px;
    display: block;
    margin: 15px 0;
    padding-bottom: 15px;
    border-bottom: 1px solid #efeeed
}

.review-shop-circle {
    background: #f8f5ee;
    color: #4cae4c;
    font-size: 40px;
    border-radius: 4px;
    text-align: center;
    margin: 15px;
    font-weight: 500;
}

.rsy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    background: #f6f6f6;
    padding: 20px;
}

.rsy-avatar {
    border-radius: 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-basis: 40px;
    -ms-flex-preferred-size: 40px;
    flex-basis: 40px;
    margin-right: 10px;
}

.rsy-text {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-basis: 340px;
    -ms-flex-preferred-size: 340px;
    flex-basis: 340px;
    margin-right: 10px;
}

.rsy-thumb {
    margin-right: 10px;
}

.rsy-thumb .btn {
    font-size: 19px;
}

.rsy-thumb .btn-default {
    background: #f6f6f6;
}

.rsy-send .btn {
    height: 40px;
}


/* Отзывы о магазине конец */


/* Бегунок для товаров которые продаются частями - начало */

#awesome2 {
    margin-bottom: 3em;
}

#awesome2 .ui-slider-handle {
    border-radius: 15px/10px;
    background: #4F5861;
    border-color: #4F5861;
}

#awesome2 .ui-slider-handle:after {
    content: " ";
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-top-color: #4F5861;
    position: absolute;
    bottom: -12px;
    left: 50%;
    margin-left: -7px;
}

#awesome2 .ui-slider-tip,
#awesome2 .ui-slider-tip:before,
#awesome2 .ui-slider-tip:after {
    background: transparent;
    border: none;
    font-size: 1.2em;
}

#awesome2 .ui-slider-pip {
    width: 1%;
    margin-left: -0.5%;
}

#awesome2 .ui-slider-pip > .ui-slider-label {
    display: none;
    height: 4px;
}

#awesome2 .ui-slider-pip > .ui-slider-line {
    background: #aaa;
}

#awesome2 .ui-slider-pip:nth-of-type(5n+1) > .ui-slider-line {
    height: 6px;
    background: #777;
}

#awesome2 .ui-slider-pip:nth-of-type(5n+1) > .ui-slider-label {
    display: block;
    top: 8px;
    color: #ccc;
    font-size: 0.8em;
}

#awesome2 .ui-slider-pip:nth-of-type(10n+1) > .ui-slider-line {
    height: 10px;
    background: #222;
}

#awesome2 .ui-slider-pip:nth-of-type(10n+1) > .ui-slider-label {
    display: block;
    top: 12px;
    color: #333;
    font-size: 1em;
}


/* HORIZONTAL */


/* increase bottom margin to fit the pips */

.ui-slider-horizontal.ui-slider-pips {
    margin-bottom: 1.4em;
}


/* default hide the labels and pips that arnt visible */


/* we just use css to hide incase we want to show certain */


/* labels/pips individually later */

.ui-slider-pips .ui-slider-label,
.ui-slider-pips .ui-slider-pip-hide {
    display: none;
}


/* now we show any labels that we've set to show in the options */

.ui-slider-pips .ui-slider-pip-label .ui-slider-label {
    display: block;
}


/* PIP/LABEL WRAPPER */


/* position each pip absolutely just below the default slider */


/* and also prevent accidental selection */

.ui-slider-pips .ui-slider-pip {
    width: 2em;
    height: 1em;
    line-height: 1em;
    position: absolute;
    font-size: 0.8em;
    color: #999;
    overflow: visible;
    text-align: center;
    top: 15px;
    left: 20px;
    margin-left: -1em;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


/* little pip/line position & size */

.ui-slider-pips .ui-slider-line {
    background: #999;
    width: 1px;
    height: 5px;
    position: absolute;
    left: 50%;
}


/* the text label postion & size */


/* it overflows so no need for width to be accurate */

.ui-slider-pips .ui-slider-label {
    position: absolute;
    top: 8px;
    left: 50%;
    margin-left: -1em;
    width: 2em;
}


/* make it easy to see when we hover a label */

.ui-slider-pip:hover .ui-slider-label {
    color: black;
    font-weight: bold;
}


/* VERTICAL */


/* vertical slider needs right-margin, not bottom */

.ui-slider-vertical.ui-slider-pips {
    margin-bottom: 1em;
    margin-right: 2em;
}


/* align vertical pips left and to right of the slider */

.ui-slider-vertical.ui-slider-pips .ui-slider-pip {
    text-align: left;
    top: auto;
    left: 20px;
    margin-left: 0;
    margin-bottom: -0.5em;
}


/* vertical line/pip should be horizontal instead */

.ui-slider-vertical.ui-slider-pips .ui-slider-line {
    width: 3px;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 0;
}

.ui-slider-vertical.ui-slider-pips .ui-slider-label {
    top: 50%;
    left: 0.5em;
    margin-left: 0;
    margin-top: -0.5em;
    width: 2em;
}


/* Всплывающие горизонтальные подсказки для бегунка */


/* remove the godawful looking focus outline on handle and float */

.ui-slider-float .ui-slider-handle:focus,
.ui-slider-float .ui-slider-handle:focus .ui-slider-tip,
.ui-slider-float .ui-slider-handle:focus .ui-slider-tip-label {
    outline: none;
}


/* style tooltips on handles and on labels */


/* also has a nice transition */

.ui-slider-float .ui-slider-tip,
.ui-slider-float .ui-slider-tip-label {
    position: absolute;
    visibility: hidden;
    top: -40px;
    display: block;
    width: 34px;
    margin-left: -18px;
    left: 50%;
    height: 20px;
    line-height: 20px;
    background: white;
    border-radius: 3px;
    border: 1px solid #888;
    text-align: center;
    font-size: 12px;
    opacity: 0;
    color: #333;
    -webkit-transition-duration: 200ms, 200ms, 0;
    transition-duration: 200ms, 200ms, 0;
    -webkit-transition-property: opacity, top, visibility;
    transition-property: opacity, top, visibility;
    -webkit-transition-delay: 0, 0, 200ms;
    transition-delay: 0, 0, 200ms;
}


/* show the tooltip on hover or focus */


/* also switch transition delay around */

.ui-slider-float .ui-slider-handle:hover .ui-slider-tip,
.ui-slider-float .ui-slider-handle:focus .ui-slider-tip,
.ui-slider-float .ui-slider-pip:hover .ui-slider-tip-label {
    opacity: 1;
    top: -30px;
    visibility: visible;
    -webkit-transition-delay: 200ms, 200ms, 0;
    transition-delay: 200ms, 200ms, 0;
}


/* put label tooltips below slider */

.ui-slider-float .ui-slider-pip .ui-slider-tip-label {
    top: 42px;
}

.ui-slider-float .ui-slider-pip:hover .ui-slider-tip-label {
    top: 32px;
    font-weight: normal;
}


/* give the tooltip a css triangle arrow */

.ui-slider-float .ui-slider-tip:after,
.ui-slider-float .ui-slider-pip .ui-slider-tip-label:after {
    content: " ";
    width: 0;
    height: 0;
    border: 5px solid rgba(255, 255, 255, 0);
    border-top-color: rgba(255, 255, 255, 1);
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -5px;
}


/* put a 1px border on the tooltip arrow to match tooltip border */

.ui-slider-float .ui-slider-tip:before,
.ui-slider-float .ui-slider-pip .ui-slider-tip-label:before {
    content: " ";
    width: 0;
    height: 0;
    border: 5px solid rgba(255, 255, 255, 0);
    border-top-color: #888;
    position: absolute;
    bottom: -11px;
    left: 50%;
    margin-left: -5px;
}


/* switch the arrow to top on labels */

.ui-slider-float .ui-slider-pip .ui-slider-tip-label:after {
    border: 5px solid rgba(255, 255, 255, 0);
    border-bottom-color: rgba(255, 255, 255, 1);
    top: -10px;
}

.ui-slider-float .ui-slider-pip .ui-slider-tip-label:before {
    border: 5px solid rgba(255, 255, 255, 0);
    border-bottom-color: #888;
    top: -11px;
}


/* FLOATING VERTICAL TOOLTIPS */


/* tooltip floats to left of handle */

.ui-slider-vertical.ui-slider-float .ui-slider-tip,
.ui-slider-vertical.ui-slider-float .ui-slider-tip-label {
    top: 50%;
    margin-top: -11px;
    width: 34px;
    margin-left: 0px;
    left: -60px;
    color: #333;
    -webkit-transition-duration: 200ms, 200ms, 0;
    transition-duration: 200ms, 200ms, 0;
    -webkit-transition-property: opacity, left, visibility;
    transition-property: opacity, left, visibility;
    -webkit-transition-delay: 0, 0, 200ms;
    transition-delay: 0, 0, 200ms;
}

.ui-slider-vertical.ui-slider-float .ui-slider-handle:hover .ui-slider-tip,
.ui-slider-vertical.ui-slider-float .ui-slider-handle:focus .ui-slider-tip,
.ui-slider-vertical.ui-slider-float .ui-slider-pip:hover .ui-slider-tip-label {
    top: 50%;
    margin-top: -11px;
    left: -50px;
}


/* put label tooltips to right of slider */

.ui-slider-vertical.ui-slider-float .ui-slider-pip .ui-slider-tip-label {
    left: 47px;
}

.ui-slider-vertical.ui-slider-float .ui-slider-pip:hover .ui-slider-tip-label {
    left: 37px;
}


/* give the tooltip a css triangle arrow */

.ui-slider-vertical.ui-slider-float .ui-slider-tip:after,
.ui-slider-vertical.ui-slider-float .ui-slider-pip .ui-slider-tip-label:after {
    border: 5px solid rgba(255, 255, 255, 0);
    border-left-color: rgba(255, 255, 255, 1);
    border-top-color: transparent;
    position: absolute;
    bottom: 50%;
    margin-bottom: -5px;
    right: -10px;
    margin-left: 0;
    top: auto;
    left: auto;
}

.ui-slider-vertical.ui-slider-float .ui-slider-tip:before,
.ui-slider-vertical.ui-slider-float .ui-slider-pip .ui-slider-tip-label:before {
    border: 5px solid rgba(255, 255, 255, 0);
    border-left-color: #888;
    border-top-color: transparent;
    position: absolute;
    bottom: 50%;
    margin-bottom: -5px;
    right: -11px;
    margin-left: 0;
    top: auto;
    left: auto;
}

.ui-slider-vertical.ui-slider-float .ui-slider-pip .ui-slider-tip-label:after {
    border: 5px solid rgba(255, 255, 255, 0);
    border-right-color: rgba(255, 255, 255, 1);
    right: auto;
    left: -10px;
}

.ui-slider-vertical.ui-slider-float .ui-slider-pip .ui-slider-tip-label:before {
    border: 5px solid rgba(255, 255, 255, 0);
    border-right-color: #888;
    right: auto;
    left: -11px;
}


/* SELECTED STATES */


/* Comment out this chuck of code if you don't want to have
        the new label colours shown */

.ui-slider-pips .ui-slider-pip-selected-initial,
.ui-slider-pips .ui-slider-pip-selected-initial-first,
.ui-slider-pips .ui-slider-pip-selected-initial-second {
    font-weight: bold;
    color: #14CA82;
}

.ui-slider-pips .ui-slider-pip-selected-initial {
}

.ui-slider-pips .ui-slider-pip-selected-initial-first {
}

.ui-slider-pips .ui-slider-pip-selected-initial-second {
    color: #1897C9;
}

.ui-slider-pips .ui-slider-pip-selected,
.ui-slider-pips .ui-slider-pip-selected-first,
.ui-slider-pips .ui-slider-pip-selected-second {
    font-weight: bold;
    color: #FF7A00;
}

.ui-slider-pips .ui-slider-pip-selected {
}

.ui-slider-pips .ui-slider-pip-selected-first {
}

.ui-slider-pips .ui-slider-pip-selected-second {
    color: #E70081;
}


/* Бегунок для товаров которые продаются частями - конец */


/* Модификаторы заказа - начало */

.modifier-checkbox {
    background: #f5f5f5;;
    padding: 5px 8px 5px 8px;
    border-bottom: none;
    margin: 0;
    border-radius: 4px;
}

.modifier-checkbox.active {
    background: #f5f5f5;
}

.product-modifier-group {
    margin: 5px 0;
}

.modifier-group-header {
    font-size: 16px;
}

.product-modifier-group .radio {
    background: #f9f9f9;
    border-bottom: none;
    margin: 0;
    border-radius: 4px;
    padding: 5px 8px 5px 8px;
    margin: 5px 0;
}

.product-modifier-group .radio label {
}

.mz-right-all {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}

.mz-right {
}

.mz-img {
    margin-right: 10px;
}

.mz-right-header {
    font-weight: 500;
}

.mz-description {
    font-size: 12px;
}

.mz-description p:last-child {
    margin-bottom: 0;
}


/* Модификаторы заказа - конец */


/* Общее начало */

.taright {
    text-align: right;
}

.fz18 {
    font-size: 18px;
}

.margin--7 {
    margin: 0 -7px !important;
}

.label {
    font-weight: 400;
}

th {
    font-weight: 500;
}


/* Общее конец */


/* Доп. комплектации начало */

.dop-products {
    margin: 0;
    padding: 0;
    list-style: none;
}

.dop-products li {
    display: table;
    width: 100%;
    clear: both;
    padding-bottom: 10px;
    margin-bottom: 10px;
}


/* Доп. комплектации конец */


/* Комплекты начало */

.kits {
}

.kits-summ {
    background: #fafafa;
    padding: 10px;
    display: table;
    width: 100%;
    clear: both;
    font-size: 18px;
    border: 1px solid #e3e4e4;
    border-bottom: none;
}

.kits-items-all {
    display: block;
    float: left;
    padding-top: 10px;
    padding-left: 8px;
    font-size: 19px;
    font-weight: 500;
}

.kits-items-summ,
.kits-items-buy {
    display: block;
    float: right;
}

.kits-items-buy .btn {
    text-transform: uppercase;
}

.kits-items-summ {
    padding-top: 3px;
    color: #6b6966;
    margin-right: 30px;
}

.buy-price-copy {
    display: inline;
    color: #333;
    font-size: 26px;
    font-weight: 700;
}

.buy-price-copy span {
    font-size: 18px;
}

.kits-options-hader-blue {
    font-size: 17px;
    color: #333;
    text-transform: uppercase;
    background: #FFF;
    padding: 18px 18px 10px;
    font-weight: 700;
    border-top: 1px solid #e3e4e4;
    /*border-left: 1px solid #e3e4e4;
    border-right: 1px solid #e3e4e4;*/
}

.kits-options-items {
    background: #fafafa;
    padding: 0px;
    border-bottom: 1px solid #e3e4e4;
}

.radioflex {
    background: #FFF;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    clear: both;
    margin: 0 0 0 0 !important;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    /*border-right: 1px solid #e3e4e4;*/
    border-top: 1px solid #e3e4e4;
    /*border-left: 1px solid #e3e4e4;*/
    padding-left: 2px;
}

.radioflex.active {
    background: #f5f5f5 !important;
    border-left: 3px solid #e56425;
    padding-left: 0;
}

.radioflex label {
    width: 100%;
}

.var-radio {
    padding: 32px 0 0 15px;
    font-size: 32px;
    display: block;
    float: left;
}

.varimage {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    float: left;
    width: 74px;
    text-align: center;
    height: 74px;
    padding: 2px;
    /* border:1px solid #E6E6E6; */
    margin: 4px 15px;
    background: #fff;
}

.varimage img {
    display: inline-block;
    vertical-align: middle;
}

.radioname {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    font-size: 15px;
    width: 448px;
    float: left;
    padding: 4px 15px 4px 0;
    min-height: 74px;
}

.radioname span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
}

.radioprice {
    display: block;
    float: right;
    margin-right: 15px;
    font-size: 20px;
    font-weight: 700;
    text-align: right;
    padding-top: 25px;
}

.kits-extras-header {
    font-size: 17px;
    color: #333;
    text-transform: uppercase;
    background: #FFF;
    padding: 0 15px 10px;
    font-weight: 700;
    /*border-left: 1px solid #e3e4e4;
    border-right: 1px solid #e3e4e4;*/
}

.kits-extras-items {
    display: block;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    /*padding: 0;*/
}

.kits-extras-items .kits-extras-block:first-child .kits-extras-block-header {
    padding-top: 0;
}

.kits-extras-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    display: -webkit-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.kits-extras-block:nth-child(n+2) {
    margin-top: 15px;
}

.kits-extras-block-header {
    font-size: 13px;
    color: #333;
    text-decoration: none;
    /*border-left: 1px solid #e3e4e4;
    border-right: 1px solid #e3e4e4;*/
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    /*padding-top: 18px;*/
}

.kits-extras-block-header span {
}

.kits-extras-block-header-white {
    background: #fff;
    border-right: 1px solid #e3e4e4;
    border-top: 1px solid #e3e4e4;
    padding: 8px 22px 8px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
/*    flex-shrink: 0;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    width: 100%;
    max-width: 200px;*/
}

.kits-extras-goto-category {
    display: inline-block;
    /*width: 100%;*/
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
    padding: 8px 22px 8px;
    border-bottom: 1px solid #e3e4e4;
}

.kits-extras-goto-category a.btn {
    border-radius: 0;
    background: none;
    color: #333;
    border: none;
    font-size: 12px;
}

.kits-extras-goto-category:hover a.btn {
    color: #d6341d;
    background: none;
    box-shadow: none;
    /* border-bottom: 1px solid #e3e4e4; */
}

.kits-extras-block-inner {
}

.kits-ul {
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}


.kits-ul .product-visible-on {
    display: block;
}

.kits-ul .product-visible-off {
    display: none;
}

.kits-ul .variant-hidden {
    display: none !important;
}

.kits-ul .btn-show-more-complects-products {
    background-color: #ccc;
    font-size: 12px;
    color: #333;
    border: none;
    border-radius: 0;
}

.kits-ul .btn-show-more-complects-products:hover,
.kits-ul .btn-show-more-complects-products:focus,
.kits-ul .btn-show-more-complects-products:active,
.kits-ul .btn-show-more-complects-products:active:focus,
.kits-ul .btn-show-more-complects-products:active:hover {
    background-color: #e3e4e4;
    color: #333;
    border: none;
}

.kits-ul .doplistitem:last-child {
    border-bottom: 1px solid #e3e4e4;
}

.doplistitem {
    background: #FFF;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    clear: both;
    margin: 0 0 0 0 !important;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    /*border-right: 1px solid #e3e4e4;*/
    border-top: 1px solid #e3e4e4;
    border-bottom: 1px solid #e3e4e4;
    /*border-left: 1px solid #e3e4e4;*/
    padding-left: 2px;
}

.doplistitem:first-child {
    border-top: none;
}

.doplistitem:hover {
    cursor: pointer !important;
}

.doplistitem-price span,
.radioprice-inner span {
    font-size: 14px;
}

.relatedescription {
    font-size: 12px;
    color: #747474;
}

.relatedescription p:last-child {
    margin-bottom: 0;
}

.doplistitem.active {
    background: #f5f5f5 !important;
    border-left: 3px solid #e56425;
    padding-left: 0;
}

.doplistitem label {
    margin: 0 !important;
    width: 100%;
}

.doplistitem-check {
    display: block;
    float: left;
    width: 49px;
    font-size: 14px;
    text-align: center;
}

.doplistitem-check input {
    height: 18px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    margin-top: 32px;
}

.doplist-image {
    position: relative;
    float: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 74px;
    width: 74px;
    padding: 1px;
    margin: 4px 15px 4px 0;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    background: #FFF;
}

.doplist-image img {
    display: inline-block;
    vertical-align: middle;
}

.doplistitem-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 14px;
    width: 60%;
    float: left;
    padding: 4px 4px 4px 0;
    min-height: 74px;
}

#complect .doplistitem-center {
    width: 75%;
}

.doplistitem-name-block {
    font-size: 15px;
}

.doplistitem-description {
    font-size: 12px;
    color: #6E6E6E;
}

.doplistitem-description p:last-child {
    margin-bottom: 0;
}

.doplistitem-right {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: right;
    float: right;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    font-size: 20px;
    font-weight: 700;
    padding-top: 25px;
    margin-top: 15px;
}

.doplistitem-right-count {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: right;
    float: right;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    font-size: 20px;
    font-weight: 700;
    margin-right: 15px;
    padding-top: 25px;
    margin-top: 15px;
}

.doplistitem-variants {
    width: 60%;
    margin-bottom: 5px;
}


.doplistitem--alt .doplistitem-check {
    width: 20px;
}

.doplistitem--alt .doplist-image {
    width: 50px;
    height: 50px;
    margin-top: 14px;
    margin-right: 25px;
}

.doplistitem--alt .doplist-image a {
    width: 50px;
    height: 50px;
    line-height: 50px;
}

.doplistitem--alt .doplist-image img {
    max-width: 100%;
    max-height: 100%;
}

.doplistitem--alt .doplistitem-right {
    padding-top: 0;
}

.doplistitem--alt .doplistitem-right-count {
    padding-top: 0;
}



/* Комплекты конец */


/* Мелочи в карточке товара начало */

.char-table tr td {
    padding: 8px 0 !important;
}

.block-class {
    display: block;
    padding: 15px 0;
}

.block-class.hit-orange {
    position: relative;
    background-color: #e76a41;
    /* cc6a45 */
    margin-left: -1500px;
    margin-right: -1500px;
    padding-top: 20px;
    padding-bottom: 45px;
    min-height: 0;
    margin-bottom: 30px;
}

.block-class.hit-orange:before,
.block-class.hit-orange:after {
    position: absolute;
    left: 0;
    right: 0;
    height: 7px;
    background: url("../img/bg-orange-waves.png") repeat-x;
    content: "";
}

.block-class.hit-orange:before {
    top: -6px;
    background-position: center 0;
}

.block-class.hit-orange:after {
    bottom: -6px;
    background-position: center -7px;
}

.block-class.hit-orange legend,
.block-class.hit-orange .group-products-filter a {
    color: #fff;
}

.block-class.hit-orange .group-products-filter {
    margin-left: -6px;
}

.block-class.hit-orange .group-products-filter li {
    border-color: transparent !important;
    background-color: transparent !important;
}

.block-class.hit-orange .legend-a {
    color: #fff !important;
}

.block-class.hit-orange .plitka-item {
    padding: 0px 6px 0px 6px !important;
}

/*
.block-class.hit-orange .plitka-item {
	background-color: #fff;
}
*/

#wrap-new-block-main {
    background: #f8f5ee;
    position: relative;
    margin: 20px 0 35px;
    width: 100vw;
    margin-left: -50vw;
    left: 50%;
}

.desc-block-header {
    margin-bottom: 15px;
}

.product-description-top-brand {
    align-self: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    text-align: center;
    margin-right: 10px;
}

.product-description-top-brand a {
    display: inline-block;
}

.product-description-top-brand img {
    max-width: 100%;
}

.brand-area {
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    justify-content: space-around;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-around;
    -ms-flex-pack: space-around;
    align-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    margin-top: 20px;
}

.delivery-days {
    color: #333;
    font-size: 13px;
    display: block;
}


/* Разделение типов в группе товаров начало */

.group-products-filter {
    margin-bottom: 0;
    margin-left: -5px;
    margin-right: -5px;
    text-align: justify;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    justify-content: space-between;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
}

.one-category {
    padding-left: 5px;
    padding-right: 5px;
    flex-shrink: 0;
    flex-grow: 1;
    text-align: center;
}

.group-products-filter-element {
    padding: 5px 5px !important;
    border: 1px dotted #ccc;
    border-radius: 5px;
    background-color: #fafafa;
    margin-bottom: 20px;
}

.group-products-filter-element:hover {
    border-color: #d6341d;
}

.group-products-filter-element a {
    text-decoration: none;
    color: #333;
    font-size: 16px;
    border: none;
}

.group-products-filter-element:hover a {
    border: none;
    text-decoration: none;
    color: #d6341d;
}

.group-products-filter-element.active {
    background: #fafafa;
    border-color: #d6341d;
}

.group-products-filter-element.active a {
    color: #d6341d;
    border: none;
}




.group-products-filter--products {
    flex-wrap: wrap;
    white-space: normal;
    overflow: visible;
}

.group-products-filter--products {
    margin-left: -5px;
    margin-right: -5px;
}

.group-products-filter--products .group-products-filter-element {
    margin-bottom: 0;
}

.group-products-filter--products .one-category {
    padding-bottom: 20px;
}

.group-products-filter--products .one-category:first-child {
    padding-left: 5px;
}

.group-products-filter--products .one-category:last-child {
    padding-right: 5px;
}

.group-products-wrapper {
    //min-height: 94px;
    margin-bottom: 20px;
}

.group-products-wrapper .above-collapse {
    max-height: 49px;
    overflow: hidden;
}


/* Разделение типов в группе товаров конец */



@media (min-width: 992px) {
    .products-bottom #viewedproducts {
        margin-left: -9999px;
        margin-right: -9999px;
    }
}

.products-bottom #viewedproducts .container {
    padding-left: 0!important;
    padding-right: 0!important;
}

/* Блок Данный товар вы можете заказать по телефону */

.tovar-phone {
    display: block;
    margin: 15px;
}


/* Блок см. также конец */

.owl-controls {
    position: static;
}

.plitka--owl {
    display: block;
    padding: 0;
    margin: 0 -7px;
    clear: both;
    white-space: nowrap;
    font-size: 0;
    overflow: hidden;
}

.plitka--owl .plitka-item {
    float: none;
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    font-size: 14px;
    width: 100%;
}

.plitka--owl .owl-tiles.inited .plitka-item {
    display: block;
}

.plitka--owl .owl-tiles .owl-controls .owl-nav .owl-prev,
.plitka--owl .owl-tiles .owl-controls .owl-nav .owl-next {
    top: 183px;
}

.plitka--owl:hover {
    position: relative;
    z-index: 5;
    /*margin-bottom: -190px;*/
}

.plitka--owl:hover .owl-stage-outer {
    /*padding-bottom: 190px;*/
}

.owl-tiles .owl-item img {
    display: inline-block;
    width: auto;
}

.owl-tiles .owl-item {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1.0, 1.0);
}

.owl-tiles .owl-controls .owl-nav .owl-prev,
.owl-tiles .owl-controls .owl-nav .owl-next {
    display: block;
    position: absolute;
    top: 50%;
    z-index: 5;
    margin-top: -33px;
    width: 40px;
    height: 66px;
    background-color: #e3e3e3;
    cursor: pointer;
    -webkit-transition: all ease .3s;
    transition: all ease .3s;
    text-align: left;
    text-indent: -9999px;
    opacity: 1;
    cursor: pointer;
}

.owl-tiles .owl-controls .owl-nav .owl-prev:hover,
.owl-tiles .owl-controls .owl-nav .owl-next:hover {
    background-color: #d2d2d2;
}

.owl-tiles .owl-controls .owl-nav .owl-prev.disabled,
.owl-tiles .owl-controls .owl-nav .owl-next.disabled {
    opacity: 0.25;
    cursor: default;
}

.owl-tiles .owl-controls .owl-nav .owl-prev {
    background: url("../img/arrow-left.png") 50% 50% no-repeat #e3e3e3;
    top: 50%;
    margin-top: -15px;
    right: auto;
    z-index: 2;
}

.owl-tiles .owl-controls .owl-nav .owl-next {
    background: url("../img/arrow-right.png") 50% 50% no-repeat #e3e3e3;
    top: 50%;
    margin-top: -15px;
    left: auto;
    right: 0px;
    z-index: 2;
}

.owl-tiles--nav-rt .owl-controls .owl-nav .owl-prev {
    top: 0;
    margin: -40px 0 0;
    left: auto;
    right: 45px;
}

.owl-tiles--nav-rt .owl-controls .owl-nav .owl-next {
    top: 0;
    margin: -40px 0 0;
    right: 9px;
}

#viewedproducts .owl-tiles .owl-controls .owl-nav .owl-prev,
#viewedproducts .owl-tiles .owl-controls .owl-nav .owl-next {
    position: absolute;
    display: block;
    height: 30px;
    width: 30px;
    background-color: white;
    text-decoration: none;
    border-bottom: none;
    text-align: left;
    text-indent: -9999px;
    opacity: 1;
    cursor: pointer;
    -webkit-transition: opacity 0.3s, background-color 0.3s, color 0.3s;
    transition: opacity 0.3s, background-color 0.3s, color 0.3s;
}

#viewedproducts .owl-prev.disabled,
#viewedproducts .owl-next.disabled {
    opacity: 0.25;
    cursor: default;
}

#viewedproducts .owl-prev {
    background: url("../img/icon-arrow-prev.png") 50% 50% no-repeat, #e3e3e3 !important;
    top: 0;
    margin: -40px 0 0 !important;
    left: auto;
    right: 45px;
    z-index: 2;
}

#viewedproducts .owl-next {
    background: url("../img/icon-arrow-next.png") 50% 50% no-repeat, #e3e3e3 !important;
    top: 0;
    margin: -40px 0 0 !important;
    right: 9px;
    z-index: 2;
}

#viewedproducts .owl-prev.disabled,
#viewedproducts .owl-next.disabled {
    opacity: 0.25 !important;
    cursor: default;
}

.kod-i-reiting:after {
    clear: both;
    display: table;
    content: '';
}

.kodtovara {
    display: block;
    float: left;
    margin-right: 15px;
    color: #333;
    font-size: 12px;
}

.property-img {
    vertical-align: top;
}

.property-img {
    display: inline-block;
    margin-right: 2px;
    margin-bottom: 2px;
    line-height: 16px;
}

legend {
    display: block;
    width: 100%;
    padding: 0;
    margin: 13px 0;
    /*font-size: 21px;
    font-weight: 700;*/
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    line-height: inherit;
    color: #1c1c1c;
    border: 0;
    border-bottom: none;
}

.legend-a {
    color: #333 !important;
}

.main-slide p {
    margin-bottom: 0;
}

.main-slide {
    margin-bottom: 0px;
}

.label {
    border-radius: 0;
}

#breadcrumbs {
    padding: 12px 0 8px;
    min-height: 35px;
}

#breadcrumbs .breadcrumb li:last-child > span, #breadcrumbs .breadcrumb li:last-child > a {
	color: #ea7352;
	font-weight: bold;
	border: 1px dotted #ea7352; 
	padding: 3px;  
	border-radius: 8px;
}

.breadcrumb {
    padding: 0;
    margin: 0;
    list-style: none;
    background-color: transparent;
    border-radius: 0px;
}

.breadcrumb {
    color: #333;
    font-size: 11px;
}

.breadcrumb a {
    color: #333;
    font-size: 11px;
}

.breadcrumb > .active {
    color: #333;
    font-size: 11px;
}

.brands-grey {
    background: #f8f5ee;
    padding: 30px 0;
}

.brands-header {
    font-size: 21px;
    font-weight: 700;
    color: #333;
    text-transform: uppercase;
    margin-bottom: 5px;
}

#brands .head {
    margin-bottom: 10px;
    display: block;
}

.reasons {
    background: #ddedf4;
    padding: 44px 0;
}

.reason2 {
    display: block;
    width: 231px;
    font-size: 19px;
    height: 109px;
    padding: 14px 12px 12px 82px;
    background: url("../img/library.jpg") no-repeat top;
}

.reason2 a {
    color: #FFF;
}

.reason2 a:hover {
    text-decoration: underline;
}

.cat-counter {
    display: block;
    float: left;
    padding: 7px 4px 6px 0;
    position: relative;
    font-size: 13px;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    background-color: #eeeeee !important;
    border-color: #dddddd !important;
    color: #333 !important;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
    color: #333;
}

.pagination > li > a,
.pagination > li > span {
    color: #333;
    font-size: 18px;
    white-space: nowrap;
}

/*
.label-default {
    background-color: #c52411;
}
*/

.label {
    font-size: 11px;
    padding: 2px 6px 2px;
}

.kodtovara {
    display: inline-block;
    padding: 4px 0 0;
    font-weight: 500;
    font-size: 12px;
}

.popover {
    /* border-color:#e56425 !important; */
}

.buy-side {
    margin-top: 50px;
    /*border: 1px solid #e3e4e4;*/
    overflow: hidden;
}


/* Блок распродажа и уценненые товары слева начало */

.left-sale-block {
    margin-bottom: 20px;
}

.left-sale-block a {
    display: block;
    padding: 11px 31px 11px 11px;
    line-height: 1;
    border: 1px solid #e56425;
    background: url("../img/sale.png") no-repeat 203px 6px;
    font-size: 14px;
    color: #333;
    text-transform: uppercase;
    font-weight: 500;
}

.left-sale-block a:last-child {
    border-top: none;
}

.left-sale-block a:hover {
    background: url("../img/sale.png") no-repeat 203px 6px #f3f3f3;
}


/* Блок распродажа и уценненые товары слева конец */

.menu586 {
    background: url("../img/white-arrow.png") no-repeat 215px 12px #e56425;
    color: #FFF;
    width: 236px;
    margin-left: -1px;
    padding-left: 21px;
    -webkit-transition: none;
    transition: none;
}

.menu586:after {
    background: none;
}

.menu586:hover {
    background: url("../img/white-arrow.png") no-repeat 215px 12px #cd5a23 !important;
    color: #FFF !important;
    width: 236px !important;
    margin-left: -1px !important;
    padding-left: 21px !important;
}


/* Табы в карточке товара начало */

.nav-tabs-wrapper2 {
    position: relative;
}

.nav-tabs-wrapper2:before,
.nav-tabs-wrapper2:after {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50px;
    z-index: 2;
    content: '';
    transition: all 0.2s;
    pointer-events: none;
}

.nav-tabs-wrapper2:before {
    left: 0;
    background: linear-gradient(to right, rgba(255,255,255, 1), rgba(255,255,255, 0));
}

.nav-tabs-wrapper2.scroll-in-start:before {
    opacity: 0;
}

.nav-tabs-wrapper2:after {
    right: 0;
    background: linear-gradient(to left, rgba(255,255,255, 1), rgba(255,255,255, 0));
}

.nav-tabs-wrapper2.scroll-in-end:after {
    opacity: 0;
}

.nav-tabs-wrapper {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    font-size: 0;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 10px;
    margin-bottom: -10px;
}

.nav-tabs-wrapper::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
}
/*
.nav-tabs-wrapper:before {
    position: absolute;
    bottom: 0;
    display: block;
    left: -2000px;
    right: -2000px;
    border-bottom: 3px solid #eee;
    content: "";
}

.nav-tabs-wrapper:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 3px;
    width: 15px;
    background-color: transparent;
    background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0), #fff);
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
    content: "";
    z-index: 2;
}*/

.nav-tabs {
    position: relative;
    padding-bottom: 0;
    border-bottom: none;
    display: inline-block;
    vertical-align: top;
    min-width: 100%;
}

.nav-tabs:before {
    position: absolute;
    bottom: 0;
    display: block;
    left: 0px;
    right: 0px;
    border-bottom: 3px solid #eee;
    content: "";
}

.nav-tabs-wrapper .nav-tabs:after {
    display: none;
}

.nav-tabs-wrapper .nav-tabs {
    background: transparent;
    z-index: 1;
}

.nav-tabs-wrapper .nav-tabs > li {
    float: none;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 0;
}

.nav-tabs-wrapper-border {
    position: absolute;
    bottom: 0;
    background: #fff;
    left: -2000px;
    right: -2000px;
    border-bottom: 3px solid #eee;
    content: "";
}

.nav-tabs > li {
    margin-bottom: -3px;
}

.nav-tabs > li > a {
    border-radius: 0px 0px 0 0;
    padding: 22px;
    font-size: 15px; /*17px;*/
    font-weight: 700;
    color: #4d4d4d;
    text-transform: uppercase;
    border: none;
    border-bottom: 3px solid transparent;
}

.nav-tabs > li > a:hover {
    background: #fafafa;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
    color: #1c1c1c;
    border: none;
    border-bottom: 3px solid #c52411;
    cursor: default;
    background: #fafafa;
}

.modal-dialog {
    z-index: 1100;
}

#map {
    margin-bottom: 15px !important;
}

label input + span:after {
    content: "";
    position: absolute;
    left: -1px;
    top: -1px;
    background: #c52411 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAHCAYAAAAxrNxjAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkJBNUIwNTYwQUE2NTExRTVCQjlDOTIzMzZDMjg5MEE5IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkJBNUIwNTYxQUE2NTExRTVCQjlDOTIzMzZDMjg5MEE5Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QkE1QjA1NUVBQTY1MTFFNUJCOUM5MjMzNkMyODkwQTkiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QkE1QjA1NUZBQTY1MTFFNUJCOUM5MjMzNkMyODkwQTkiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5F2mv9AAAAXUlEQVR42mL89+8fAwFQAMT+DCCFeHABEP8F4jwQJxGIlXAoAoE8sK1A4iQQP0RTXAhVlAsTAxH8QHwaiB9BFRdBFeUg2wBjgBSfAuJ32BQhK4QpPgbE2dg8BhBgAPdxxmCWhpLBAAAAAElFTkSuQmCC) 3px 5px no-repeat;
    width: 16px;
    height: 16px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.3s, visibility 0s 0.3s;
    transition: opacity 0.3s, visibility 0s 0.3s;
}

label input:checked + span:after {
    opacity: 1;
    visibility: visible;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

label input + span.radio:after {
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    margin: -4px 0 0 -4px;
    background-color: #e56425;
    border-radius: 50%;
}

label span.checkbox,
label span.radio {
    border: 1px solid #d2d2d2;
    width: 16px;
    height: 16px;
    float: left;
    background: #fff;
    display: inline-block;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

label span.checkbox:hover,
label span.radio:hover {
    border-color: #999;
}

label span.radio {
    border-radius: 50%;
}

/*
.panel-body input[type="checkbox"],
.panel-body input[type="radio"] {
    width: 0px;
    height: 0px;
    opacity: 0;
}
*/

.filterbox__inner input[type="checkbox"],
.filterbox__inner input[type="radio"] {
    width: 0px!important;
    height: 0px!important;
    opacity: 0!important;
}

.filterbox__inner .radio label,
.filterbox__inner .checkbox label {
    padding-left: 0px;
    line-height: 1.1;
    display: table;
    clear: both;
    width: 100%;
}

/**/

/*
.panel-body .radio label,
.checkbox label {
    padding-left: 0px;
    line-height: 1.1;
    display: table;
    clear: both;
    width: 100%;
}
*/

.panel-body li.doplistitem.active span:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: url("../img/unnell/2.png") no-repeat;
    width: 16px;
    height: 16px;
}

.panel-body .property-img {
    /* margin-left: -10px; */
}

.un_f1 {
    overflow: hidden;
}

.un_f11,
.un_f2 {
    font-size: 13px;
    text-transform: uppercase;
    width: 358px;
    height: 27px;
    margin: 0 auto;
    text-align: center;
    background: #3da8db;
    line-height: 27px;
    color: #fff;
    cursor: pointer;
    margin-bottom: 20px;
    margin-top: -21px;
}

.un_f11 .fa,
.un_f2 .fa {
    font-size: 18px;
}

.f2 {
    /* float: right; */
}

.un_f3 {
    margin-top: 22px;
}

.un_f3.activ {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(-1%, rgba(255, 255, 255, 0)), color-stop(21%, rgba(255, 255, 255, 1)));
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) -1%, rgba(255, 255, 255, 1) 21%);
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) -1%, rgba(255, 255, 255, 1) 21%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) -1%, rgba(255, 255, 255, 1) 21%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
    bottom: -2px;
    height: 27px;
    padding-bottom: 7px;
    /* position: absolute; */
    text-align: center;
    /* width: 100%; */
    margin-top: -31px;
    position: relative;
    padding-top: 42px;
    margin-bottom: 20px;
}

#viewedproducts .owl-next {
    margin: -40px -4px 0 0 !important;
}

.tech-menu:hover .tech-menu__title {
    background: url("../img/unnell/3.png") 200px 21px no-repeat;
}

.varimage a,
.doplist-image a {
    display: block;
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    cursor: pointer;
}

.varimage a:before,
.doplist-image a:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(230, 109, 49, 0.71);
    content: "";
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 0.3s, visibility 0s 0.3s;
    transition: opacity 0.3s, visibility 0s 0.3s;
    z-index: 1;
}

.varimage a:after,
.doplist-image a:after {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -20px 0 0 -20px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-family: FontAwesome;
    font-size: 28px;
    color: #fff;
    content: "\f00e";
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 0.3s, visibility 0s 0.3s;
    transition: opacity 0.3s, visibility 0s 0.3s;
    z-index: 1;
}

.varimage a:hover:before,
.doplist-image a:hover:before {
    visibility: visible;
    opacity: 1;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

.varimage a:hover:after,
.doplist-image a:hover:after {
    visibility: visible;
    opacity: 1;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

.popover {
    border: 1px solid #e56425;
}

.popover-show-on-scroll.fade {
    -webkit-transition: opacity 0.75s linear;
    transition: opacity 0.75s linear;
}

.popover.right > .arrow {
    border-right-color: #e56425;
}

.popover > .arrow {
    border-left-color: #e56425 !important;
}

.un_f4 {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
}

.product-properties {
    height: 313px;
    overflow: hidden;
}

#relatedvariants-products-div {
    display: block;
    clear: both;
    width: 100%;
}

.un_f6 {
    background: #e3e4e4;
    font-size: 13px;
    text-align: center;
    text-transform: uppercase;
    padding: 5px 0;
    margin-top: 15px;
    cursor: pointer;
}

.un_f20 {
    background: #333;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    padding: 5px 0;
    margin-top: 15px;
    cursor: pointer;
    color: #fff;
}

.un_f20:hover {
    background: #e3e4e4;
}

.kits .popover.left {
    width: 150px;
    z-index: 990;
}

.popover-content {
    font-family: Lato, Аrial, sans-serif;
}

.un_f8 {
    position: relative;
}

.un_f9 {
    height: 476px;
    overflow: hidden;
}

.un_f10 {
    margin-top: 6px;
}

.un_f11 {
    margin-top: -20px;
}

.un_f12 {
    display: none;
}

.un_f12 .order-stat-header {
    font-size: 20px;
    margin-top: 6px;
    text-indent: 20px;
}

.un_f12 .order-text {
    font-size: 12px;
    text-indent: 37px;
}


/*.tech-menu:hover .tech-menu__title {transform: rotate(180deg); } */


/* Угловая установка начало  */

.corner-installation {
    color: #1c1c1c;
    border: none;
    cursor: default;
    background: #fafafa;
    padding: 12px;
    margin-top: 12px;
}

.corner-installation p:last-child {
    margin-bottom: 0;
}


/* Угловая установка конец  */

#related #view {
    display: none;
}

.main-categories {
    padding-top: 0;
	margin-top: 50px;
	margin-bottom: 50px;
}


/* Прелоадер */

#preloader {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    overflow: visible;
    background: #333;
    opacity: 0.45;
    line-height: 100%;
    text-align: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: opacity 0.14s;
    transition: opacity 0.14s;
}


/* Текст главной страницы разбиваем на 2 колонки */

.main-text h1 {
    padding-bottom: 0;
}

.main-text .material-full {
    display: block;
    margin-bottom: 25px;
    font-size: 12px;
}


/* Категории на главной странице */

.categories-on-main {
    display: block;
    margin: 0 -10px;
}

.categories-column {
    width: 180px;
    display: block;
    float: left;
    margin: 10px;
}

.category-level1 {
    display: block;
    margin-bottom: 20px;
}

.category-level1 a {
    color: #333;
}

.category-level1 strong {
    font-weight: 500;
    font-size: 16px;
}

.category-level2 {
    font-size: 13px;
}

.category-level2 a {
    color: #6d6d6d;
}

.category-level2 a:hover {
    color: #333;
}

.category-level2 a:hover {
}

.expand-more-categories,
.collapse-more-categories {
    cursor: pointer;
    color: #333;
    background: #dcdcdc;
    display: inline-block;
    margin: 4px 0 0 0;
    font-size: 13px;
    padding: 3px 8px;
    opacity: 0.7;
}

.expand-more-categories:hover,
.collapse-more-categories:hover {
    opacity: 1;
}

.expand-more-popular-categories,
.collapse-more-popular-categories {
    color: #333;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500px;
    padding: 6px 16px;
    border: 2px solid #e3e4e4;
    margin-top: 15px;
    display: inline-block;
    -webkit-transition: background 0.3s easy;
    transition: background 0.3s easy;
}

.expand-more-popular-categories:hover,
.collapse-more-popular-categories:hover {
    background: #e3e4e4;
    color: #333;
}

.expansible-block {
    position: relative;
    max-height: 332px;
    overflow: hidden;
    -webkit-transition: max-height 0.3s;
    transition: max-height 0.3s;
}

.expansible-block.active {
    max-height: 9999px;
}

.expansible-block-inner {
    position: relative;
    padding-bottom: 54px;
    background-color: #fff;
}

.expansible-block-inner:before {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 55px;
    /*border-left: 1px solid #e3e4e4;
    border-right: 1px solid #e3e4e4;*/
    content: "";
    z-index: 1;
}

.kits-options-items .expansible-block-inner:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 54px;
    border-top: 1px solid #e3e4e4;
    content: "";
    z-index: 1;
}

.kits-extras-block .expansible-block {
    border-bottom: 1px solid #e3e4e4;
}

.expansible-block-toggler {
    position: absolute;
    bottom: 1px;
    left: 1px;
    right: 1px;
    height: 40px;
    background-color: #fff;
    text-align: center;
    /*display: none;*/
}

.expansible-block-toggler:before {
    position: absolute;
    top: -40px;
    height: 42px;
    left: 0;
    right: 0;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(80%, rgba(255, 255, 255, 1)));
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 80%);
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 80%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 80%);
    content: "";
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.expansible-block-toggler a {
    position: relative;
    display: inline-block;
    background-color: #3da8db;
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    font-family: Lato;
    text-align: center;
    padding: 5px 10px;
    min-width: 360px;
    line-height: 1.4;
    z-index: 1;
    text-decoration: none !important;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
}

.expansible-block-toggler a:hover {
    background-color: #51b7e8;
}

.expansible-block-toggler a:before {
    position: relative;
    top: -2px;
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 6px;
    margin-right: 10px;
    background: url("../img/bg-arrow-down-white-small.png") center center no-repeat;
    content: "";
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.expansible-block.active .expansible-block-toggler a:before {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.expansible-block.active .expansible-block-toggler:before {
    opacity: 0;
}

.product-code {
    margin-top: 35px;
    font-size: 13px;
    opacity: 0.4;
}

.new-block-main {
    margin-top: 25px;
}

.menu-brands {
    background: url("../img/white-arrow.png") no-repeat 215px 12px #5cb85c;
    color: #FFF;
    width: 236px;
    margin-left: -1px;
    padding-left: 21px;
    -webkit-transition: none;
    transition: none;
}

.menu-brands:hover {
    background: url("../img/white-arrow.png") no-repeat 215px 12px #468b46 !important;
    color: #FFF !important;
    width: 236px !important;
    margin-left: -1px !important;
    padding-left: 21px !important;
}

.brands-menu {
    /* Несколько колонок брендов в меню */
    -moz-column-count: 4;
    -moz-column-gap: 15px;
    -webkit-column-count: 4;
    -webkit-column-gap: 15px;
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    column-gap: 15px;
    margin-bottom: 30px;
}

.brands-menu-item {
    display: block;
    margin: 3px 0;
}

.brands-menu-item span {
    opacity: 0.5;
}

.modal-title {
    font-size: 17px;
    font-weight: 500;
}

.lazy-load {
    opacity: 0;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
}

.lazy-load-inited {
    opacity: 1;
}

.btn-brands {
    background: #5cb85c;
    color: #FFF !important;
}

.btn-brands:hover {
    background: #468b46;
    color: #FFF !important;
}


/* страница брендов начало */

.brand-letter {
    font-size: 30px;
    font-weight: 700;
    margin-top: 20px;
}

.brands-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.brands-list-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-basis: 112px;
    -ms-flex-preferred-size: 112px;
    flex-basis: 112px;
    margin: 0 8px 12px 0;
    padding: 2px;
    width: 100%;
    clear: both;
    border: 1px solid #E6E6E6;
    text-align: center;
    text-decoration: none;
}

.brands-list-image {
    min-height: 100px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.brands-list-image img {
}

.brands-list-name {
    font-size: 13px;
    margin: 5px 0 3px;
    font-weight: 500;
    text-decoration: none;
    color: #333;
    line-height: 1.2;
}


/* страница брендов конец */


/* Небольшая доработка советника яндекс-маркета начало */

/*body > div[id^='m'][class^='m'][class*=' m'] {
    position: absolute;
    margin-top: -9999px;
}*/


/* Небольшая доработка советника яндекс-маркета конец */

.external-modules {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

#payments-methods-modal-form .modal-body img {
    display: none;
}

#delivery-price-modal-form .modal-body img {
    display: none;
}

.topadress {
    font-size: 12px;
    color: #434343;
    line-height: 14px;
    padding: 0px 0 10px 0px;
    /* background: url("../img/maptop.png") no-repeat left 6px; */
}

.topadress a {
    color: #333;
    text-decoration: underline;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    background-color: #f5f1e8 !important;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
    background-color: #f5f1e8 !important;
}

.pagination > li:last-child > a, .pagination > li:last-child > span {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.pagination > li:first-child > a, .pagination > li:first-child > span {
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.letter {
    margin: 10px 0 10px 0 !important;
    font-weight: 500;
    font-size: 18px !important;
    display: block !important;
    opacity: 0.8;
}


.relatedvariants-products-toggle {
    cursor: pointer;
}

.payment-options {
    margin-top: 20px;
}

.payment-options-2 {
    margin-top: 0px;
}


.payment-options-icons
.payment-icon {
    display: inline-block;
    margin: 10px 6px 5px 0px;
    float: left;
}

.payment-icon-2 {
    margin: 8px 0px 0px 0px;
    display: inline-block;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.05);
}

.payment-icon-2 img {
    height: 33px;
    border-radius: 2px;
    -webkit-transition: 0.15s;
    transition: 0.15s;
}


.payment-icon-check {
    display: inline-block;
    margin: 10px 0px 5px 0px !important
    !;
}

.payment-icon img {
    height: 30px;
    border-radius: 2px;
    -webkit-transition: 0.15s;
    transition: 0.15s;
}

.payment-icon img:hover {
    height: 30px;
    border-radius: 2px;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
    -webkit-transition: 0.15s;
    transition: 0.15s;
}

.vygodaprod {
    text-align: center;
}

.vygodaprod {
    /*padding: 0px 0px 1px 0px;*/
    font: 13px 'Open Sans', Arial, sans-serif;
    color: #d6341d;
    font-style: italic;
    width: 100%;
    /*margin: 0 0 10px 10px;*/
    position: relative;
    z-index: 3;
}

.vygodaprod .discount_info .discount_info_tip {
    position: absolute;
    display: none;
    width: 100%;
    padding: 7px;
    border: 1px solid #ccc;
    border-radius: 0px 0px 0px 0px;
    font-size: 13px;
    background-color: #ffffff;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.255);
    font-style: normal;
    left: 0px;
    margin-top: 14px;
}

.vygodaprod .discount_info .discount_info_tip .dicount_info_title {
    font-size: 15px;
    font-weight: bold;
    margin: 0px 0px 10px 0px;
    text-align: center;
    font-style: normal;
    color: #d6341d;
}

.vygodaprod .discount_info .discount_info_tip .dicount_info_row {
    margin: 0px 0px 5px 0px;
    color: #333333;
}

.vygodaprod .discount_info {
    padding: 13px 15px 13px 15px;
    background: #fff;
    /* background-size: 26px; */
    border-radius: 4px;
    overflow: hidden;
    /* border-bottom: 3px solid #c5874c; */
    transition: all .2s;
    border: 1px dotted #333;
    border-radius: 0;
}

.vygodaprod .discount_info:hover {
    background: #fff;
    /* background-size: 26px; */
    transition: all .2s;
    /* border-bottom: 3px solid #c5874c; */
}

.vygodaprod .discount_info span {
    border-bottom: 1px dotted #d6341d;
    cursor: pointer;
    font-style: normal;
    font-size: 16px;
}

.rub-symbol {
    font-family: "Rouble";
    font-weight: 400;
    font-style: normal;
    text-transform: lowercase;
}

.vygodaprod .discount_info .discount_info_tip .dicount_info_form_title {
    margin: 10px 0px 5px 0px;
    font-weight: bold;
    color: #333333;
}

.vygodaprod .discount_info .discount_info_tip .dicount_info_button {
    width: 150px;
    height: 30px;
    margin: 0px auto 5px auto;
    border: 0px;
    border-radius: 3px;
    border-color: #D7AF34;
    font: bold 14px 'Open Sans', Arial, Helvetica, sans-serif;
    text-align: center;
    line-height: 30px;
    background-image: url("../img/search_butt_1.gif");
    color: #363636;
    cursor: pointer;
}

.vygodaprod .discount_form {
    position: absolute;
    /* display: none; */
    width: 100%;
    padding: 7px;
    border: 1px solid #f5ab63;
    font-size: 13px;
    background-color: #ffffff;
    margin-top: -2px;
    border-radius: 0 0 4px 4px;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.255);
}

.vygodaprod .discount_form .discount_form_close {
    position: absolute;
    top: -4px;
    right: 5px;
    font-size: 24px;
    text-decoration: none;
    color: #ff6633;
    cursor: pointer;
    font-style: normal;
    transform: rotate(45deg);
}

.vygodaprod .discount_form .discount_form_title {
    font-size: 15px;
    font-weight: bold;
    margin: 0px 0px 10px 0px;
    text-align: center;
    color: #555;
    padding: 0px 10px;
    font-style: normal;
}

.vygodaprod .discount_form .discount_form_row {
    margin: 0px 0px 5px 0px;
}

.vygodaprod .discount_form .discount_form_row .label {
    float: left;
    width: 60px;
    margin: 0px 10px 0px 0px;
    color: #333333;
}

.vygodaprod .discount_form .discount_form_row .field {
    float: left;
    width: 100%;
    margin-top: 3px;
}

.vygodaprod_clear {
    clear: both;
}

.vygodaprod .discount_form .discount_form_info {
    margin: 10px 0px 5px 0px;
    font-weight: bold;
    color: #333333;
}

.vygodaprod .discount_form .discount_form_button {
    width: 100%;
    height: 30px;
    margin: 0px auto 5px auto;
    border: 0px;
    border-color: #D7AF34;
    font: bold 14px 'Open Sans', Arial, Helvetica, sans-serif;
    text-align: center;
    line-height: 30px;
    background-image: url("../img/search_butt_1.gif");
    color: #363636;
    cursor: pointer;
    border-radius: 4px;
}

.vygodaprod .discount_form .discount_form_row .field input {
    width: 100%;
    border: 1px solid #333333;
    font: 11px 'Open Sans', Arial, sans-serif;
    color: #333333;
}

.vygodaprod .discount_form .discount_form_row .label {
    float: left;
    width: 60px;
    margin: 0px 10px 0px 0px;
    color: #333333;
}

/* Баннеры слева и справа начало */

.category-banner-side {
    position: absolute;
    z-index: 5;
    top: 72px;
    padding-top: 20px;
    max-width: 150px;
}

.category-banner-side.fixed {
    position: fixed;
    top: 10px !important;
}

.category-banner-side img {
    border: 1px solid #c8c8c8;
    border-radius: 4px;
    -webkit-transition: 0.2s;
    transition: 0.2s;
}

.category-banner-side img:hover {
    -webkit-transition: 0.2s;
    transition: 0.2s;
    opacity: 0.85;
}

.category-banner-left {
    right: 50%;
    margin-right: 520px;
}

.category-banner-right {
    left: 50%;
    margin-left: 520px;
}

.wrap-top-menu/*.fixed*/ {
    position: fixed !important;
    left: 0px !important;
    top: 0px !important;
    width: 100% !important;
    z-index: 10001 !important; /*100 !important;*/
	/*top: 23px !important;*/
}

/*.wrap-top-menu.fixed + */.wrap-top-menu-replace {
    height: 40px;
}

#cart-module-top-line {
    display: none;
}

#cart-module-top-line.fixed {
    display: block;
}


.wrap.wrap-main-menu {
    position: relative;
    z-index: 10000; /*6;*/
    background-color: #d6341d;
    margin-bottom: 0px; /*15px;*/
}


/* Adaptive styles */


body {
    min-width: 320px;
}


.search-box {
    position: relative;
}

.searchclear {
    right: 85px;
    left: auto;
    top: 6px;
}

.search-box .input-group .form-control {
    padding-right: 40px;
}

.search-box-hide-group-btn {
    display: none;
}

.search-box-hide {
    text-indent: -9999px;
    width: 34px;
    border-left: none;
    margin-left: 0;
    background-image: url("../img/icon-cross.svg") !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: 24px 24px !important;
}

.search-box .btn {
    margin-left: -1px;
    z-index: 2;
}

.search-box #searchButton {
    z-index: 3;
}


.phone-icon {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 42px;
    height: 42px;
    border-radius: 2px;
    background-color: #f8f5ee;
    text-indent: -9999px;
    display: none;
}

.whatsapp-icon {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 42px;
    height: 42px;
    border-radius: 2px;
    background-color: #f8f5ee;
    text-indent: -9999px;
    display: none;
}

.phone-icon:before {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: url("../img/icon-phone.svg") center center no-repeat;
    background-size: 24px 24px;
    opacity: 0.54;
    content: "";
}

.whatsapp-icon:before {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: url('/files/uploads/images/Social/whatsapp_kamindom.png') center center no-repeat;
    background-size: 24px 24px;
    opacity: 0.54;
    content: "";
}

.mmenu-icon {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-indent: -9999px;
    display: none;
}

.mmenu-icon .fa {
    transition: all 0.3s ease-in-out;
}

.mmenu-icon .icon {
    position: absolute;
    opacity: 0;
    transform: scale(0);
    transition: all 0.3s ease-in-out;
}

.menu-switcher {
    position: relative;
    z-index: 2;
    float: left;
    margin-top: 7px;
    margin-right: 15px;
    display: none;
    height: 30px;
    width: 36px;
    text-align: center;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.menu-switcher:before {
    position: absolute;
    top: -1px;
    right: 0;
    left: 0;
    height: 5px;
    width: 36px;
    background-color: #d53535;
    content: "";
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.menu-switcher:after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 5px;
    width: 36px;
    background-color: #d53535;
    content: "";
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.menu-switcher > span {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.menu-switcher > span:first-child {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    height: 5px;
    width: 36px;
    margin-top: -3px;
    background-color: #d53535;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.menu-switcher > span:first-child + span {
    position: absolute;
    top: -8px;
    right: -5px;
    bottom: -8px;
    left: -5px;
}


/*.slideout-open .menu-switcher > span:first-child*/
.menu-switcher.active > span:first-child {
    -webkit-transform: rotate(90deg) scaleX(0);
    -moz-transform: rotate(90deg) scaleX(0);
    -ms-transform: rotate(90deg) scaleX(0);
    -o-transform: rotate(90deg) scaleX(0);
    transform: rotate(90deg) scaleX(0);
}

/*.slideout-open .menu-switcher:before*/
.menu-switcher.active:before {
    -webkit-transform-origin: 0% 50%;
    -moz-transform-origin: 0% 50%;
    -ms-transform-origin: 0% 50%;
    -o-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-transform: translate(5px, 0px) rotate(45deg);
    -moz-transform: translate(5px, 0px) rotate(45deg);
    -ms-transform: translate(5px, 0px) rotate(45deg);
    -o-transform: translate(5px, 0px) rotate(45deg);
    transform: translate(5px, 0px) rotate(45deg);
}

/*.slideout-open .menu-switcher:after*/
.menu-switcher.active:after {
    -webkit-transform-origin: 0% 50%;
    -moz-transform-origin: 0% 50%;
    -ms-transform-origin: 0% 50%;
    -o-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-transform: translate(5px, 0px) rotate(-45deg);
    -moz-transform: translate(5px, 0px) rotate(-45deg);
    -ms-transform: translate(5px, 0px) rotate(-45deg);
    -o-transform: translate(5px, 0px) rotate(-45deg);
    transform: translate(5px, 0px) rotate(-45deg);
}


.respon-meter {
    position: absolute;
    z-index: 9999;
}


#register_form {
    max-width: 305px;
}

.mobile-block {
    display: none;
}


.menu-site li a {
    font-size: 16px;

}

/*.navbar-kamin {
    border: none;
    border-color: #e7e7e7;
    background: url("../img/menu.png") repeat-x bottom left;
    margin-bottom:5px;
}*/

.navbar-kamin .navbar-collapse {
    background: #FFF;
    border-bottom: 2px solid #eee;
}

.header-kamin {
    border: none;
}

.navbar-default .navbar-nav > li > a, .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #333;
}

.header-kamin .navbar-toggle {
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-size: 26px;
    width: 100%;
    margin: 0;
    padding: 0;
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 0;
    /* background: url("../img/menu.png") repeat-x top left;*/
    background-color: #d00;
    color: #fff;
}

.mobile-block {
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background-color: #ebe5d7;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
    background-color: #ebe5d7;
}

#main-row-photos {

}

.kits-extras-header {
    /* margin-top: 55px; */
}

.catalog_often_searched {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}


/* Свои баннеры наверху НАЧАЛО */

.banner-top-new {
    justify-content: center;
    align-items: center;
    display: flex;
    background: #ffefc8;
    border: 1px solid #e1d2ae;
    border-radius: 4px;
    padding: 15px 15px 10px 15px !important;
}

.banner-top-new p {
    margin: 0 0 0 0;
    text-align: center;
}

.banner-top-new .banner-top-new__wrap {
    font-size: 20px;
    color: #232323;
    text-decoration: none !important;
}

.banner-top-new .banner-top-new__wrap em {
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    background: #ca5f03;
    padding: 10px 14px;
    border-radius: 4px;
    color: #fff;
    letter-spacing: .5px;
    box-shadow: inset 0 0 12px 0 rgba(0, 0, 0, 0.25);
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    display: inline-block;
    line-height: 1;
    margin-bottom: 5px;
}

.banner-top-new .banner-top-new__wrap strong {

}

/* Свои баннеры наверху КОНЕЦ */

/* fbbar (Begin) */
.fbbar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 250;
    background-color: #888;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    font-size: 14px;
    line-height: 1.25;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    bottom: env(safe-area-inset-bottom);
}

.fbbar.absolute-bottom {
    position: relative;
    bottom: 0 !important;
}

.fbbar a {
    color: #fff;
}

.fbbar-wrapper {
    height: 38px;
}

.fbbar__table {
    display: table;
    width: 100%;
    border-left: 1px solid rgba(255, 255, 255, 0.15);
    border-right: 1px solid rgba(255, 255, 255, 0.15);
    border-collapse: collapse;
}

.fbbar__cell {
    position: relative;
    display: table-cell;
    vertical-align: top;
    text-align: center;
    white-space: nowrap;
    border-left: 1px solid rgba(255, 255, 255, 0.15);
}

.fbbar__phone {
    display: block;
    padding: 10px 20px;
    text-decoration: none;
}

.fbbar__phone:before {
    position: relative;
    top: -2px;
    display: inline-block;
    background: url("../img/icon-phone-white.png") 0 0 no-repeat;
    width: 16px;
    height: 16px;
    margin: -5px 7px -5px 0;
    content: "";
}

.fbbar__phone:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.fbbar__feedback {
    display: block;
    padding: 10px 20px;
    text-decoration: none;
    text-align: center;
}

.fbbar__feedback:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.fbbar__compare {
    display: block;
    padding: 10px 20px;
    text-decoration: none;
}

.fbbar__compare:before {
    position: relative;
    top: -2px;
    display: inline-block;
    background: url("../img/icon-compare-white.png") 0 0 no-repeat;
    width: 16px;
    height: 16px;
    margin: -5px 7px -5px 0;
    content: "";
}

.fbbar__compare:hover, .fbbar__cell--cart:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.2);
}

.fbbar__compare-action {
    display: inline-block;
    color: #fff;
    background: transparent;
    letter-spacing: 0.04em;
    font-size: 12px;
    padding: 4px 8px;
    margin: -4px 0 -4px 5px;
}

.fbbar__cell--cart {
    width: 360px;
    background-color: #000;
}

.fbbar__scart {
    position: relative;
    display: block;
    padding: 10px 20px;
    z-index: 1;
}

.touch .fbbar__scart {
    cursor: pointer;
}

.fbbar__scart:before {
    position: relative;
    top: -3px;
    display: inline-block;
    background: url("../img/cart.png") center center no-repeat;
    width: 24px;
    height: 24px;
    margin: -10px 10px -10px 0;
    content: "";
}

.fbbar__scart:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.fbbar__scart-price {
    display: inline-block;
    font-weight: 700;
    color: #008dd0;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 3px 5px;
    margin: -3px 0 -3px 5px;
}

.fbbar__scart-action {
    display: inline-block;
    color: #fff;
    background: url(/venmi/views/admin/default/img/white-waves.png) #e56425;
    border-color: #e56425;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.04em;
    padding: 4px 8px;
    margin: -4px 0 -4px 5px;
}

.fbbar__scart-action:hover {
    color: #fff;
    background-color: #cd5920;
    border-color: #cd5920;
}

.fbbar__cart {
    position: absolute;
    bottom: 100%;
    right: -1px;
    width: 100%;
    min-width: 500px;
    text-align: left;
    color: #000;
    -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
    background-color: #f2f2f2;
    max-height: 0;
    overflow: hidden;
    -webkit-transition: all 0.3s, visibility 0s 0.3s;
    -o-transition: all 0.3s, visibility 0s 0.3s;
    transition: all 0.3s, visibility 0s 0.3s;
}

.fbbar__cart-close {
    position: absolute;
    top: 0;
    right: 0;
    color: #000;
    font-size: 24px;
    background-color: #fafafa;
    border: 1px solid #ddd;
    width: 28px;
    text-align: center;
    height: 28px;
    line-height: 25px;
    cursor: pointer;
}

.fbbar__cart-close:hover {
    color: #cd5920;
}

.fbbar__cart-title {
    position: absolute;
    top: 10px;
    left: 10px;
    text-transform: uppercase;
    font-size: 16px;
    color: #000;
}

.fbbar__cart-inner {
    padding: 35px 10px 10px;
    border: 1px solid #ddd;
    border-bottom: none;
}

.fbbar__cell--cart.disabled .fbbar__cart {
    max-height: 0;
}

.fbbar__cart.active {
    max-height: 9999px;
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
}

.fbbar__cart a {
    color: #000;
}

.fbbar__cart-list {
    display: table;
    width: 100%;
}

.fbbar__cart-item {
    display: table-row;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.fbbar__cart-item-title {
    white-space: normal;
}

.fbbar__cart-item:first-child {
    border-top: none;
}

.fbbar__cart-cell {
    display: table-cell;
    vertical-align: top;
    padding: 12px 0 12px 12px;
}

.fbbar__cart-cell:first-child {
    padding-left: 0;
}

.fbbar__cart-action {
    padding-top: 10px;
}

.fbbar__cart-action a {
    color: #fff;
}

.fbbar__cart-action button,
.fbbar__cart-action input,
.fbbar__cart-action a {
    margin: 0;
}

.fbbar__cart-item-quantity {
    color: #888;
}

.fbbar__cart-item-quantity input {
    width: 50px;
    text-align: center;
    border: 1px solid #888;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 3px 5px;
    margin: -5px 5px;
    color: #000;
    font-size: 14px;
}

.fbbar__cart-item-delete-icon {
    display: inline-block;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 50%;
    border-radius: 50%;
    font-size: 15px;
    width: 18px;
    line-height: 15px;
    padding-left: 1px;
    height: 18px;
    text-align: center;
    text-decoration: none !important;
}

.fbbar__cart-item-delete-icon:hover {
    color: #fff;
    background-color: #cd5920;
    border-color: #cd5920;
}

/* fbbar (End) */

#city-location i {
    font-size: 19px;
    color: #008dd0;
}

#city-location .city {
    color: grey;
    text-decoration: none;
    cursor: pointer;
}

.city-location-mobile {
    color: #2196F3;
    text-decoration: none;
    font-size: 16px;
}

.city-location-mobile svg {
    width: 12px;
    height: 12px;
    fill: currentColor;
}

li.borderless {
    border: 0 none;
}

.plitka-buy {
    margin-bottom: 2px;
    float: none;
    display: inherit;
    width: 100%;
    text-align: center;
}

#limit-catalog {
    font-size: 13px;
    width: 200px;
    text-align: right;
    float: right;
    color: #999;
    margin-bottom: 8px;
}



.offcanvas-stop-scrolling {
    height: auto;
    overflow: hidden;
}

.wrapall {
    transition: all 0.3s ease-in-out;
}

.wrap-header {
    transition: all 0.3s ease-in-out;
}

.navbar-default .navbar-offcanvas {
    background-color: #f8f8f8;
}

.navbar-inverse .navbar-offcanvas {
    background-color: #222;
}

.navbar-offcanvas {
    position: fixed;
    width: 100%;
    max-width: 255px;
    height: 100%;
    min-height: 100%;
    left: -255px;
    top: 0;
    padding-left: 0;
    padding-right: 0;
    z-index: 999;
    background-color: #f8f5ee;
    overflow-y: auto;
    overflow-x: hidden;
    /*-webkit-overflow-scrolling: touch;*/
    transition: all 0.3s ease-in-out;
}

.navbar-offcanvas.in {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.navbar-offcanvas.navbar-offcanvas-fade {
    opacity: 0;
}

.navbar-offcanvas.navbar-offcanvas-fade.in {
    opacity: 1;
}

.navbar-offcanvas.offcanvas-transform.in {
    -webkit-transform: translateX(255px);
    transform: translateX(255px);
}

.navbar-offcanvas.offcanvas-position.in {
    left: 0;
}

.navbar-offcanvas.navbar-offcanvas-right {
    left: auto;
    right: -255px;
}

.navbar-offcanvas.navbar-offcanvas-right.offcanvas-transform.in {
    -webkit-transform: translateX(-255px);
    transform: translateX(-255px);
}

.navbar-offcanvas.navbar-offcanvas-right.offcanvas-position.in {
    left: auto;
    right: 0;
}

.navbar-offcanvas .dropdown.open .caret {
    border-top: 0;
    border-bottom: 4px solid;
}

.navbar-offcanvas .dropdown-menu {
    position: relative;
    width: 100%;
    border: inherit;
    box-shadow: none;
    transition: height 0.15s ease-in;
}
/*
.navbar-offcanvas .dropdown-menu.shown {
    display: block;
    margin-bottom: 10px;
}*/

.offcanvas-toggle .icon-bar {
    background: #000;
    transition: all .25s ease-in-out;
}

.offcanvas-toggle.is-open .icon-bar:nth-child(1) {
    -webkit-transform: rotate(45deg) translate(5px, 4px);
    transform: rotate(45deg) translate(5px, 4px);
}

.offcanvas-toggle.is-open .icon-bar:nth-child(2) {
    opacity: 0;
}

.offcanvas-toggle.is-open .icon-bar:nth-child(3) {
    -webkit-transform: rotate(-45deg) translate(4px, -4px);
    transform: rotate(-45deg) translate(4px, -4px);
}

.navbar-offcanvas__section {

}

.navbar-offcanvas__section--dark {
    background-color: #ebe5d7;
}

.navbar-offcanvas__section--bordered {
    border-bottom: 1px solid #ccc;
}

.h4-mobile-header {
    text-align: center;
    font-size: 18px;
    font-family: inherit;
    font-weight: 700;
    line-height: 1.1;
    padding: 20px 20px 10px;
    color: inherit;
    display: block;
    list-style: none;
    -webkit-tap-highlight-color: transparent;
}

.menu-kamin .nav {
    float: none;
    /*padding-bottom: 10px;
    margin-bottom: 9px;*/
}

.menu-kamin .nav > li {
    float: none;
}

.menu-kamin .nav > li > a {
    font-size: 16px;
    font-weight: 700;
    padding: 9px 20px;
    color: #333;
    border-top: 1px solid #ccc!important;
}

.menu-kamin .nav > li > a .icon {
    position: relative;
    top: -2px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    color: #72706d;
}

.menu-kamin .nav > li > a:hover, .menu-kamin .nav > li > a:focus {
    background-color: transparent;
}

/*.menu-kamin .nav .open > a {
    border-top-color: rgba(51,51,51,.3)!important;
}*/

.menu-kamin .nav .open > a, .menu-kamin .nav .open > a:hover, .menu-kamin .nav .open > a:focus {
    background-color: rgba(51,51,51,.3);/*ebe5d7;*/
}

.menu-kamin .nav a.menulevel1 {
    font-size: 14px;
    padding: 7px 20px !important;
}

.menu-kamin .nav .dropdown-menu {
    float: none;
    margin: 0;
    border-radius: 0;
}

.menu-kamin .dropdown-menu > li > a {
    white-space: normal;
    line-height: 1.2;
}

.navbar-kamin .navbar-nav .open .dropdown-menu > .active > a,
.navbar-kamin .navbar-nav .open .dropdown-menu > .active > a:hover,
.navbar-kamin .navbar-nav .open .dropdown-menu > .active > a:focus {
    background-color: #ebe5d7;
}

.menu-kamin .nav > li > a.menulevel0 .caret {
    float: right;
    margin: 7px 0 0 10px;
    color: #333;
    border-width: 5px;
}

.menu-kamin--alt .nav > li > a .icon {
    color: #ccc;
}

.menu-kamin--alt .nav > li.menu-item--hl > a {
    background: #ffea00;
    font-weight: 700;
}

.menu-kamin--alt .nav > li.menu-item--hl > a .icon {
    color: #333;
}

.menu-kamin--alt .nav > li.menu-item--hl > a:hover {
    background: #fff583;
}


.navbar-offcanvas-btns {
    display: flex;
    margin-left: -1px;
}

.navbar-offcanvas-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    height: 40px;
    color: #72706d;
    flex-grow: 1;
    flex-basis: 0;
    border-left: 1px solid #ccc;
    text-decoration: none!important;
}

.navbar-offcanvas-btn:hover,
.navbar-offcanvas-btn:active,
.navbar-offcanvas-btn:focus {
    color: #d5351d!important;
}

.navbar-offcanvas-btn .fa,
.navbar-offcanvas-btn .icon {
    font-size: 20px;
    margin-right: 4px;
}

.navbar-offcanvas-btn .fa-user {
    font-size: 18px;
}

.navbar-offcanvas-btn--alt {
    background-color: #e5e5e5;
}


.navbar-offcanvas1.in ~ .wrap-header .mmenu-icon .fa {
    opacity: 0;
    transform: scale(0);
}

.navbar-offcanvas1.in ~ .wrap-header .mmenu-icon .icon {
    opacity: 1;
    transform: scale(1);
}

.navbar-offcanvas2.in ~ .wrapall .filter-switcher {
    pointer-events: none;
}

.navbar-offcanvas2 .navbar-nav {
    margin: 0;
}

.navbar-offcanvas.in ~ .wrapall {
    transform: translateX(-255px);
}

.navbar-offcanvas.in ~ .wrap-header {
    transform: translateX(-255px);
}

.navbar-offcanvas2 {
    max-width: 285px;
    left: -285px;
}

.navbar-offcanvas2.offcanvas-transform.in {
    -webkit-transform: translateX(285px);
    transform: translateX(285px);
}

.navbar-offcanvas2.in ~ .wrapall {
    transform: translateX(285px);
}

.navbar-offcanvas2.in ~ .wrap-header {
    transform: translateX(285px);
}




/* НАЧАЛО ИЗМЕНЕНИЙ от Feelosov с Kwork */
/* НАЧАЛО - настройка кнопки "Скрыть/показать категории */
/*! К .header-button-cat-toggle привязан скрипт !*/

.header-button-cat-toggle .btn:active {
    box-shadow: none;
}

.header-button-cat-toggle-label {
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.header-button-cat-toggle a:hover .header-button-cat-toggle-label {
    color: #ea7351;
}

.header-button-cat-toggle i {
    background-color: #e7e7e7;
    padding: 2px 6px;
    border-radius: 5px;
    font-size: 20px;
    color: #333;
    margin-right: 6px;
}

.header-button-cat-toggle a:hover i {
    background-color: #ea7351;
    color: #fff;
}

/* КОНЕЦ - настройка кнопки "Скрыть-показать категории */

/* НАЧАЛО - настройка списка категорий */

.delete-horizontal-paddings {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

/* КОНЕЦ - настройка списка категорий */

/* НАЧАЛО - настройка модального окна */

.after-buy-form-close-continue.btn {
    border-radius: 0;
}

.go-to-card .btn {
    background-color: #333;
    border-radius: 0;
    border: #333 1px solid;
}

.go-to-card .btn:hover {
    background-color: #d6341d;
    border: #d6341d 1px solid;
}

.go-to-card .btn:active {
    background-color: #d6341d;
    border-color: #d6341d;
}

.go-to-card .btn:active:hover {
    background-color: #d6341d;
    border: #d6341d 1px solid;
}

.go-to-card .btn:focus {
    background-color: #d6341d;
    border: #d6341d 1px solid;
}

.go-to-card a.btn {
    color: #fff;
}


/* КОНЕЦ - настройка модального окна */
/* НАЧАЛО - превью товаров в категории */

.plitka-hover-buttons .btn {
    border-radius: 0;
}

.tiles-buy-buttons {
    position: relative;
    z-index: 2;
    display: flex;
    display: -moz-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: -ms-flex;
    display: -o-flex;
}

.tiles-buy-buttons .plitka-buy {
    width: 80%;
    background: #d6341d;
    color: #fff;
    border-radius: 0px;
    display: block;
    text-transform: none;
}

.tiles-buy-buttons .plitka-buy i {
    margin-right: 5px;
}

.tiles-buy-buttons .plitka-buy:hover {
    background: #ea7352;
    text-decoration: none;
}

.tiles-buy-buttons .plitka-buy-fast {
    width: 20%;
    background: #333;
    border-radius: 0px;
    color: #fff;
    opacity: 1 !important;
    display: block;
}

.tiles-buy-buttons .plitka-buy-fast:hover {
    background: #000;
    text-decoration: none;
}

.tiles-buy-buttons a {
    text-decoration: none;
}


.plitka-buy-block .price-with-amount-and-little-buttons {
    margin-bottom: 15px;
    margin-top: 15px;
}


.tooltip-data-title:hover:after {
    content: attr(data-title);
    position: absolute;
    right: 0;
    bottom: 100%;
    margin-bottom: 20px;
    z-index: 2;
    background: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    font-weight: bold;
    padding: 5px;
    border: 1px dotted #d6341d;
    color: #d6341d;
    box-shadow: 0 0 3px #ccc;
    min-width: 165px;
    min-height: 50px;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    align-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
}

.plitka-buy-fast.tooltip-data-title:hover:after {
}

.plitka-gift.tooltip-data-title:hover:after {
}

#after-buy-form-make-order.buy-buy {
    padding: 12px !important;
    border-radius: 0;
    height: 42px;
}

#make-order-one-click.buy-buy {
    padding: 12px !important;
    border-radius: 0;
    height: 42px;
}

legend {
    border: none !important;
}

/* КОНЕЦ - превью товаров в категории */


/*
#make-compare:before {
    position: relative;
    top: -2px;
    display: inline-block;
    background: url("../img/icon-compare-black.png") 0 0 no-repeat;
    width: 16px;
    height: 16px;
    margin: -5px 0 -5px 0;
    content: "";
}
#make-compare {
	position: relative;
    top: -2px;
    display: inline-block;
    background: url("../img/balance-scale-left-regular.svg") 0 0 no-repeat;
    width: 16px;
    height: 16px;
    margin: -5px 0 -5px 0;
    content: "";
}
*/
#make-compare svg {
	width: 16px;
	height: 16px;
	color: #333;
}

/* КОНЕЦ ИЗМЕНЕНИЙ от Feelosov с Kwork */

.absolute {
    position: absolute;
}





.fbuy {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    /*z-index: 250;*/
	z-index: 2147483647;
    background: #fff;
    display: none;
    align-items: center;
    min-height: 80px;
    box-shadow: 0 0 24px rgba(0,0,0,0.3), 0 -1px 0 #ccc;
}

.fbuy-replace {
    min-height: 80px;
    display: none;
}

.fbuy .row {
    margin-left: -10px;
    margin-right: -10px;
    display: flex;
    align-items: center;
}

.fbuy .col-xs-6 {
    width: auto;
    flex: auto 1 1;
    padding-left: 10px;
    padding-right: 10px;
}

.fbuy__container {
    flex: auto 1 1;
}

.fbuy .plitka-buy {
    margin: 0;
}

.fbuy .plitka-buy i {
    font-size: 20px;
}

.fbuy .plitka-buy-fast {
    height: 45px;
    margin: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.fbuy .plitka-buy-fast i {
    font-size: 18px;
}

.fbuy .buy-price {
    font-size: 24px;
}

.fbuy .have-tradein-price {
	font-size: 18px;
}

.fbuy .buy-tradein-price {
    font-size: 24px;
}

.fbuy .buy-oldprice {
    font-size: 14px;
    white-space: nowrap;
}

.fbuy .product-status {
    font-size: 10px;
    margin-bottom: 0;
}

.fbuy .discount-badge {
    font-size: 12px!important;
}





/* Изменения от 09.2020 по корзине */


.product-header {
    margin-top: 20px;
}

.module-CartController #content h1 {
    margin-top: 20px;
}

.carttable tr td {
    border-top-color: #ccc !important;
}

.carttable tr th {
    border-bottom-color: #ccc !important;
}

.carttable-count input {
    border-radius: 0 !important;
}


.cart-blocks {
    display: flex;
    flex-direction: column;
}

.panel--delivery {
    order: 75;
}

.panel--montage {
    order: 80;
}

.cart-block--additional {
    order: 85;
}

.panel--payment {
    order: 90;
}

.form-group--agree {
    order: 95;
}

.cart-block-last {
    order: 100;
}

.panel--alt {
    border-radius: 0;
    border: none;
}

.panel--alt .panel-heading {
    border-radius: 0;
    border: none;
    background-color: #333;
    color: #fff;
    padding: 13px 20px;
}

.panel--alt .panel-body {
    padding: 20px 20px;
}

.panel--alt .form-control {
    border-radius: 0;
}

.panel--alt .btn-group .btn {
    border-radius: 0;
}

.panel--alt .userdata .cart-ok {
    right: 21px;
}

.panel--alt .userdata .hint {
    padding-left: 15px;
}

.add-phone {
    position: relative;
    display: block;
    clear: both;
    background-color: #eee;
    color: #333;
    line-height: 1;
    padding: 2px 5px 3px;
    margin-top: 2px;
    text-align: center;
}

.add-phone a {
    display: inline-block;
    vertical-align: top;
    line-height: 1.2;
    color: #333;
    font-size: 12px;
    text-decoration: none;
    border-bottom: 1px solid #999;
}

.add-phone:hover {
    border-bottom-color: transparent;;
}

.add-phone a:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
}

.cart-block-action .btn {
    font-size: 16px;
    border-color: #d6341d!important;
    background-color: #d6341d!important;
    border-radius: 0;
}






.the-count-to-cart {
    /* float: left */
}

.u_favorite {
    /* float: right;
    width: 30px;
    height: 30px; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    font-size: 18px;
    margin: 0 1px!important;
    border-radius: 4px!important;
    border: none!important;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    /* background-color: #e6e6e6;
    padding: 15px; */
    z-index: 2;
    position: relative
}

.u_favorite:hover {
    color: red;
}

.u_favorite.active,
.u_favorite:active {
    color: red!important;
}

.plitka-buy-block .plitka-buy i,
.u_favorite i {
    pointer-events: none;
    line-height: 1;
    display: inline-block;
}

.plitka-buy-block .plitka-buy i .icon {
    vertical-align: top;
}

.plitka-buy-fast {
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    float: right;
    background: #f7f7f7;
    color: #505050;
    padding: 6px 12px;
    border-radius: 2px;
    opacity: .6;
    margin-bottom: 2px;
    float: none;
    display: inherit;
    width: 100%;
    cursor: pointer;
}
.the-big-fast-open{
    width: 100%;
    padding: 13px;
    margin-bottom: 10px;
    font-size: 18px;
}
#after-information-products .product-name--mobile, .plitka-hover-buttons .product-link,
#after-information-products[aria-hidden=true] .modal-body .row.row__qiuck-content{
    display: block;
}
#after-information-products[aria-hidden=true] .modal-body .row.row__qiuck-content{
    height: auto;
    pointer-events: all;
    position: static;
}


.products-not-found {
    padding: 60px 0;
    text-align: center;
}



.modal--middle .modal-dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100% - 20px);
    pointer-events: none;
}

.modal--middle .modal-content {
    pointer-events: auto;
    border-radius: 15px;
}


.nav-pills-alt {
    padding: 25px 0;
}

.nav-pills-alt > li {
    float: none;
}

.nav-pills-alt > li > a {
    position: relative;
    text-align: left;
    font-size: 16px;
    color: #333!important;
    background-color: transparent!important;
    border: none;
    border-radius: 0;
    padding: 10px 15px;
    padding-right: 50px;
}

.nav-pills-alt > li > a:before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: 1px solid #393939;
    border-radius: 50%;
    content: '';
}

.nav-pills-alt > li > a:after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 18px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    content: '';
}

.nav-pills-alt > li.current > a:before {
    border-color: #d6341d;
}

.nav-pills-alt > li.current > a:after {
    background-color: #d6341d;
}



.nav-pills-alt > li i {
    margin-right: 5px;
}

.modal-content-thin {
    width: 296px;
    margin: 0 auto;
}

select.variant1_control option, select.variant2_control option, select.variant3_control option {
    color: #333;
}

select.variant1_control option:disabled, select.variant2_control option:disabled, select.variant3_control option:disabled {
    color: #ccc;
}

.swiper-next {
	top: 183px;
    background: url("../img/arrow-right.png") 50% 50% no-repeat #e3e3e3;
    margin-top: -15px;
    left: auto;
    right: 0px;
    z-index: 2;
    display: block;
    position: absolute;
    width: 40px;
    height: 66px;
    transition: all ease .3s;
    text-align: left;
    text-indent: -9999px;
    opacity: 1;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    margin: 6px;
    padding: 4px 7px;
    border-radius: 0px;
    white-space: nowrap;
}

.swiper-next:hover{
	background-color: #d2d2d2;
}

.swiper-next.swiper-button-disabled{
	opacity: 0.25;
    cursor: default;
}

.swiper-prev {
	top: 183px;
    background: url("../img/arrow-left.png") 50% 50% no-repeat #e3e3e3;
    margin-top: -15px;
    right: auto;
    z-index: 2;
    display: block;
    position: absolute;
    width: 40px;
    height: 66px;
    transition: all ease .3s;
    text-align: left;
    text-indent: -9999px;
    opacity: 1;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    margin: 6px;
    padding: 4px 7px;
    border-radius: 0px;
    user-select: none;
    white-space: nowrap;
}

.swiper-prev:hover{
	background-color: #d2d2d2;
}

.swiper-prev.swiper-button-disabled{
	opacity: 0.25;
    cursor: default;
}

.icon-icon-youtube-wt {
	background-color: #ff0000;
}

.icon-icon-youtube-wt a {
	color: #fff!important;
}

#menu-item-youtube{
	padding: 10px 15px 10px 15px;
	margin-left: 10px;
	border-radius: 15px;
	background-color: #333333;
	height: 42px;
}

#menu-item-youtube:hover{
	background-color:#ff0000!important;
	color:#ffffff!important;
	border: 2px solid #ffffff;
	height: 45px;
	padding-left: 13px;
	padding-right: 13px;
}

.product-price-subscription {
	color: #ea7352;
    /*font-weight: bold;*/
    border: 1px dotted #ea7352;
    padding: 3px;
	clear: both;
	cursor: pointer;
	display: inline-block;
	margin-top: 10px;
	margin-bottom: 10px;
	width: 100%;
	text-align: center;
}

.product-price-subscription.subscribed {
	color: #555;
	border: 1px dotted #555;
}
.product-price-subscription.subscribed svg {
	fill: #555;
}

.product-price-subscription svg, .product-price-subscription span {
	vertical-align: middle;
}

.product-price-subscription-message {
	color: #ea7352; 
    border: 1px dotted #ea7352;
    padding: 3px;
	clear: both;
	cursor: pointer;
	display: inline-block;
	margin-top: 10px;
	margin-bottom: 10px;
	width: 100%;
	text-align: center;
}

.badge-check-price-by-phone {
	margin: 8px 0 8px 0;
	color: #d6341d; 
	padding: 3px 2px 3px 2px; 
	border: solid thin #D6341D; 
	border-radius: 15px; 
	text-align: center;
	cursor: pointer;
	position: relative;
}