/* top line */
.climat_style .topline {
	background-color: #ebebeb !important;
}

.climat_style #topmenu li.menu-item a:hover {
    background: #ccc !important;
}

.climat_style #topmenu li.menu-item a:hover, 
.climat_style .navbar-default .navbar-nav>.active>a, 
.climat_style .navbar-default .navbar-nav>.active>a:focus, 
.climat_style .navbar-default .navbar-nav>.active>a:hover, 
.climat_style .right-menu a:hover {
	background: #ccc !important;
}

/* cart */
.climat_style #cart {
	background-color: #ebebeb !important;
}

.climat_style .wrap.wrap-main-menu {
	background-color: #3076be !important;
}

/* main menu */
.climat_style #main-navbar .horizontal-navbar .navbar-collapse {
	background-color: #3076be !important;
}

.climat_style .dropdown:hover a.menu-level0,
.climat_style .navbar-default .navbar-nav > .active > a.menu-level0,
.climat_style .dropdown:hover a.menu-level0 {
	background-color: #3076be !important;
}

.climat_style .navbar-default .navbar-nav > .active > a.menu-level0:hover,
.climat_style .navbar-default .navbar-nav > .active > a.menu-level0:focus {
	background-color: #3076be !important;
}

.climat_style .horizontal-navbar li.dropdown.dropdown-large a.menu-level0:hover {
	background-color: #eaf1f8 !important;
}

/* drop menu */
.climat_style .dropdown-menu {
	background-color: #eaf1f8 !important;
	border-color: #3076be !important;
}

/* sort */
.climat_style #sort li a.current {
	background-color: #f0f8e5 !important;
}

.climat_style #sort li a:hover {
	background-color: #eaf1f8 !important;
}

/* view */
.climat_style .view-style a.active {
	background-color: #f0f8e5 !important;
}

.climat_style .view-style a:hover {
    background-color: #eaf1f8 !important;
}

/* filter */
.climat_style #filter .panel-body > .form-group:hover {
	background-color: #f0f8e5 !important;
}

.climat_style .panel-body label input + span:after {
	background-color: #3076be !important;
}

.climat_style #clear-filter:hover {
	background-color: #f0f8e5 !important;
}

/* show more */
.climat_style .show-more {
	background-color: #3076be !important;
	color: #fff !important;
}

.climat_style .show-more:hover {
	background-color: #eaf1f8 !important;
	border-color: #3076be !important;
	color: #333 !important;
}

/* pagination */
.climat_style .pagination-all:hover {
	background-color: #eaf1f8 !important;
}

.climat_style .pagination > .active > a,
.climat_style .pagination > .active > span,
.climat_style .pagination > .active > a:hover,
.climat_style .pagination > .active > span:hover,
.climat_style .pagination > .active > a:focus,
.climat_style .pagination > .active > span:focus {
    background-color: #eaf1f8 !important;
}

.climat_style .pagination > li > a:hover,
.climat_style .pagination > li > span:hover,
.climat_style .pagination > li > a:focus,
.climat_style .pagination > li > span:focus {
    background-color: #eaf1f8 !important;
}

/* b-top */
.climat_style .b-top {
	background-color: #ccc !important;
}

/* footer */
.climat_style #footer,
.climat_style div.company {
	background-color: #ebebeb !important;
}