/* Быстрый просмотр НАЧАЛО */

.plitka-hover-buttons {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 47px;
    top: 0;
    left: 0;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
}

.product-inner-two:hover .plitka-hover-buttons {
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    opacity: 1;
    visibility: visible;
}

.product-inner-two:hover .badge-overlay {
    opacity: 0;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.plitka-hover-buttons .quick-view {
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    -webkit-transform: translate(-50%,-30px);
        -ms-transform: translate(-50%,-30px);
            transform: translate(-50%,-30px);
    max-width: 210px;
    background: rgba(255,255,255,0.7);
    color: #969696;
    border: 1px solid #969696;
    font-size: 14px;
    border-radius: 0;
    font-weight: bold;
}

.plitka-hover-buttons .quick-view:hover,
.plitka-hover-buttons .quick-view:focus,
.plitka-hover-buttons .quick-view:focus:active,
.plitka-hover-buttons .quick-view:active,
.plitka-hover-buttons .quick-view:active:hover {
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    background: rgba(150,150,150,0.7);
    color: #fff;
    border: 1px solid #333;
    color: #333;
    opacity: 1;
    font-weight: bold;
}

.product-inner-two:hover .plitka-hover-buttons .quick-view {
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    -webkit-transform: translate(-50%,0);
        -ms-transform: translate(-50%,0);
            transform: translate(-50%,0);
}

.plitka-hover-buttons .product-link {
    display: none!important;
}

    /** Модальное **/


#after-information-products .modal-dialog {
    width: 900px;
    max-width: 100%;
    margin: 0 auto;
    height: 100%;
    max-height: 700px;
    top: 50%;
    -webkit-transform: translate(0,-50%);
        -ms-transform: translate(0,-50%);
            transform: translate(0,-50%);
}

#after-information-products .modal-header {
    padding: 15px;
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    border-bottom: 0;
}

#after-information-products .modal-header .close {
    font-size: 25px;
    color: #333;
    opacity: 1;
    width: 40px;
    height: 40px;
    background: #fff;
    -webkit-border-radius: 100px;
            border-radius: 100px;
    line-height: 17px;
    border: 1px solid #333;
}

#after-information-products .modal-header .close:hover {
    background: #ea7352;
    color: #fff;
    border: none;
}

#after-information-products .modal-body {
    padding: 25px 15px 15px 15px;
    position: fixed;
    z-index: 1;
    background: #fff;
    bottom: 64px;
    overflow: auto;
    top: 0;
    width: 900px;
    max-width: 100%;
}

#after-information-products .modal-body::-webkit-scrollbar {
    width: 12px;
}

#after-information-products .modal-body::-webkit-scrollbar-track {
    background: #eee;
}

#after-information-products .modal-body::-webkit-scrollbar-thumb {
    background: #d6d6d6; 
    border-radius: 10px;
    -webkit-border-radius: 10px;
    box-shadow: inset 0 0 1px rgba(0,0,0,0.2); 
    -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.2); 
}

#after-information-products .modal-body::-webkit-scrollbar-thumb:window-inactive {
    background: #d2d2d2; 
    -webkit-border-radius: 10px;
}

	/* Аналог эффекта скролла в начала модального окна при закрытии. Нужен, т. к. сохраняется позиция прокрутки из-за того, что контент модального окна не очищается после закрытия */
#after-information-products[aria-hidden="true"] .modal-body .row.row__qiuck-content {
    display: none;
    pointer-events: none;
    position: fixed;
    top: 0;
    height: 0;
    padding: 0;
    margin: 0;
}

#after-information-products .product-name--mobile {
    display: none;
}

#after-information-products .row__badges .product-links {
    margin-bottom: 15px;;
}

#after-information-products .col__img {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

#after-information-products .img_part .img_gallery li {
    min-height: 350px;
}

#after-information-products .img_part .img_gallery li a {
    width: 100%;
    height: 350px;
    line-height: 350px;
    pointer-events: none;
}

#after-information-products .img_part .img_gallery_small li {
    cursor: default;
}

#after-information-products .img_part .img_gallery_small li a {
    pointer-events: none;
}

#after-information-products .product-name--full {
    padding-bottom: 10px;
}

#after-information-products .product-name--full p {
    margin-bottom: 5px;
    font-size: 18px;
    padding-right: 45px;
}

#after-information-products .plitka-buy-block {
    margin-top: 0;
    /* margin-bottom: 13px; */
    /* border-top: 1px solid #ddd; */
    /* padding-top: 11px; */
}

#after-information-products .plitka-price {
    font-size: 26px;
    color: #000;
}

#after-information-products .plitka-price.have-oldprice-tile {
    color: #ff001e;
    padding: 15px 0;
}

#after-information-products .product-properties {
    height: auto!important;
}

#after-information-products .un_f6 {
    display: none!important;
}

#after-information-products .modal-footer {
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
    position: fixed;
    bottom: 0;
    background: #fff;
    width: 900px;
    max-width: 100%;
    z-index: 100;
}

#after-information-products .img_part{
    width: 100%;
}

@media (max-width: 767px) {

    #after-information-products .col__img {
        position: relative;
    }

    #after-information-products .plitka-buy-block {
        min-height: unset;
    }

    #after-information-products .img_part .img_gallery li {
        min-height: 350px;/*250px;*/
    }

    #after-information-products .img_part .img_gallery li a {
        width: 100%;
        height: 350px;/*250px;*/
        line-height: 350px;/*250px;*/
        pointer-events: none;
    }

}

@media (max-width: 1024px) {
    
    .plitka-hover-buttons {
        left: auto;
        right: 0;
        width: 50px;
        height: 50px;
        border: 0;
        background: transparent;
        opacity: 1!important;
        visibility: visible!important;
    }
    
    .product-inner-two .plitka-hover-buttons .quick-view {
        -webkit-transition: .15s;
        -o-transition: .15s;
        transition: .15s;
        background: transparent!important;
        border: 0;
        display: block;
        height: 100%;
        width: 100%;
        padding: 0;
        -webkit-transform: none;
            -ms-transform: none;
                transform: none;
        opacity: .8;
        right: 0;
        top: 0;
        left: auto;
    }
        
    .product-inner-two .plitka-hover-buttons .quick-view:hover {
        -webkit-transform: scale3d(1.2,1.2,1.2);
                transform: scale3d(1.2,1.2,1.2);
        -webkit-box-shadow: none;
                box-shadow: none;
        opacity: 1;
    }
    
    .product-inner-two:hover .plitka-hover-buttons .quick-view {
        -webkit-transition: .15s;
        -o-transition: .15s;
        transition: .15s;
        -webkit-transform: none;
            -ms-transform: none;
                transform: none;
        opacity: .4;
    }
    
    .product-inner-two:hover .badge-overlay {
        opacity: 1;
    }
        
    .product-inner-two .plitka-hover-buttons .quick-view span {
        background: url(/venmi/views/frontend/default/img/quick-view.svg) no-repeat center center rgba(44,44,44,0.5);
        -webkit-background-size: 19px 19px;
                background-size: 19px;
        display: block;
        width: 30px;
        height: 30px;
        position: absolute;
        top: 5px;
        right: 5px;
        -webkit-border-radius: 100%;
                border-radius: 100%;
        text-indent: -9999px;
        color: transparent;
        -webkit-transition: all .2s;
        -o-transition: all .2s;
        transition: all .2s;
        /*-webkit-box-shadow: inset 0 0 12px 0 #007cff;
                box-shadow: inset 0 0 12px 0 #007cff;*/
    }
    
    #after-information-products {
        background-color: #fff;
    }
    
    #after-information-products .modal-backdrop {
        background-color: #fff;
    }

    #after-information-products .modal-dialog {
        height: 100%;
        width: 100%;
        max-height: 100%;
        margin: 0;
        top: 0;
        -webkit-transform: none;
            -ms-transform: none;
                transform: none;
    }
    
    #after-information-products .modal-header {
        position: fixed;
        z-index: 10;
        top: 0;
        right: 0;
        border-bottom: 0;
    }
    
    #after-information-products .modal-header .close {
        font-size: 30px;
        color: #333;
        opacity: 1;
        width: 50px;
        height: 50px;
        background: #fff;
        border: 1px solid #333;
        -webkit-border-radius: 100px;
                border-radius: 100px;
    }
    
    #after-information-products .modal-body {
        position: fixed;
        z-index: 1;
        background: #fff;
        height: 100%;
        overflow-y: auto;
        width: 100%;
        padding-bottom: 75px;
    }
	
	#after-information-products .modal-body::-webkit-scrollbar {
		display: none;
	}

    #after-information-products .img_part a {
        pointer-events: none;
    }
    
    #after-information-products .product-properties {
        height: auto!important;
    }
    
    #after-information-products .un_f6 {
        display: none;
    }
    
    #after-information-products .modal-body .col-xs-6 {
        width: 100%;
    }

    #after-information-products .modal-footer {
        position: fixed;
        z-index: 10;
        bottom: 0;
        left: 0;
        right: 0;
        background: #e3e4e4;
        padding: 0;
        width: 100%;
    }
    
    #after-information-products .modal-footer-col {
        padding: 0;
    }
    
    #after-information-products .modal-footer .modal-footer-col:first-child {
        display: none;
    }
    
    #after-information-products .modal-footer .modal-footer-col:last-child {
        margin-top: 0;
    }
    
    #after-information-products .modal-footer .modal-footer-col .btn {
        padding: 12px 15px;
        font-size: 18px;
    }
    
    #after-information-products .modal-footer .modal-footer-col:last-child .btn:after {
        content: "";
        display: inline-block;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        width: 10px;
        height: 10px;
        -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
                transform: rotate(45deg);
        margin-left: 4px;
    }
            
}

@media (min-width: 768px)
and (max-width: 1024px) {
        
    #after-information-products .modal-backdrop {
        background-color: #000;
    }
    
    
    #after-information-products .product-name--full p {
        padding-right: 60px;
    }


    #after-information-products .modal-footer .modal-footer-col:first-child {
        display: block;
    }
    
    #after-information-products .modal-body .col-xs-6 {
        width: 50%;
    }
    
}

@media (min-width: 1025px) {
    
    #after-information-products .modal-body {
        padding: 25px 25px 15px 15px;
    }
    
    #after-information-products .modal-header {
        right: 5px;
    }
    
}