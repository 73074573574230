
.dblock {
    margin-top: 50px;
	margin-bottom: 50px;
    padding-top: 0;
    padding-bottom: 0;
}

/*
.dblock + .dblock {
    margin-top: 40px;
}
*/

/*
.dblock:first-child {
    margin-top: 0;
}
*/


.rtgbs {
    margin: 20px -15px 0;
}

.rtgbs:first-child {
    margin-top: 0;
}

.rtgbs__container:after {
    display: table;
    clear: both;
    content: '';
}

.rtgbs__inner {
    float: left;
    width: 100%;
    margin: -15px 0;
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    counter-reset: rtgb;
}

.rtgb {
    display: inline-block;
    vertical-align: top;
    width: 33.33%;
    padding: 14px;
    font-size: 16px;
    line-height: 1.2;
    text-align: center;
}

.rtgb__inner {
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    min-height: 100px;
    padding-left: 30px;
}

.rtgb__inner:before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 30px;
    background-color: #333;
    color: #fff;
    font-size: 44px;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    counter-increment: rtgb;
    content: counter(rtgb);
}

.rtgb__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f3f3f3 center center no-repeat;
    background-size: cover;
    z-index: -1;
    box-shadow: inset 0 0 0 1px #4f4f4f;
}

.rtgb__bg img {
    position: absolute;
    width: 100%;
    height: 100%;
    visibility: hidden;
}

.rtgb__title {
    font-size: 18px;
    font-weight: 700;
    text-shadow: 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff;
}

.rtgb__summary {
}

.rtgb:nth-child(2n) .rtgb__bg {
    box-shadow: inset 0 0 0 1px #d6341d;
}

.rtgb:nth-child(2n) .rtgb__inner:before {
    background-color: #d6341d;
}


.stgbs {
    margin: 0 -45px;
}

.stgbs:after {
    display: table;
    clear: both;
    content: '';
}

.stgbs:first-child {
    margin-top: 0;
}

.stgbs__inner {
    float: left;
    width: 100%;
    margin: -10px 0;
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
}

.stgb {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 25%;
    padding: 30px 45px;
    font-size: 16px;
    line-height: 1.2;
}

.stgb:after {
    position: absolute;
    top: 50%;
    left: -30px;
    margin-top: -33px;
    width: 61px;
    height: 66px;
    background: center center no-repeat;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='arrow' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='60.007px' height='65.992px' viewBox='0 0 60.007 65.992' enable-background='new 0 0 60.007 65.992' xml:space='preserve'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='%23E5E5E5' d='M0,65.992l32.462-32.995L0,0h27.536L60,32.997 L27.536,65.992H0z'/%3E%3C/svg%3E%0A");
    content: '';
}

.stgb:nth-child(4n+1):after {
    display: none;
}

.stgb:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-top: 1px solid #ccc;
    content: '';
}

.stgb:nth-child(1):before,
.stgb:nth-child(2):before,
.stgb:nth-child(3):before,
.stgb:nth-child(4):before {
    display: none;
}

.stgb:nth-child(4n+1):before {
    left: 45px;
}

.stgb:nth-child(4n+4):before {
    right: 45px;
}

.stgb__inner {
    display: flex;
    align-items: center;
    height: 100%;
}

.stgb__icon {
    flex: auto 0 0;
    margin-right: 10px;
    font-size: 30px;
    line-height: 1;
    color: #d6341d;
}

.stgb__icon .icon--cons {
    width: 1.166em;
}

.stgb__icon .icon--check {
    width: 1.333em;
}

.stgb__icon .icon--dely {
    width: 1.43em;
}

.stgb__icon .icon {
    vertical-align: top;
}

.stgb__content {
    flex: auto 1 1;
}