.mobx-holder,
.mobx-holder * {
    -webkit-box-sizing: border-box;
            box-sizing: border-box
}

.mobx-holder,
.mobx-holder svg {
    pointer-events: none
}

.mobx-holder,
.mobx-item,
.mobx-item-inner {
    overflow: hidden;
    -webkit-backface-visibility: hidden
}

.mobx-error,
.mobx-html,
.mobx-iframe,
.mobx-img,
.mobx-slider,
.mobx-thumb,
.mobx-video {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none
}

.mobx-holder {
    position: fixed;
    z-index: 99999;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    will-change: opacity, visibility;
    -ms-touch-action: none;
    touch-action: none;
    -webkit-text-size-adjust: 100%;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-transition: opacity .4s ease;
    -webkit-transition: opacity .4s ease, visibility .4s ease;
    -o-transition: opacity .4s ease, visibility .4s ease;
    transition: opacity .4s ease, visibility .4s ease
}

.mobx-overlay,
.mobx-ui:after {
    position: absolute;
    left: 0;
    will-change: opacity;
    top: 0
}

.mobx-holder:-webkit-full-screen {
    background-color: transparent
}

.mobx-open {
    pointer-events: auto;
    visibility: visible;
    opacity: 1
}

.mobx-rtl {
    direction: rtl
}

.mobx-slider {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 1;
    -ms-touch-action: none;
    touch-action: none;
    -webkit-transition: opacity .45s ease;
    -o-transition: opacity .45s ease;
    transition: opacity .45s ease;
    will-change: transform;
}

.mobx-hide {
    opacity: 0;
    -webkit-transition: none;
    -o-transition: none;
    transition: none
}

.mobx-overlay {
    width: 100%;
    height: 100%;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    background-color: rgba(0, 0, 0, 0.75);
}

.mobx-ui:after {
    content: "";
    right: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    background: rgba(0, 0, 0, .4);
    -webkit-transition: opacity .25s ease-out, visibility .25s ease-out;
    -o-transition: opacity .25s ease-out, visibility .25s ease-out;
    transition: opacity .25s ease-out, visibility .25s ease-out
}

.mobx-open-tooltip .mobx-ui:after {
    opacity: 1;
    visibility: visible
}

.mobx-share-tooltip:after,
.mobx-thumb-video:after,
.mobx-ui button,
.mobx-video:after,
.mobx-video:before {
    background-image: url("../js/vendor/icons/modulobox.svg");
    background-repeat: no-repeat;
    background-color: transparent
}

.mobx-bottom-bar,
.mobx-top-bar {
    position: absolute;
    will-change: transform;
    background-color: rgba(0, 0, 0, .4);
    left: 0;
    width: 100%
}

.mobx-top-bar {
    top: 0;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
        transform: translateY(0);
    -webkit-transition: -webkit-transform .25s ease-in-out;
    transition: -webkit-transform .25s ease-in-out;
    -o-transition: transform .25s ease-in-out;
    transition: transform .25s ease-in-out;
    transition: transform .25s ease-in-out, -webkit-transform .25s ease-in-out
}

.mobx-holder:not(.mobx-open) .mobx-top-bar,
.mobx-idle .mobx-top-bar,
.mobx-will-close .mobx-top-bar {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
        transform: translateY(0)
}

.mobx-bottom-bar {
    bottom: 0;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
        transform: translateY(0);
    -webkit-transition: -webkit-transform .25s ease-in-out;
    transition: -webkit-transform .25s ease-in-out;
    -o-transition: transform .25s ease-in-out;
    transition: transform .25s ease-in-out;
    transition: transform .25s ease-in-out, -webkit-transform .25s ease-in-out
}

.mobx-holder:not(.mobx-open) .mobx-bottom-bar,
.mobx-panzoom .mobx-bottom-bar,
.mobx-will-close .mobx-bottom-bar {
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
        transform: translateY(100%)
}

.mobx-caption {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    pointer-events: none
}

.mobx-caption-inner {
    max-width: 420px;
    margin: 0 auto;
    pointer-events: auto;
    cursor: default
}

.mobx-desc,
.mobx-title {
    position: relative;
    margin: 0;
    padding: 14px 10px 12px;
    font-weight: 400;
    display: block
}

.mobx-error,
.mobx-html {
    pointer-events: none
}

.mobx-title {
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #eee
}

.mobx-desc,
.mobx-error {
    color: #bbb;
    text-align: center
}

.mobx-desc {
    font-size: 12px;
    line-height: 16px
}

.mobx-title+.mobx-desc {
    margin: -6px 0 0;
    padding: 0 10px 10px
}

.mobx-item,
.mobx-item-inner {
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    -ms-touch-action: none;
        touch-action: none;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
        transform-origin: center
}

.mobx-img,
.mobx-panzoom .mobx-item {
    cursor: move;
    cursor: grab;
    cursor: -webkit-grab
}

.mobx-dragging .mobx-item * {
    cursor: grabbing;
    cursor: -webkit-grabbing
}

.mobx-error,
.mobx-html,
.mobx-iframe,
.mobx-img,
.mobx-video {
    position: absolute;
    display: block;
    height: 0;
    width: 0;
    max-width: none;
    padding: 0;
    margin: 0;
    opacity: 0;
    will-change: opacity;
    visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-transition: opacity .35s ease;
    -o-transition: opacity .35s ease;
    transition: opacity .35s ease
}

.mobx-media-loaded {
    opacity: 1;
    visibility: visible
}

.mobx-error,
.mobx-html-inner {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
        transform: translateY(-50%)
}

.mobx-error {
    width: 100%;
    height: auto;
    padding: 20px 60px
}

.mobx-html-inner {
    position: relative;
    display: inline-block;
    overflow: auto;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    pointer-events: auto
}

.mobx-video {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.75);
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.mobx-video.mobx-playing {
    background: 0 0;
    background-image: none!important
}

.mobx-video:after,
.mobx-video:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    padding: 0;
    /*margin: -44px 0 0 -44px;*/
    /*border: 4px solid #fff;*/
    -webkit-border-radius: 100%;
            border-radius: 100%;
    -webkit-box-sizing: content-box;
            box-sizing: content-box
}

.mobx-video.mobx-loading:before {
    border-color: transparent
}

.mobx-video:before {
    /*background: url(/templates/modern/js/modulobox/play--w.svg) no-repeat 60% 50%;*/
	background: center center no-repeat;
	background-size: contain;
	background-image: url(/venmi/views/frontend/default/img/youtube-icon-logo.svg);
	top: 40%;
	left: 40%;
	width: 20%;
	height: 20%;
	position:absolute;
	content: '';
	/*
	-webkit-background-size: 40px 40px;
	        background-size: 40px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
	*/
}

.mobx-video:hover:before {
    -webkit-transform: scale3d(1.2,1.2,1.2);
            transform: scale3d(1.2,1.2,1.2);
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.mobx-video:after {
    opacity: 0;
    visibility: hidden;
    background-image: none;
    background-color: transparent;
    /*border-color: transparent transparent transparent #fff*/
}

.mobx-video.mobx-loading:after {
    opacity: 1;
    visibility: visible;
    -webkit-animation: mobx-load 1.1s infinite linear;
    animation: mobx-load 1.1s infinite linear
}

.mobx-video.mobx-playing:after,
.mobx-video.mobx-playing:before {
    opacity: 0
}

.mobx-video .mejs-video,
.mobx-video iframe,
.mobx-video video {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%!important;
    height: 100%!important;
    opacity: 0;
    background: rgba(255, 255, 255, .065);
    pointer-events: none;
    -webkit-transition: opacity .35s ease-in-out;
    -o-transition: opacity .35s ease-in-out;
    transition: opacity .35s ease-in-out
}

.mobx-video iframe {
    background: 0 0
}

.mobx-video.mobx-playing .mejs-video,
.mobx-video.mobx-playing iframe,
.mobx-video.mobx-playing video {
    opacity: 1;
    pointer-events: auto
}

.mobx-fullscreen .mejs-fullscreen-button,
.mobx-fullscreen video::-webkit-media-controls-fullscreen-button {
    display: none
}

.mobx-counter,
.mobx-timer {
    position: relative;
    display: inline-block
}

:-webkit-full-screen-ancestor:not(iframe) {
    will-change: auto!important
}

.mobx-counter {
    float: left;
    height: 44px;
    font-size: 13px;
    line-height: 44px;
    color: #fff;
    opacity: .75;
    margin: 0 10px;
    letter-spacing: .6px
}

.mobx-rtl .mobx-counter {
    float: right
}

.mobx-timer {
    float: left;
    width: 24px;
    height: 24px;
    margin: 10px 0 10px 5px;
    opacity: 0;
    will-change: opacity;
    -webkit-transition: opacity .25s ease-in-out;
    -o-transition: opacity .25s ease-in-out;
    transition: opacity .25s ease-in-out
}

.mobx-rtl .mobx-timer {
    float: right
}

.mobx-autoplay .mobx-timer {
    opacity: 1
}

.mobx-loader {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 50%;
    left: 50%;
    margin: -15px 0 0 -15px;
    outline: transparent solid 1px;
    -webkit-border-radius: 50%;
            border-radius: 50%;
    border-width: 3px;
    border-right-style: solid;
    border-top-style: solid;
    border-bottom-style: solid;
    border-color: rgba(255, 255, 255, .2);
    border-left: 3px solid #fff;
    -webkit-animation: mobx-load 1.1s infinite linear;
    animation: mobx-load 1.1s infinite linear
}

@-webkit-keyframes mobx-load {
    0% {
        -webkit-transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg)
    }
}

@keyframes mobx-load {
    0% {
        -webkit-transform: rotate(0);
                transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg)
    }
}

.mobx-share-tooltip {
    position: absolute;
    display: block;
    z-index: 2;
    width: 120px;
    top: 50px;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    will-change: transform;
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
        transform: translateY(10px);
    -webkit-transition: -webkit-transform .25s ease, opacity .25s ease, visibility .25s ease;
    -webkit-transition: opacity .25s ease, visibility .25s ease, -webkit-transform .25s ease;
    transition: opacity .25s ease, visibility .25s ease, -webkit-transform .25s ease;
    -o-transition: transform .25s ease, opacity .25s ease, visibility .25s ease;
    transition: transform .25s ease, opacity .25s ease, visibility .25s ease;
    transition: transform .25s ease, opacity .25s ease, visibility .25s ease, -webkit-transform .25s ease
}

.mobx-share-tooltip:before {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    top: -12px;
    right: 14px;
    border: 6px solid transparent;
    border-bottom-color: #f9fafb
}

.mobx-share-tooltip button:after,
.mobx-thumb-video:after,
.mobx-thumb:after {
    content: ""
}

.mobx-share-tooltip[data-position=left]:before {
    left: 14px;
    right: auto
}

.mobx-open-tooltip .mobx-share-tooltip {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
        transform: translateY(0)
}

.mobx-share-tooltip span {
    display: block;
    width: 100%;
    height: 40px;
    padding: 10px;
    color: #777;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    background: #f9fafb;
    -webkit-box-shadow: inset 0 -2px 8px rgba(0, 0, 0, .03);
            box-shadow: inset 0 -2px 8px rgba(0, 0, 0, .03)
}

.mobx-share-tooltip:after,
.mobx-ui button {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    padding: 0;
    cursor: pointer;
    -webkit-box-shadow: none;
            box-shadow: none;
    border: none;
    outline: 0;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    -webkit-appearance: none;
    -webkit-transition: opacity .25s ease-in-out;
    -o-transition: opacity .25s ease-in-out;
    transition: opacity .25s ease-in-out
}

.mobx-share-tooltip button:hover:after,
.mobx-ui button:hover {
    opacity: 1
}

.mobx-share-tooltip button:after {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0
}

.mobx-share-tooltip button {
    float: left
}

.mobx-rtl .mobx-share-tooltip button {
    float: right
}

.mobx-top-bar button {
    float: right;
    margin: 2px;
    opacity: .75
}

.mobx-rtl .mobx-top-bar button {
    float: left
}

button.mobx-next,
button.mobx-prev {
    position: absolute;
    left: 0;
    top: 50%;
    width: 90px;
    height: 320px;
    margin: 0 0;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0);
    will-change: transform;
    -webkit-transform: translateX(0) translateY(-50%);
    -ms-transform: translateX(0) translateY(-50%);
        transform: translateX(0) translateY(-50%);
    -webkit-transition: opacity .25s ease-in-out, -webkit-transform .25s ease-in-out;
    transition: opacity .25s ease-in-out, -webkit-transform .25s ease-in-out;
    -o-transition: opacity .25s ease-in-out, transform .25s ease-in-out;
    transition: opacity .25s ease-in-out, transform .25s ease-in-out;
    transition: opacity .25s ease-in-out, transform .25s ease-in-out, -webkit-transform .25s ease-in-out
}

button.mobx-next {
    left: auto;
    right: 0
}

.mobx-holder:not(.mobx-open) .mobx-prev,
.mobx-idle .mobx-prev,
.mobx-will-close .mobx-prev {
    -webkit-transform: translateX(0) translateY(-50%);
    -ms-transform: translateX(0) translateY(-50%);
        transform: translateX(0) translateY(-50%)
}

.mobx-holder:not(.mobx-open) .mobx-next,
.mobx-idle .mobx-next,
.mobx-will-close .mobx-next {
    -webkit-transform: translateX(0) translateY(-50%);
    -ms-transform: translateX(0) translateY(-50%);
        transform: translateX(0) translateY(-50%)
}

.mobx-holder[data-download=false] .mobx-download,
.mobx-holder[data-download=false] .mobx-download:hover,
.mobx-holder[data-zoom=false] .mobx-zoom,
.mobx-holder[data-zoom=false] .mobx-zoom:hover {
    opacity: .45;
    cursor: default
}

.mobx-prev {
    left: 0;
    background-position: 10px 4px
}

.mobx-next {
    right: 0;
    background-position: 10px -40px
}

.mobx-close {
    background-position: 12px -76px
}

.mobx-zoom {
    background-position: 12px -120px
}

.mobx-panzoom .mobx-zoom {
    background-position: 12px -164px
}

.mobx-play {
    background-position: 12px -208px
}

.mobx-autoplay .mobx-play {
    background-position: 12px -252px
}

.mobx-download {
    background-position: 12px -296px
}

.mobx-share {
    background-position: 12px -340px
}

.mobx-fullscreen {
    background-position: 12px -384px
}

.mobx-fullscreen .mobx-fullscreen {
    background-position: 12px -428px
}

.mobx-facebook {
    background-position: -32px -472px
}

.mobx-facebook:after {
    background-color: #3b5998;
    background-position: 12px -472px
}

.mobx-twitter {
    background-position: -32px -516px
}

.mobx-twitter:after {
    background-color: #1da1f2;
    background-position: 12px -516px
}

.mobx-googleplus {
    background-position: -32px -560px
}

.mobx-googleplus:after {
    background-color: #dd4b39;
    background-position: 12px -560px
}

.mobx-pinterest {
    background-position: -32px -604px
}

.mobx-pinterest:after {
    background-color: #bd081c;
    background-position: 12px -604px
}

.mobx-linkedin {
    background-position: -32px -648px
}

.mobx-linkedin:after {
    background-color: #0077b5;
    background-position: 12px -648px
}

.mobx-reddit {
    background-position: -32px -692px
}

.mobx-reddit:after {
    background-color: #ff4500;
    background-position: 12px -692px
}

.mobx-tumblr {
    background-position: -32px -736px
}

.mobx-tumblr:after {
    background-color: #35465c;
    background-position: 12px -736px
}

.mobx-blogger {
    background-position: -32px -780px
}

.mobx-blogger:after {
    background-color: #f57d00;
    background-position: 12px -780px
}

.mobx-buffer {
    background-position: -32px -824px
}

.mobx-buffer:after {
    background-color: #323b43;
    background-position: 12px -824px
}

.mobx-digg {
    background-position: -34px -868px
}

.mobx-digg:after {
    background-color: #005be2;
    background-position: 10px -868px
}

.mobx-stumbleupon {
    background-position: -32px -912px
}

.mobx-stumbleupon:after {
    background-color: #eb4924;
    background-position: 12px -912px
}

.mobx-evernote {
    background-position: -32px -956px
}

.mobx-evernote:after {
    background-color: #2dbe60;
    background-position: 12px -956px
}

.mobx-thumbs-holder,
.mobx-thumbs-inner {
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    margin: 0 auto
}

.mobx-thumbs-holder {
    position: relative;
    cursor: move;
    cursor: grab;
    cursor: -webkit-grab
}

.mobx-thumb {
    position: absolute;
    margin: 0;
    will-change: left;
    background-color: #191919;
    outline: transparent solid 0;
    -webkit-transition: background-color .25s ease;
    -o-transition: background-color .25s ease;
    transition: background-color .25s ease
}

.mobx-thumb:hover {
    background-color: #323232
}

.mobx-thumb:after {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .0001;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    border: 2px solid #fff
}

.mobx-active-thumb:after {
    opacity: .0
}

.mobx-thumb-bg {
    position: absolute;
    overflow: hidden;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    visibility: hidden;
    opacity: 0;
    background-position: center;
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    will-change: opacity, visibility;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: opacity .25s ease, visibility .25s ease;
    -o-transition: opacity .25s ease, visibility .25s ease;
    transition: opacity .25s ease, visibility .25s ease
}

.mobx-thumb-loaded {
    visibility: visible;
    opacity: .5
}

.mobx-active-thumb .mobx-thumb-loaded,
.mobx-thumb-bg:hover {
    opacity: 1
}

.mobx-dragging .mobx-thumbs-holder {
    cursor: grabbing;
    cursor: -webkit-grabbing
}

.mobx-thumb-video:after {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    width: 40px;
    height: 40px;
    margin: -20px auto 0;
    background-position: 12px -208px
}

.mobx-thumb-bg {
    cursor: pointer;
}

.mobx-thumb .mobx-thumb-bg {
    -webkit-box-shadow: inset 0 0 0 2px #8b8b8b;
            box-shadow: inset 0 0 0 2px #8b8b8b;
}

.mobx-thumb.mobx-active-thumb .mobx-thumb-bg,
.mobx-thumb:hover .mobx-thumb-bg {
    -webkit-box-shadow: inset 0 0 0 2px #2196F3;
            box-shadow: inset 0 0 0 2px #2196F3;
}

/*
@media screen and (max-height:800px) {
    .mobx-video:after,
    .mobx-video:before {
        width: 60px;
        height: 60px;
        margin: -34px 0 0 -34px;
    }
}
*/

/*
@media screen and (max-height:360px) {
    .mobx-video:after,
    .mobx-video:before {
        width: 40px;
        height: 40px;
        margin: -24px 0 0 -24px;
        border-width: 3px
    }
}
*/

@media screen and (max-width:400px) {
    .mobx-timer {
        margin: 10px 0
    }
    .mobx-top-bar button {
        margin: 2px 0
    }
}

@media screen and (max-width:360px) {
    .mobx-counter {
        margin: 0 6px 0 4px
    }
    .mobx-top-bar button {
        width: 34px
    }
}

@media only screen and (-webkit-min-device-pixel-ratio:2) and (max-width:380px),
only screen and (min--moz-device-pixel-ratio:2) and (max-width:380px),
only screen and (-o-min-device-pixel-ratio:2/1) and (max-width:380px),
only screen and (min-device-pixel-ratio:2) and (max-width:380px) {
    .mobx-top-bar button {
        width: 40px
    }
}



/* Своё НАЧАЛО */

.mobx-top-bar {
    background-color: rgba(0, 0, 0, 0);
}

.mobx-img {
    -webkit-box-shadow: 0 0 0 1px #ddd;
    box-shadow: 0 0 0 1px #ddd;
    background-color: #fff;
}

button.mobx-close {
    background: url(/templates/modern/js/modulobox/close--w.svg) no-repeat center center;
    -webkit-background-size: contain;
            background-size: contain;
    height: 40px;
    width: 40px;
    top: 15px;
    right: 15px;
	opacity: 1;
}

button.mobx-prev {
    background: url(/templates/modern/js/modulobox/arrow-left--w.svg) no-repeat 40% 50%;
    -webkit-background-size: 64px 64px;
            background-size: 64px;
}

button.mobx-prev:hover {
    -webkit-transform: translateX(-10%) translateY(-50%);
    -ms-transform: translateX(0%) translateY(-50%);
        transform: translateX(0%) translateY(-50%);
}

button.mobx-next {
    background: url(/templates/modern/js/modulobox/arrow-right--w.svg) no-repeat 60% 50%;
    -webkit-background-size: 64px 64px;
            background-size: 64px;
}

button.mobx-next:hover {
    -webkit-transform: translateX(10%) translateY(-50%);
    -ms-transform: translateX(0%) translateY(-50%);
        transform: translateX(0%) translateY(-50%);
}

button.mobx-prev:hover,
button.mobx-next:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

@media (min-width: 768px) {
	.mobx-slider {
		top: -20px;
	}
}

@media (max-width: 1024px) {
	
	button.mobx-close {
		height: 30px;
		width: 30px;
	}
	
	button.mobx-next,
	button.mobx-prev {
		width: 60px;
		top: calc(50% - 10px);
		-webkit-background-size: 44px 44px;
		background-size: 44px;
		opacity: .8;
	}
	
	button.mobx-prev:hover,
	button.mobx-next:hover {
		background-color: rgba(0, 0, 0, 0);
	}
}

/* Своё КОНЕЦ */