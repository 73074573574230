.lds-hourglass {
  display: inline-block!important;
  position: relative!important;
  width: 64px!important;
  height: 64px!important;
}
.lds-hourglass:after {
  content: " "!important;
  display: block!important;
  border-radius: 50%!important;
  width: 0!important;
  height: 0!important;
  margin: 6px!important;
  box-sizing: border-box!important;
  border: 26px solid #fff!important;
  border-color: #fff transparent #fff transparent!important;
  animation: lds-hourglass 1.2s infinite!important;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}
